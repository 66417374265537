import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Dialog, DialogContent, DialogContentText, DialogActions, Button, Typography, Stack } from '@mui/material';
import styles from '../../assets/styles/modal.module.scss';

interface LeaveGameModalProps {
  open: boolean;
  onClose: () => void;
  onLeaveGame: () => void;
}
const LeaveGameModal: FC<LeaveGameModalProps> = ({ open, onClose, onLeaveGame }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'LEAVE_GAME_MODAL' });
  const handleLeaveGame = () => onLeaveGame();

  return (
    <Dialog open={open} transitionDuration={0} className={styles.modal}>
      <Stack className={clsx(styles.header, styles.warning)}>
        <Typography variant="h3">{t('TITLE')}</Typography>
      </Stack>
      <DialogContent className={styles.body}>
        <DialogContentText>
          <Typography variant="body-1">{t('TEXT')}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.footer}>
        <Stack gap={5} direction="row">
          <Button
            variant="text"
            className={styles['secondary-button']}
            onClick={onClose}
            aria-label={t('CANCEL_BUTTON') as string}
          >
            <Typography variant="h4">{t('CANCEL_BUTTON')}</Typography>
          </Button>

          <Button
            variant="contained"
            className={clsx(styles['action-button'], styles.warning)}
            onClick={handleLeaveGame}
            aria-label={t('STOP_GAME_BUTTON') as string}
          >
            <Typography variant="button">{t('STOP_GAME_BUTTON')}</Typography>
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default LeaveGameModal;
