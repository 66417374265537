import React, { FC, useCallback, useEffect, useMemo, MouseEvent as ReactMouseEvent } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Stack, Typography, Button } from '@mui/material';
import {
  SoloIcon,
  ComputerIcon,
  MultiplayerIcon,
  LadderIcon,
  TournamentIcon,
  LogoutIcon,
  LoginIcon,
} from '../../assets/icons';
import { BottomBarItem } from '../../components';
import { useAuth, useRoutes, useWindowDimensions } from '../../hooks';
import styles from './BottomBar.module.scss';

const BOTTOM_NAVIGATION_HEIGHT = 501; // hack

interface BottomNavigationProps {
  openLoginModal: () => void;
  openLogoutModal: () => void;
  isOpenNavigation: boolean;
  closeNavigation: () => void;
}
const BottomNavigation: FC<BottomNavigationProps> = ({
  openLoginModal,
  openLogoutModal,
  isOpenNavigation,
  closeNavigation,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'NAVIGATION_BAR' });
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const ROUTES = useRoutes();
  const { outOfSession } = useAuth();
  const { height: screenHeight } = useWindowDimensions();

  const clickOutside = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const isClickOutside = !target.closest(`.${styles['bottom-navigation']}`);
      const isClickInApp = target.closest('#root');

      if (isClickInApp && isClickOutside && isOpenNavigation) {
        e.stopPropagation();
        e.preventDefault();
        closeNavigation();
      }
    },
    [isOpenNavigation]
  );

  useEffect(() => {
    document.addEventListener('click', clickOutside, true);
    return () => document.removeEventListener('click', clickOutside, true);
  }, [isOpenNavigation]);

  const onDraggerLineTouch = (e: ReactMouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    closeNavigation();
  };

  const navigationStructure = [
    { route: ROUTES.COMPUTER, icon: <ComputerIcon /> },
    { route: ROUTES.MULTIPLAYER, icon: <MultiplayerIcon /> },
    { route: ROUTES.LADDER_GAME, icon: <LadderIcon /> },
    { route: ROUTES.TOURNAMENT, icon: <TournamentIcon /> },
  ];

  const sideBarNavigation = useMemo(() => {
    const soloNavigationItem = {
      route: ROUTES[outOfSession ? 'SOLO_DEMO' : 'SOLO'],
      icon: <SoloIcon />,
      disabled: false,
    };
    return [soloNavigationItem, ...navigationStructure].map((v) => ({
      title: t(`ROUTE_TO_TITLE_MAP.${v.route.substring(3)}`),
      disabled: outOfSession,
      ...v,
    }));
  }, [outOfSession]);

  return (
    <Stack
      direction="column"
      alignItems="start"
      className={clsx(styles['bottom-navigation'], { [styles['bottom-navigation-open']]: isOpenNavigation })}
      style={{ height: isOpenNavigation && screenHeight < BOTTOM_NAVIGATION_HEIGHT ? screenHeight : 'auto' }}
    >
      <Stack justifyContent="center" alignItems="center" className={styles.dragger} onClick={onDraggerLineTouch}>
        <div className={styles['dragger-line']} />
      </Stack>

      {sideBarNavigation.map(({ route, title, icon, disabled }) => (
        <BottomBarItem
          key={route}
          route={route}
          title={title}
          icon={icon}
          disabled={disabled}
          openLoginModal={openLoginModal}
          closeNavigation={closeNavigation}
        />
      ))}

      <Stack direction="column" alignItems="center" justifyContent="center" className={styles['login-logout-wrap']}>
        {outOfSession ? (
          <Button
            component={NavLink}
            to={ROUTES.AUTH_SIGN_IN}
            variant="text"
            aria-label={ariaLabelT('LINK.SIGN_IN') as string}
          >
            <Stack direction="row" gap={2} alignItems="center" className={styles['login-button']}>
              <LoginIcon />
              <Typography variant="h5">{t('LOG_IN')}</Typography>
            </Stack>
          </Button>
        ) : (
          <Button
            onClick={openLogoutModal}
            variant="text"
            aria-label={ariaLabelT('BUTTON.OPEN_LOGOUT_MODAL') as string}
          >
            <Stack direction="row" gap={2} alignItems="center" className={styles['logout-button']}>
              <LogoutIcon />
              <Typography variant="h5">{t('LOG_OUT')}</Typography>
            </Stack>
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default BottomNavigation;
