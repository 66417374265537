import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { auth, db } from '../firebase';
import { AuthError } from '../types';
import { useRoutes } from './index';

const useAuth = () => {
  const ROUTES = useRoutes();
  const [user, loading] = useAuthState(auth);
  const [initiated, setInitiated] = useState(false);

  useEffect(() => {
    if (loading) {
      setInitiated(true);
    }
  }, [loading]);

  const registerWithEmailAndPassword = async (name: string, email: string, password: string) => {
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      await addDoc(collection(db, 'users'), {
        uid: res.user.uid,
        name,
        authProvider: 'local',
        email,
      });
      // eslint-disable-next-line no-console
      console.info('Signed up with email');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw (err as AuthError).code;
    }
  };

  const logInWithEmailAndPassword = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // eslint-disable-next-line no-console
      console.info('Logged in with email');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw (err as AuthError).code;
    }
  };

  const googleProvider = new GoogleAuthProvider();

  const signInWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      const q = query(collection(db, 'users'), where('uid', '==', res.user.uid));
      const docs = await getDocs(q);

      if (docs.docs.length === 0) {
        await addDoc(collection(db, 'users'), {
          uid: res.user.uid,
          name: res.user.displayName,
          authProvider: 'google',
          email: res.user.email,
        });
        // eslint-disable-next-line no-console
        console.info('Created a user and logged in with google');
      } else {
        // eslint-disable-next-line no-console
        console.info('Logged in with google');
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const sendPasswordReset = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email, {
        handleCodeInApp: false,
        url: `${location.origin}${ROUTES.AUTH_SIGN_IN}`,
      });
      // eslint-disable-next-line no-console
      console.info('Password reset link sent!');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw (err as AuthError).code;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setInitiated(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  return {
    user,
    loading,
    initiated,
    outOfSession: initiated && !loading && !user,
    signInWithGoogle,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
  };
};

export default useAuth;
