import React, { FC, ReactNode, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, Autocomplete, TextField, Paper } from '@mui/material';
import { getGameFolder, setGameFolder, getFolders } from '../../redux/slices';
import { ArrowIcon } from '../../assets/icons';
import { dropdownMaxHeight } from '../../constants';
import styles from './DesktopSettings.module.scss';

interface PaperComponentProps {
  children?: ReactNode;
}
const PaperComponent: FC<PaperComponentProps> = ({ children }) => (
  <Paper style={{ maxHeight: dropdownMaxHeight, overflow: 'auto' }} className={styles['custom-paper']}>
    {children}
  </Paper>
);

const CategorySetting: FC = () => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'SETTINGS' });
  const dispatch = useDispatch();
  const gameFolder = useSelector(getGameFolder);
  const folders = useSelector(getFolders);
  const foldersPaths = useMemo(() => folders.map(({ fullPath }) => fullPath), [folders]);

  const handleFolderChange = (event: any, newValue: string) => dispatch(setGameFolder(newValue));

  return (
    <Stack direction="column" gap={2} className={styles.dropdown}>
      <Typography variant="h5" color="var(--text-secondary)">
        {t('CATEGORY_LABEL')}
      </Typography>
      <Autocomplete
        options={foldersPaths}
        popupIcon={<ArrowIcon className={styles['arrow-drop-down-icon']} />}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              'aria-label': params.inputProps.value as string,
            }}
          />
        )}
        getOptionLabel={(fullPath) =>
          i18n.exists(`SETTINGS.FOLDERS_MAP.${fullPath}`) ? t(`FOLDERS_MAP.${fullPath}`) : ''
        }
        PaperComponent={PaperComponent}
        noOptionsText={t('NO_OPTIONS')}
        onChange={handleFolderChange}
        value={foldersPaths.length ? gameFolder : ''}
        disableClearable
        autoHighlight
        // open
      />
    </Stack>
  );
};

export default CategorySetting;
