import React, { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ListItemButton, Typography, ListItemText, Stack } from '@mui/material';
import styles from './BottomBarItem.module.scss';

interface BottomBarItemProps {
  title: string;
  route: string;
  icon: JSX.Element;
  openLoginModal: () => void;
  closeNavigation: () => void;
  disabled?: boolean;
}

const BottomBarItem: FC<BottomBarItemProps> = ({ route, icon, title, openLoginModal, closeNavigation, disabled }) => {
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const { pathname } = useLocation();

  const handleClick = () => {
    if (disabled) {
      openLoginModal();
    }
  };

  return (
    <div onClick={handleClick} className={styles['item-wrap']}>
      <ListItemButton
        component={NavLink}
        to={route}
        onClick={closeNavigation}
        className={clsx(styles.item, {
          [styles.active]: pathname.startsWith(route),
        })}
        disabled={disabled}
        aria-label={ariaLabelT('LINK.GENERIC', { pageName: title }) as string}
      >
        <Stack direction="row" gap={4} alignItems="center">
          <div className={styles['icon-wrap']}>{icon}</div>
          <ListItemText className={styles.title}>
            <Typography variant="h3">{title}</Typography>
          </ListItemText>
        </Stack>
      </ListItemButton>
    </div>
  );
};

export default BottomBarItem;
