import React from 'react';
import { TypographyOptions } from '@mui/material/styles/createTypography';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    'body-1': React.CSSProperties;
    'body-2': React.CSSProperties;
    button: React.CSSProperties;
    'landing-h1': React.CSSProperties;
    'landing-h2': React.CSSProperties;
    'landing-body-1': React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    'body-1': React.CSSProperties;
    'body-2': React.CSSProperties;
    button: React.CSSProperties;
    'landing-h1': React.CSSProperties;
    'landing-h2': React.CSSProperties;
    'landing-body-1': React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    'body-1': true;
    'body-2': true;
    button: true;
    'landing-h1': true;
    'landing-h2': true;
    'landing-body-1': true;
  }
}

const typography: TypographyOptions = {
  fontFamily: ['Montserrat', 'sans-serif'].join(','),
  fontSize: 12,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,

  h1: {
    fontWeight: 600,
    fontSize: '32px',
    lineHeight: '120%',
    letterSpacing: '-0.608px',
  },
  h2: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '120%',
    letterSpacing: '-0.456px',
  },
  h3: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '120%',
    letterSpacing: '-0.38px',
  },
  h4: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '120%',
    letterSpacing: '-0.304px',
  },
  h5: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '120%',
    letterSpacing: '-0.266px',
  },
  'body-1': {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '-0.304px',
  },
  'body-2': {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '-0.266px',
  },
  button: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '120%',
    letterSpacing: '-0.304px',
    textTransform: 'none',
  },
  caption: {
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '139.5%',
    letterSpacing: '-0.19px',
  },
  'landing-h1': {
    fontWeight: 700,
    fontSize: '48px',
    lineHeight: '137.5%',
  },
  'landing-h2': {
    fontWeight: 600,
    fontSize: '40px',
    lineHeight: 'normal',
  },
  'landing-body-1': {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '130%',
  },
};

export default typography;
