import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Stack, Typography, Link } from '@mui/material';
import clsx from 'clsx';
import { LEGAL_EMAIL } from '../../constants';
import { useMainLayout } from '../../components/Layout/LayoutWrapper';
import { MobileTopNotch } from '../../components/MobileTopNotch';
import styles from './TermsOfService.module.scss';

const TermsOfService: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'TERMS_OF_SERVICE' });
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const { isMobile } = useMainLayout();

  return (
    <>
      <MobileTopNotch title={t('TITLE')} />

      <Stack
        flexDirection="column"
        gap={8}
        alignItems="left"
        className={clsx(styles['page-wrap'], {
          [styles['page-wrap-mobile']]: isMobile,
          [styles['page-wrap-mobile-with-notch']]: isMobile,
        })}
      >
        {!isMobile && <Typography variant="h1">{t('TITLE')}</Typography>}

        <Typography variant="body-2">{t('LAST_UPDATED', { date: '12-11-2023' })}</Typography>

        <Stack flexDirection="column" gap={6}>
          <Typography variant="h2">{t('ACCEPTANCE_OF_TERMS_SUB_TITLE')}</Typography>
          <Typography variant="body-1">{t('ACCEPTANCE_OF_TERMS_TEXT')}</Typography>
        </Stack>

        <Stack flexDirection="column" gap={6}>
          <Typography variant="h2">{t('ACCESS_AND_USE_SUB_TITLE')}</Typography>
          <Stack flexDirection="column" gap={4}>
            <Typography variant="h3">{t('ACCESS_AND_USE_SUB_SUB_TITLE_1')}</Typography>
            <Typography variant="body-1">{t('ACCESS_AND_USE_TEXT_1')}</Typography>
          </Stack>
          <Stack flexDirection="column" gap={4}>
            <Typography variant="h3">{t('ACCESS_AND_USE_SUB_SUB_TITLE_2')}</Typography>
            <Typography variant="body-1">{t('ACCESS_AND_USE_TEXT_2')}</Typography>
          </Stack>
        </Stack>

        <Stack flexDirection="column" gap={6}>
          <Typography variant="h2">{t('COOKIES_AND_THIRD_SERVICES_SUB_TITLE')}</Typography>
          <Typography variant="body-1">{t('COOKIES_AND_THIRD_SERVICES_TEXT')}</Typography>
        </Stack>

        <Stack flexDirection="column" gap={6}>
          <Typography variant="h2">{t('INFO_COLLECTION_SUB_TITLE')}</Typography>
          <Stack flexDirection="column" gap={4}>
            <Typography variant="h3">{t('INFO_COLLECTION_SUB_SUB_TITLE_1')}</Typography>
            <Typography variant="body-1">{t('INFO_COLLECTION_TEXT_1')}</Typography>
          </Stack>
          <Stack flexDirection="column" gap={4}>
            <Typography variant="h3">{t('INFO_COLLECTION_SUB_SUB_TITLE_2')}</Typography>
            <Typography variant="body-1">{t('INFO_COLLECTION_TEXT_2')}</Typography>
          </Stack>
          <Stack flexDirection="column" gap={4}>
            <Typography variant="h3">{t('INFO_COLLECTION_SUB_SUB_TITLE_3')}</Typography>
            <Typography variant="body-1">{t('INFO_COLLECTION_TEXT_3')}</Typography>
          </Stack>
        </Stack>

        <Stack flexDirection="column" gap={6}>
          <Typography variant="h2">{t('USER_RESPONSIBILITIES_SUB_TITLE')}</Typography>
          <Typography variant="body-1">{t('USER_RESPONSIBILITIES_TEXT')}</Typography>
        </Stack>

        <Stack flexDirection="column" gap={6}>
          <Typography variant="h2">{t('CHANGES_OF_TERMS_SUB_TITLE')}</Typography>
          <Typography variant="body-1">{t('CHANGES_OF_TERMS_TEXT')}</Typography>
        </Stack>

        <Stack flexDirection="column" gap={6}>
          <Typography variant="h2">{t('TERMINATION_SUB_TITLE')}</Typography>
          <Typography variant="body-1">{t('TERMINATION_TEXT')}</Typography>
        </Stack>

        <Stack flexDirection="column" gap={6}>
          <Typography variant="h2">{t('CONTACT_INFORMATION_SUB_TITLE')}</Typography>
          <Typography variant="body-1">
            <Trans
              i18nKey="TERMS_OF_SERVICE.CONTACT_INFORMATION_TEXT"
              components={[
                <Link
                  href={`mailto:${LEGAL_EMAIL}`}
                  target="send-email"
                  className={styles.link}
                  aria-label={ariaLabelT('LINK.SEND_EMAIL', { email: LEGAL_EMAIL }) as string}
                />,
              ]}
              values={{ email: LEGAL_EMAIL }}
            />
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default TermsOfService;
