import React, { FC } from 'react';
import { Stack, CircularProgress } from '@mui/material';
import clsx from 'clsx';
import {
  useGetSoloDataQuery,
  useGetMultiPlayerDataQuery,
  useGetComputerDataQuery,
  useGetTournamentDataQuery,
  useGetLadderGameDataQuery,
} from '../../redux/api';
import { GameModeWidgetSet, GameAnalyticsWidgetSet, BestScoreGamesSet, DonationButton } from '../../components';
import useAuth from '../../hooks/useAuth';
import { useMainLayout } from '../../components/Layout/LayoutWrapper';
import styles from '../Info/Info.module.scss';

const Dashboard: FC = () => {
  const { user, loading: authLoading } = useAuth();
  const { isMobile } = useMainLayout();

  const { isLoading: isLoadingSoloData } = useGetSoloDataQuery({ uid: user?.uid! }, { skip: authLoading });
  const { isLoading: isLoadingMultiPlayerData } = useGetMultiPlayerDataQuery(
    { uid: user?.uid! },
    { skip: authLoading }
  );
  const { isLoading: isLoadingComputerData } = useGetComputerDataQuery({ uid: user?.uid! }, { skip: authLoading });
  const { isLoading: isLoadingTournamentData } = useGetTournamentDataQuery({ uid: user?.uid! }, { skip: authLoading });
  const { isLoading: isLoadingLadderGameData } = useGetLadderGameDataQuery({ uid: user?.uid! }, { skip: authLoading });

  if (
    isLoadingSoloData ||
    isLoadingMultiPlayerData ||
    isLoadingComputerData ||
    isLoadingTournamentData ||
    isLoadingLadderGameData
  ) {
    return (
      <Stack className="game-loader-wrap">
        <Stack className="loader-wrap">
          <CircularProgress color="primary" />
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      gap={isMobile ? 10 : 16}
      alignItems="start"
      justifyContent="start"
      className={clsx(styles['page-wrap'], { [styles['page-wrap-mobile']]: isMobile })}
    >
      <GameModeWidgetSet />
      <GameAnalyticsWidgetSet />
      <BestScoreGamesSet />
      <DonationButton />
    </Stack>
  );
};

export default Dashboard;
