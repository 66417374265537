import React, { FC, MutableRefObject, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Button, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import clsx from 'clsx';
import { Logo, LogoWide, EnIcon, UkIcon } from '../../assets';
import { mobileHeaderHeight, desktopHeaderHeight } from '../../constants';
import { useRoutes } from '../../hooks';
import { Burger } from '../Burger';
import styles from './LandingHeader.module.scss';

const root = document.getElementById('root')!;

const iconMap: { [key: string]: JSX.Element } = {
  en: <EnIcon />,
  uk: <UkIcon />,
};

interface RouteActionsProps {
  isMobile: boolean;
  activeAnchor: string;
  handleMenuItemClick: (elName: 'home' | 'about' | 'howToPlay') => () => void;
}
const RouteActions: FC<RouteActionsProps> = ({ isMobile, activeAnchor, handleMenuItemClick }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'LANDING' });

  return (
    <>
      <Typography
        variant={isMobile ? 'h2' : 'button'}
        className={clsx(styles['menu-item'], { [styles.active]: activeAnchor === 'home' })}
        onClick={handleMenuItemClick('home')}
        aria-label={t('HOME_MENU_ITEM') as string}
      >
        {t('HOME_MENU_ITEM')}
      </Typography>

      <Typography
        variant={isMobile ? 'h2' : 'button'}
        className={clsx(styles['menu-item'], { [styles.active]: activeAnchor === 'about' })}
        onClick={handleMenuItemClick('about')}
        aria-label={t('ABOUT_MENU_ITEM') as string}
      >
        {t('ABOUT_MENU_ITEM')}
      </Typography>

      <Typography
        variant={isMobile ? 'h2' : 'button'}
        className={clsx(styles['menu-item'], { [styles.active]: activeAnchor === 'howToPlay' })}
        onClick={handleMenuItemClick('howToPlay')}
        aria-label={t('HOW_TO_PLAY_MENU_ITEM') as string}
      >
        {t('HOW_TO_PLAY_MENU_ITEM')}
      </Typography>
    </>
  );
};

interface LanguageToggleProps {
  isMobile: boolean;
}
const LanguageToggle: FC<LanguageToggleProps> = ({ isMobile }) => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'LANDING' });
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const changeLanguage = (e: SelectChangeEvent<string>) => {
    const language = e.target.value;

    i18next.changeLanguage(language).then((tCb) => {
      if (typeof tCb === 'function') {
        navigate(`/${language}${pathname.substring(3)}`);
      }
    });
  };

  return (
    <Select
      value={i18n.language}
      renderValue={(value: string) => iconMap[value]}
      onChange={changeLanguage}
      className={clsx(styles['outlined-dropdown'], { [styles['outlined-dropdown-mobile']]: isMobile })}
    >
      <MenuItem value="en">
        <Stack direction="row" gap={2}>
          <EnIcon />
          <Typography variant="body-2">{t('LANGUAGE.EN')}</Typography>
        </Stack>
      </MenuItem>
      <MenuItem value="uk">
        <Stack direction="row" gap={2}>
          <UkIcon />
          <Typography variant="body-2">{t('LANGUAGE.UK')}</Typography>
        </Stack>
      </MenuItem>
    </Select>
  );
};

interface ButtonActionsProps {
  isMobile: boolean;
}
const ButtonActions: FC<ButtonActionsProps> = ({ isMobile }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'LANDING' });
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const ROUTES = useRoutes();

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      alignItems="center"
      gap={4}
      className={clsx(styles['action-buttons'], { [styles['action-buttons-mobile']]: isMobile })}
    >
      {!isMobile && <LanguageToggle isMobile={false} />}

      <Button
        component={RouterLink}
        to={ROUTES.AUTH_SIGN_IN}
        variant="outlined"
        className={clsx(styles['outlined-button'], styles['header-button'])}
        aria-label={ariaLabelT('LINK.SIGN_IN') as string}
      >
        <Typography variant="button">{t('SING_IN')}</Typography>
      </Button>

      <Button
        component={RouterLink}
        to={ROUTES.SOLO_DEMO}
        variant="contained"
        className={clsx(styles['action-button'], styles['header-button'], styles.tiny)}
        aria-label={ariaLabelT('LINK.SOLO_DEMO') as string}
      >
        <Typography variant="button">{t('PLAY')}</Typography>
      </Button>
    </Stack>
  );
};

const headerBgColorMap: { [key: string]: string } = {
  home: 'var(--landing-bg-yellow)',
  about: 'var(--global-white)',
  howToPlay: 'var(--landing-bg-blue)',
};

interface LandingHeaderProps {
  isMobile: boolean;
  homeRef: MutableRefObject<HTMLElement | null>;
  aboutRef: MutableRefObject<HTMLElement | null>;
  howToPlayRef: MutableRefObject<HTMLElement | null>;
  narrowBreakPoint: number;
}
const LandingHeader: FC<LandingHeaderProps> = ({ isMobile, homeRef, aboutRef, howToPlayRef, narrowBreakPoint }) => {
  const [openedBurger, setOpenedBurger] = useState(false);
  const [activeAnchor, setActiveAnchor] = useState('home');
  const headerHeight = isMobile ? mobileHeaderHeight : desktopHeaderHeight;

  const handleMobileMenu = () => {
    setOpenedBurger((currentOpenedBurger) => {
      if (currentOpenedBurger) {
        document.body.classList.remove('freeze');
      } else {
        document.body.classList.add('freeze');
      }
      return !currentOpenedBurger;
    });
  };

  const refMap: {
    home: MutableRefObject<HTMLElement | null>;
    about: MutableRefObject<HTMLElement | null>;
    howToPlay: MutableRefObject<HTMLElement | null>;
  } = {
    home: homeRef,
    about: aboutRef,
    howToPlay: howToPlayRef,
  };

  useEffect(() => {
    const scrollHandler = () => {
      const scrollY = root.scrollTop + headerHeight;
      const homeTop = homeRef.current?.offsetTop || 0;
      const aboutTop = aboutRef.current?.offsetTop || 0;
      const howToPlayTop = howToPlayRef.current?.offsetTop || 0;
      const isScrolledToBottom = root.scrollTop + document.body.scrollHeight >= root.scrollHeight;

      let currentActiveAnchor;
      if (scrollY >= homeTop) {
        currentActiveAnchor = 'home';
      }
      if (scrollY >= aboutTop) {
        currentActiveAnchor = 'about';
      }
      if (scrollY >= howToPlayTop || isScrolledToBottom) {
        currentActiveAnchor = 'howToPlay';
      }

      if (currentActiveAnchor) {
        setActiveAnchor(currentActiveAnchor);
      }
    };

    root.addEventListener('scroll', scrollHandler);

    return () => {
      root.removeEventListener('scroll', scrollHandler);
      document.body.classList.remove('freeze');
    };
  }, [isMobile]);

  const handleMenuItemClick = (elName: 'home' | 'about' | 'howToPlay') => () => {
    const { current }: { current: HTMLElement | null } = refMap[elName];

    if (isMobile) {
      handleMobileMenu();
    }

    if (current) {
      const { top } = current?.getBoundingClientRect() || { top: 0 };
      root.scroll({ top: Math.ceil(root.scrollTop + top - headerHeight), behavior: 'smooth' });
    }
  };

  if (isMobile) {
    return (
      <Stack
        direction="column"
        className={clsx(styles['mobile-menu-wrap'], { [styles['mobile-menu-wrap-opened']]: openedBurger })}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={styles['mobile-landing-header']}
          style={{ backgroundColor: openedBurger ? 'var(--global-white)' : headerBgColorMap[activeAnchor] }}
        >
          <LogoWide className={styles['brand-icon']} />
          <Burger opened={openedBurger} handleClick={handleMobileMenu} />
        </Stack>

        {openedBurger && (
          <Stack
            direction="column"
            gap={6}
            alignItems="center"
            justifyContent="space-between"
            className={styles['mobile-menu-content']}
          >
            <Stack direction="column" gap={6} alignItems="center">
              <RouteActions {...{ isMobile, activeAnchor, handleMenuItemClick }} />
              <LanguageToggle isMobile />
            </Stack>
            <ButtonActions isMobile={isMobile} />
          </Stack>
        )}
      </Stack>
    );
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap={4}
      className={styles['landing-header']}
      style={{ backgroundColor: headerBgColorMap[activeAnchor] }}
    >
      <Stack direction="row" gap={6}>
        {narrowBreakPoint > 1 ? (
          <Logo className={clsx(styles['brand-icon'], styles['narrow-icon'])} />
        ) : (
          <LogoWide className={styles['brand-icon']} />
        )}
        <RouteActions {...{ isMobile, activeAnchor, handleMenuItemClick }} />
      </Stack>

      <ButtonActions isMobile={isMobile} />
    </Stack>
  );
};

export default LandingHeader;
