import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Dialog, DialogContent, DialogContentText, Typography, Stack, DialogActions, Button } from '@mui/material';
import { useWindowDimensions } from '../../hooks';
import { checkNarrowBreakPoint } from '../../utils';
import styles from '../../assets/styles/modal.module.scss';

const MobileOrientationModal: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'MOBILE_ORIENTATION_MODAL' });
  const [mobileOrientationModalIsOpen, setMobileOrientationModalIsOpen] = useState<boolean>(false);
  const openMobileOrientationModal = () => setMobileOrientationModalIsOpen(true);
  const closeMobileOrientationModal = () => setMobileOrientationModalIsOpen(false);

  const toggleMobileOrientationModal = (type: OrientationType = window.screen.orientation?.type) => {
    if (type.includes('landscape')) {
      openMobileOrientationModal();
    } else {
      closeMobileOrientationModal();
    }
  };

  const onOrientationChange = (event: Event) => {
    toggleMobileOrientationModal((event.target as ScreenOrientation).type);
  };

  const { width, height } = useWindowDimensions();
  const narrowBreakPointByWidth = checkNarrowBreakPoint(width);
  const narrowBreakPointByHeight = checkNarrowBreakPoint(height);
  const orientationType = window.screen.orientation?.type;
  const isLandscape = orientationType.includes('landscape');
  const isMobile = (isLandscape ? narrowBreakPointByHeight : narrowBreakPointByWidth) >= 3;

  useEffect(() => {
    if (isMobile) {
      toggleMobileOrientationModal();
      window.screen.orientation.addEventListener('change', onOrientationChange);
    }

    return () => {
      if (isMobile) {
        window.screen.orientation.removeEventListener('change', onOrientationChange);
      }
    };
  }, []);

  return (
    <Dialog open={mobileOrientationModalIsOpen} transitionDuration={0} className={styles.modal}>
      <Stack className={styles.header} alignItems="center">
        <Typography variant="h3">{t('TITLE')}</Typography>
      </Stack>
      <DialogContent className={styles.body}>
        <DialogContentText textAlign="center">
          <Typography variant="body-1">{t('TEXT')}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={clsx(styles.footer, styles.centered)}>
        <Button
          variant="contained"
          className={styles['action-button']}
          aria-label={t('OK_BUTTON') as string}
          onClick={closeMobileOrientationModal}
        >
          <Typography variant="h4">{t('OK_BUTTON')}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MobileOrientationModal;
