import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { Box, CircularProgress, Stack } from '@mui/material';
import { useAuth, useInit, useRoutes } from '../../hooks';
import { removeTrailingSlashes } from '../../utils';
import { NavigationBar } from '../NavigationBar';
import LayoutWrapper, { useMainLayout } from './LayoutWrapper';
import GameWrapper from './GameWrapper';

const sideBarWide = 214;
const sideBarNarrow = 94;

const useMainWidth = () => {
  const { narrowBreakPoint, isOpen, isMobile } = useMainLayout();
  if (isMobile) return 0;
  const isOpenValue = isOpen ? sideBarWide : sideBarNarrow;
  return narrowBreakPoint ? sideBarNarrow : isOpenValue;
};

type ComponentWithChildProps = {
  children?: string | JSX.Element | JSX.Element[];
};

const Nav = ({ children }: ComponentWithChildProps) => {
  const { isOpen } = useMainLayout();

  return (
    <Box component="nav" sx={{ width: isOpen ? sideBarWide : sideBarNarrow }}>
      {children}
    </Box>
  );
};

const Main = ({ children }: ComponentWithChildProps) => {
  const { isMobile } = useMainLayout();
  const width = useMainWidth();

  return (
    <Box
      component="main"
      sx={{
        height: isMobile ? 'auto' : 'inherit',
        minHeight: 'inherit',
        transition: 'margin 0.3s ease-in-out, width 0.3s ease-in-out',
        marginLeft: `${width}px`,
        width: `calc(100% - ${width}px)`,
      }}
    >
      {children}
    </Box>
  );
};

const Layout = () => {
  const { pathname } = useLocation();
  const path = removeTrailingSlashes(pathname);
  const { user, loading: authLoading } = useAuth();
  const ROUTES = useRoutes();
  const fetchData = !!user || path === ROUTES.SOLO_DEMO;
  const { isInitialLoading } = useInit(fetchData);

  if (authLoading || isInitialLoading) {
    return (
      <Stack justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress color="primary" />
      </Stack>
    );
  }

  if (!user && ![ROUTES.SOLO_DEMO, ROUTES.INFO, ROUTES.PRIVACY_POLICY, ROUTES.TERMS_OF_SERVICE].includes(path)) {
    return <Navigate to={ROUTES.AUTH_SIGN_IN} />;
  }

  if (user && path === ROUTES.SOLO_DEMO) {
    return <Navigate to={ROUTES.SOLO} />;
  }

  return (
    <LayoutWrapper>
      <Nav>
        <NavigationBar />
      </Nav>
      <Main>
        <GameWrapper>
          <Outlet />
        </GameWrapper>
      </Main>
    </LayoutWrapper>
  );
};

export default Layout;
