import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DonationState } from '../types/donation';

const initialState: DonationState = {
  showDonationButton: true,
};

export const donationSlice = createSlice({
  name: 'donation',
  initialState,
  reducers: {
    updateDonation: (state, action: PayloadAction<DonationState>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { updateDonation } = donationSlice.actions;

export const getDonation = ({ donation }: { donation: DonationState }) => donation;
export const getShowDonationButton = ({ donation }: { donation: DonationState }) =>
  getDonation({ donation }).showDonationButton;
export const { reducer: donationReducer } = donationSlice;
