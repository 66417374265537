import { arrayUnion, doc, updateDoc, getDoc, increment, setDoc } from 'firebase/firestore';
import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { db } from '../../firebase';
import { sortByTimeStamp, sortLadderGames, sortTournaments } from '../../utils';
import { ComputerGame, LadderGame, MultiPlayerGame, Tournament, Solo } from '../types/achievements';
import {
  getComputerGrade,
  getCurrentEfficiencies,
  getGameFolder,
  getLadderGameStatus,
  getLadderGameStep,
  getOverallEfficiency,
  getUid,
} from '../slices';
import { RootState } from '../store';

interface SoloResponse {
  last10: Array<Solo>;
  total: number;
}

interface MultiPlayerResponse {
  last10: Array<MultiPlayerGame>;
  total: number;
}

interface ComputerResponse {
  last10: Array<ComputerGame>;
  total: number;
}

interface TournamentResponse {
  last10: Array<Tournament>;
  top10: Array<Tournament>;
  total: number;
}

interface LadderGameResponse {
  last10: Array<LadderGame>;
  top10: Array<LadderGame>;
  total: number;
}

interface ApiArgs {
  uid: string;
}

export const firestoreApi = createApi({
  baseQuery: fakeBaseQuery(),
  tagTypes: ['Solo', 'MultiPlayer', 'Computer', 'Tournament', 'LadderGame'],
  endpoints: (builder) => ({
    getSoloData: builder.query<SoloResponse, ApiArgs>({
      async queryFn({ uid }) {
        try {
          const soloRef = doc(db, 'solo', uid);
          const getSoloSnap = async () => getDoc(soloRef);

          const soloSnap = await getSoloSnap();
          if (soloSnap.exists()) {
            const soloData = soloSnap.data() as SoloResponse;
            return { data: soloData };
          }
          return { data: { last10: [], total: 0 } };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      providesTags: ['Solo'],
    }),
    updateSoloData: builder.mutation({
      async queryFn(args, { getState }) {
        const state = getState() as RootState;
        const uid = getUid(state);
        const gameFolder = getGameFolder(state);

        try {
          const soloRef = doc(db, 'solo', uid);
          const getSoloSnap = async () => getDoc(soloRef);

          const now = new Date();
          const newRecord = {
            category: gameFolder,
            timeStamp: now.getTime(),
          };

          const soloSnap = await getSoloSnap();

          if (soloSnap.exists()) {
            const soloData = soloSnap.data();

            let last10;
            if (soloData.last10.length >= 10) {
              const resultingMap = sortByTimeStamp([...soloData.last10, newRecord]);
              last10 = resultingMap.slice(0, resultingMap.length - 1);
            } else {
              last10 = arrayUnion(newRecord);
            }

            await updateDoc(soloRef, { last10, total: increment(1) });
            return { data: null };
          }

          await setDoc(soloRef, { last10: [newRecord], total: 1 });
          return { data: null };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      invalidatesTags: ['Solo'],
    }),
    getMultiPlayerData: builder.query<MultiPlayerResponse, ApiArgs>({
      async queryFn({ uid }) {
        try {
          const multiPlayerRef = doc(db, 'multiPlayer', uid);
          const getMultiPlayerSnap = async () => getDoc(multiPlayerRef);

          const multiPlayerSnap = await getMultiPlayerSnap();
          if (multiPlayerSnap.exists()) {
            const multiPlayerData = multiPlayerSnap.data() as MultiPlayerResponse;
            return { data: multiPlayerData };
          }
          return { data: { last10: [], total: 0 } };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      providesTags: ['MultiPlayer'],
    }),
    updateMultiPlayerData: builder.mutation({
      async queryFn(args, { getState }) {
        const state = getState() as RootState;
        const uid = getUid(state);
        const gameFolder = getGameFolder(state);

        try {
          const multiPlayerRef = doc(db, 'multiPlayer', uid);
          const getMultiPlayerSnap = async () => getDoc(multiPlayerRef);

          const now = new Date();
          const newRecord = {
            category: gameFolder,
            timeStamp: now.getTime(),
          };

          const multiPlayerSnap = await getMultiPlayerSnap();

          if (multiPlayerSnap.exists()) {
            const multiPlayerData = multiPlayerSnap.data();

            let last10;
            if (multiPlayerData.last10.length >= 10) {
              const resultingMap = sortByTimeStamp([...multiPlayerData.last10, newRecord]);
              last10 = resultingMap.slice(0, resultingMap.length - 1);
            } else {
              last10 = arrayUnion(newRecord);
            }

            await updateDoc(multiPlayerRef, { last10, total: increment(1) });
            return { data: null };
          }

          await setDoc(multiPlayerRef, { last10: [newRecord], total: 1 });
          return { data: null };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      invalidatesTags: ['MultiPlayer'],
    }),
    getComputerData: builder.query<ComputerResponse, ApiArgs>({
      async queryFn({ uid }) {
        try {
          const computerRef = doc(db, 'computer', uid);
          const getComputerSnap = async () => getDoc(computerRef);

          const computerSnap = await getComputerSnap();
          if (computerSnap.exists()) {
            const computerData = computerSnap.data() as ComputerResponse;
            return { data: computerData };
          }
          return { data: { last10: [], total: 0 } };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      providesTags: ['Computer'],
    }),
    updateComputerData: builder.mutation({
      async queryFn(args, { getState }) {
        const state = getState() as RootState;
        const uid = getUid(state);
        const gameFolder = getGameFolder(state);
        const computerGrade = getComputerGrade(state);

        try {
          const computerRef = doc(db, 'computer', uid);
          const getComputerSnap = async () => getDoc(computerRef);

          const now = new Date();
          const newRecord = {
            category: gameFolder,
            timeStamp: now.getTime(),
            computerGrade,
          };

          const computerSnap = await getComputerSnap();

          if (computerSnap.exists()) {
            const computerData = computerSnap.data();

            let last10;
            if (computerData.last10.length >= 10) {
              const resultingMap = sortByTimeStamp([...computerData.last10, newRecord]);
              last10 = resultingMap.slice(0, resultingMap.length - 1);
            } else {
              last10 = arrayUnion(newRecord);
            }

            await updateDoc(computerRef, { last10, total: increment(1) });
            return { data: null };
          }

          await setDoc(computerRef, { last10: [newRecord], total: 1 });
          return { data: null };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      invalidatesTags: ['Computer'],
    }),
    getTournamentData: builder.query<TournamentResponse, ApiArgs>({
      async queryFn({ uid }) {
        try {
          const tournamentRef = doc(db, 'tournament', uid);
          const getTournamentSnap = async () => getDoc(tournamentRef);

          const tournamentsSnap = await getTournamentSnap();
          if (tournamentsSnap.exists()) {
            const tournamentsData = tournamentsSnap.data() as TournamentResponse;
            return { data: tournamentsData };
          }
          return { data: { last10: [], top10: [], total: 0 } };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      providesTags: ['Tournament'],
    }),
    updateTournamentData: builder.mutation({
      async queryFn(args, { getState }) {
        const state = getState() as RootState;
        const uid = getUid(state);
        const currentEfficiencies = getCurrentEfficiencies(state);
        const overallEfficiency = getOverallEfficiency(state);
        const gameFolder = getGameFolder(state);

        try {
          const tournamentRef = doc(db, 'tournament', uid);
          const getTournamentSnap = async () => getDoc(tournamentRef);

          const now = new Date();
          const newRecord = {
            ...currentEfficiencies,
            overall: overallEfficiency,
            category: gameFolder,
            timeStamp: now.getTime(),
          };

          const tournamentsSnap = await getTournamentSnap();

          if (tournamentsSnap.exists()) {
            const tournamentsData = tournamentsSnap.data();

            let last10;
            let top10;

            if (tournamentsData?.last10?.length && tournamentsData.last10.length >= 10) {
              const resultingMap = sortByTimeStamp([...tournamentsData.last10, newRecord]);
              last10 = resultingMap.slice(0, resultingMap.length - 1);
            } else {
              last10 = arrayUnion(newRecord);
            }

            if (tournamentsData?.top10?.length && tournamentsData.top10.length >= 10) {
              const resultingMap = sortTournaments([...tournamentsData.top10, newRecord]);
              top10 = resultingMap.slice(0, resultingMap.length - 1);
            } else {
              top10 = arrayUnion(newRecord);
            }

            await updateDoc(tournamentRef, { last10, top10, total: increment(1) });
            return { data: null };
          }

          await setDoc(tournamentRef, { last10: [newRecord], top10: [newRecord], total: 1 });
          return { data: null };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      invalidatesTags: ['Tournament'],
    }),
    getLadderGameData: builder.query<LadderGameResponse, ApiArgs>({
      async queryFn({ uid }) {
        try {
          const ladderGameRef = doc(db, 'ladderGame', uid);
          const getLadderGameSnap = async () => getDoc(ladderGameRef);

          const ladderGameSnap = await getLadderGameSnap();
          if (ladderGameSnap.exists()) {
            const ladderGameData = ladderGameSnap.data() as LadderGameResponse;
            return { data: ladderGameData };
          }
          return { data: { last10: [], top10: [], total: 0 } };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      providesTags: ['LadderGame'],
    }),
    updateLadderGameData: builder.mutation({
      async queryFn(args, { getState }) {
        const state = getState() as RootState;
        const uid = getUid(state);
        const gameFolder = getGameFolder(state);
        const ladderGameStep = getLadderGameStep(state);
        const ladderGameStatus = getLadderGameStatus(state);

        if (!ladderGameStep || !['won', 'failed'].includes(ladderGameStatus)) return { data: null };

        try {
          const ladderGameRef = doc(db, 'ladderGame', uid);
          const getLadderGameSnap = async () => getDoc(ladderGameRef);

          const now = new Date();
          const newRecord = {
            result: ladderGameStep,
            category: gameFolder,
            timeStamp: now.getTime(),
          };

          const ladderGameSnap = await getLadderGameSnap();

          if (ladderGameSnap.exists()) {
            const ladderGameData = ladderGameSnap.data();

            let last10;
            let top10;

            if (ladderGameData?.last10?.length && ladderGameData.last10.length >= 10) {
              const resultingMap = sortByTimeStamp([...ladderGameData.last10, newRecord]);
              last10 = resultingMap.slice(0, resultingMap.length - 1);
            } else {
              last10 = arrayUnion(newRecord);
            }

            if (ladderGameData?.top10?.length && ladderGameData.top10.length >= 10) {
              const resultingMap = sortLadderGames([...ladderGameData.top10, newRecord]);
              top10 = resultingMap.slice(0, resultingMap.length - 1);
            } else {
              top10 = arrayUnion(newRecord);
            }

            await updateDoc(ladderGameRef, { last10, top10, total: increment(1) });
            return { data: null };
          }

          await setDoc(ladderGameRef, { top10: [newRecord], total: 1 });
          return { data: null };
        } catch (error: any) {
          return { error: error.message };
        }
      },
      invalidatesTags: ['LadderGame'],
    }),
  }),
});

export const {
  useGetSoloDataQuery,
  useUpdateSoloDataMutation,
  useGetMultiPlayerDataQuery,
  useUpdateMultiPlayerDataMutation,
  useGetComputerDataQuery,
  useUpdateComputerDataMutation,
  useGetTournamentDataQuery,
  useUpdateTournamentDataMutation,
  useGetLadderGameDataQuery,
  useUpdateLadderGameDataMutation,
} = firestoreApi;
