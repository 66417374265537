import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import {
  CategorySetting,
  CardNumberSetting,
  CardBackSetting,
  OpeningMethodSetting,
  PlayersSetting,
  ComputerGradeSetting,
} from './index';
import { SettingsHeader } from '../../assets/icons';
import { useGameContext } from '../Layout/GameWrapper';
import styles from './DesktopSettings.module.scss';

interface SettingsProps {
  withOpeningMethod?: boolean;
  withCardsNumber?: boolean;
  withPlayers?: boolean;
  withComputerGrade?: boolean;
  cardBackElementsNumber?: number;
}
const Settings: FC<SettingsProps> = ({
  withOpeningMethod,
  withCardsNumber,
  withPlayers,
  withComputerGrade,
  cardBackElementsNumber,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'SETTINGS' });
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const { startGameHandler, isLoadingGame } = useGameContext();

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" className={styles['game-settings-wrap']}>
      {isLoadingGame ? (
        <Stack className={styles['loader-wrap']}>
          <CircularProgress color="primary" />
        </Stack>
      ) : (
        <Stack direction="column" className={styles['game-settings']}>
          <Stack direction="column" alignItems="center" className={styles['settings-header']}>
            <SettingsHeader />
            <Typography variant="h2">{t('TITLE')}</Typography>
          </Stack>
          <Stack
            direction="column"
            gap={6}
            justifyContent="space-between"
            alignItems="center"
            className={styles['game-settings-form']}
          >
            <Stack direction="row" gap={15} justifyContent="space-between" className={styles['carousel-form-wrap']}>
              {withOpeningMethod && <OpeningMethodSetting />}
              <CardBackSetting elementsNumber={cardBackElementsNumber} />
            </Stack>

            <Stack direction="column" gap={6} justifyContent="space-between" className={styles['form-wrap']}>
              <Stack direction="row" gap={4} justifyContent="space-between">
                <CategorySetting />
                {withCardsNumber && (
                  <CardNumberSetting styles={{ dropdown: styles.dropdown, 'cards-number': styles['cards-number'] }} />
                )}
              </Stack>

              {withComputerGrade && (
                <Stack className={styles['computer-grade']}>
                  <ComputerGradeSetting />
                </Stack>
              )}

              {withPlayers && (
                <Stack direction="row" gap={6} justifyContent="space-between">
                  <PlayersSetting />
                </Stack>
              )}
            </Stack>

            <Button
              variant="contained"
              className={clsx(styles['action-button'], styles['start-game-button'])}
              onClick={startGameHandler}
              aria-label={ariaLabelT('BUTTON.PLAY_BUTTON') as string}
            >
              <Typography variant="button">{t('PLAY_BUTTON')}</Typography>
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default Settings;
