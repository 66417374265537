import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FoldersState } from '../types/folders';

const initialState: FoldersState = [];

export const foldersSlice = createSlice({
  name: 'folders',
  initialState,
  reducers: {
    setFolders: (state, action: PayloadAction<FoldersState>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { setFolders } = foldersSlice.actions;

export const getFolders = ({ folders }: { folders: FoldersState }) => folders;

export const { reducer: foldersReducer } = foldersSlice;
