import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ListItemButton, Typography, ListItemText, Tooltip } from '@mui/material';
import { useRoutes } from '../../hooks';
import styles from './SideBarButton.module.scss';

interface ButtonWrapProps {
  children: JSX.Element;
  ariaLabel?: string;
  route?: string;
  onClick?: () => void;
  className: string;
}

const ButtonWrap: FC<ButtonWrapProps> = ({ children, onClick, ariaLabel, route, className }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'NAVIGATION_BAR' });
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const ROUTES = useRoutes();
  const pageName = t(`ROUTE_TO_TITLE_MAP.${route?.substring(3)}`);

  return typeof onClick === 'function' ? (
    <ListItemButton onClick={onClick} className={className} aria-label={ariaLabel}>
      {children}
    </ListItemButton>
  ) : (
    <ListItemButton
      component={RouterLink}
      to={route || ROUTES.ROOT}
      className={className}
      aria-label={(route ? ariaLabelT('LINK.GENERIC', { pageName }) : ariaLabelT('LINK.ROOT')) as string}
    >
      {children}
    </ListItemButton>
  );
};

interface SideBarButtonProps {
  title: string;
  route?: string;
  ariaLabel?: string;
  onClick?: () => void;
  icon: JSX.Element;
  transitionedIsOpen: boolean;
}

const SideBarButton: FC<SideBarButtonProps> = ({ title, route, onClick, icon, transitionedIsOpen, ariaLabel }) => (
  <ButtonWrap
    className={clsx(styles.item, { [styles.expanded]: transitionedIsOpen })}
    onClick={onClick}
    route={route}
    ariaLabel={ariaLabel}
  >
    <>
      {transitionedIsOpen ? (
        icon
      ) : (
        <Tooltip key={title} title={title} placement="right" arrow>
          {icon}
        </Tooltip>
      )}

      {transitionedIsOpen && (
        <ListItemText className={styles.title}>
          <Typography variant="h5">{title}</Typography>
        </ListItemText>
      )}
    </>
  </ButtonWrap>
);

export default SideBarButton;
