export const MAX_IMAGES_NUMBER = 20;
export const TOURNAMENT_CARDS_NUMBER = 20;
export const MIN_CARDS_NUMBER = 4;
export const MAX_COMPUTER_GRADE = 10;
export const MIN_PLAYER_NUMBER = 2;
export const MAX_PLAYER_NUMBER = 6;

export const carouselCardMargin = 8;
export const carouselCardBorder = 3;
export const dropdownMaxHeight = 240;
