import {
  WIDE_BREAKPOINT_1,
  NARROW_BREAKPOINT_0,
  NARROW_BREAKPOINT_1,
  NARROW_BREAKPOINT_2,
  NARROW_BREAKPOINT_3,
  NARROW_BREAKPOINT_4,
} from '../constants';

export const checkWideBreakPoint = (width: number) => {
  if (width > WIDE_BREAKPOINT_1) return 1;
  return 0;
};

export const checkNarrowBreakPoint = (width: number) => {
  if (width < NARROW_BREAKPOINT_4) return 5;
  if (width < NARROW_BREAKPOINT_3) return 4;
  if (width < NARROW_BREAKPOINT_2) return 3;
  if (width < NARROW_BREAKPOINT_1) return 2;
  if (width < NARROW_BREAKPOINT_0) return 1;
  return 0;
};
