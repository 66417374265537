import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogContentText, DialogActions, Button, Typography, Stack } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useRoutes } from '../../hooks';
import styles from '../../assets/styles/modal.module.scss';

interface LoginModalProps {
  open: boolean;
  onClose: () => void;
}
const LoginModal: FC<LoginModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'LOGIN_MODAL' });
  const ROUTES = useRoutes();

  return (
    <Dialog open={open} transitionDuration={0} className={styles.modal}>
      <Stack className={styles.header}>
        <Typography variant="h3">{t('TITLE')}</Typography>
      </Stack>
      <DialogContent className={styles.body}>
        <DialogContentText>
          <Typography variant="body-1">{t('TEXT')}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.footer}>
        <Stack gap={5} direction="row">
          <Button
            variant="text"
            className={styles['secondary-button']}
            onClick={onClose}
            aria-label={t('CANCEL_BUTTON') as string}
          >
            <Typography variant="h4">{t('CANCEL_BUTTON')}</Typography>
          </Button>

          <Button
            component={RouterLink}
            to={ROUTES.AUTH_SIGN_IN}
            variant="contained"
            className={styles['action-button']}
            aria-label={t('LOG_IN_BUTTON') as string}
          >
            <Typography variant="button">{t('LOG_IN_BUTTON')}</Typography>
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default LoginModal;
