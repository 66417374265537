import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import {
  CategorySetting,
  CardNumberSetting,
  CardBackSetting,
  OpeningMethodSetting,
  PlayersSetting,
  ComputerGradeSetting,
} from './index';
import { MobileTopNotch } from '../index';
import { useGameContext } from '../Layout/GameWrapper';
import { useMainLayout } from '../Layout/LayoutWrapper';
import styles from './MobileSettings.module.scss';

const carouselElementsNumberMap: { [key: number]: number } = {
  3: 5,
  4: 4,
  5: 3,
};

interface MobileSettingsProps {
  withOpeningMethod?: boolean;
  withCardsNumber?: boolean;
  withPlayers?: boolean;
  withComputerGrade?: boolean;
}
const MobileSettings: FC<MobileSettingsProps> = ({
  withOpeningMethod,
  withCardsNumber,
  withPlayers,
  withComputerGrade,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'SETTINGS' });
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const { startGameHandler, isLoadingGame } = useGameContext();
  const { narrowBreakPoint } = useMainLayout();
  const elementsNumber = carouselElementsNumberMap[narrowBreakPoint] || 3;

  return (
    <Stack direction="column" alignItems="center" className={styles['game-settings-wrap']}>
      <MobileTopNotch title={t('TITLE')} />

      {isLoadingGame ? (
        <Stack className={styles['game-settings-loader-wrap']}>
          <CircularProgress color="primary" />
        </Stack>
      ) : (
        <Stack
          direction="column"
          gap={10}
          justifyContent="start"
          alignItems="center"
          className={styles['game-settings-form']}
        >
          <Stack direction="column" gap={6} justifyContent="space-between" className={styles['form-wrap']}>
            {withOpeningMethod && <OpeningMethodSetting elementsNumber={elementsNumber} isMobile />}
            <CardBackSetting elementsNumber={elementsNumber} isMobile />

            <CategorySetting />
            {withCardsNumber && (
              <CardNumberSetting styles={{ dropdown: styles.dropdown, 'cards-number': styles['cards-number'] }} />
            )}

            {withComputerGrade && (
              <Stack className={styles['computer-grade']}>
                <ComputerGradeSetting />
              </Stack>
            )}

            {withPlayers && (
              <Stack direction="row" gap={6} justifyContent="space-between">
                <PlayersSetting isMobile />
              </Stack>
            )}
          </Stack>

          <Button
            variant="contained"
            className={clsx(styles['action-button'], styles['start-game-button'])}
            onClick={startGameHandler}
            aria-label={ariaLabelT('BUTTON.PLAY_BUTTON') as string}
          >
            <Typography variant="button">{t('PLAY_BUTTON')}</Typography>
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default MobileSettings;
