import React, { FC, Suspense, lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import Settings from '../../components/Settings/Settings';
import DonationButton from '../../components/DonationButton/DonationButton';
import { getCardsInGame, getGameCardsNumber, resetGameCardsNumber, setGameCardsNumber } from '../../redux/slices';
import { TOURNAMENT_CARDS_NUMBER } from '../../constants';

const Playground = lazy(() => import('../../components/Playground/Playground'));
const TournamentStepper = lazy(() => import('../../components/TournamentStepper/TournamentStepper'));

const Tournament: FC = () => {
  const dispatch = useDispatch();
  const cardsInGame = useSelector(getCardsInGame);
  const gameCardsNumber = useSelector(getGameCardsNumber);

  useEffect(() => {
    dispatch(setGameCardsNumber(TOURNAMENT_CARDS_NUMBER));
    return () => {
      dispatch(resetGameCardsNumber());
    };
  }, [gameCardsNumber]);

  return (
    <>
      {cardsInGame.length ? (
        <Suspense fallback={<CircularProgress color="primary" />}>
          <TournamentStepper />
          <Playground />
        </Suspense>
      ) : (
        <Settings cardBackElementsNumber={7} />
      )}
      <DonationButton onPlayground={!!cardsInGame.length} />
    </>
  );
};

export default Tournament;
