import { useEffect, useContext } from 'react';
import { History } from 'history';
import { UNSAFE_NavigationContext as NavigationContext, Navigator } from 'react-router-dom';

type ExtendNavigator = Navigator & Pick<History, 'block'>;

export interface Transaction {
  retry: () => void;
}

export const useBlocker = (blocker: (tx: Transaction) => void, when: boolean = true) => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return () => {};

    const unblock = (navigator as any as ExtendNavigator).block((tx: Transaction) => {
      const autoUnblockingTx: Transaction = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };
      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
};
