import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {
  foldersReducer,
  cardsReducer,
  userDataReducer,
  gameReducer,
  efficiencyReducer,
  donationReducer,
} from './slices';
import { firestoreApi } from './api';

export const store = configureStore({
  reducer: {
    folders: foldersReducer,
    cards: cardsReducer,
    userData: userDataReducer,
    game: gameReducer,
    efficiency: efficiencyReducer,
    donation: donationReducer,
    [firestoreApi.reducerPath]: firestoreApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([firestoreApi.middleware]),
  devTools: process.env.NODE_ENV === 'development',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
