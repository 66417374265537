import React from 'react';
import { RouteObject, useRoutes, Navigate, useLocation } from 'react-router-dom';
import { CircularProgress, Stack } from '@mui/material';
import { AUTH_ROUTES, ROUTES_WITHOUT_LANGUAGE, makeRoutes } from './constants';
import { AuthLayout, Login, SignUp, Reset, Recover } from './pages/Auth';
import { Layout } from './components';
import {
  Landing,
  Info,
  Solo,
  MultiPlayer,
  Computer,
  Tournament,
  LadderGame,
  Dashboard,
  Page404,
  PrivacyPolicy,
  TermsOfService,
} from './pages';
import { useAuth } from './hooks';

const { SIGN_IN, SIGN_UP, RESET, RECOVER } = AUTH_ROUTES;

const {
  ROOT,
  INFO,
  PRIVACY_POLICY,
  TERMS_OF_SERVICE,
  AUTH,
  SOLO,
  MULTIPLAYER,
  COMPUTER,
  TOURNAMENT,
  LADDER_GAME,
  DASHBOARD,
  SOLO_DEMO,
} = ROUTES_WITHOUT_LANGUAGE;

const CreateRoutes = (rootElement: JSX.Element, language: string, search: string) => {
  const ROUTES = makeRoutes(language);

  const privateRoutes: RouteObject[] = [
    {
      path: ROOT,
      element: rootElement,
    },
    {
      path: DASHBOARD,
      element: <Layout />,
      children: [
        {
          path: '',
          element: <Dashboard />,
        },
      ],
    },
    {
      path: SOLO,
      element: <Layout />,
      children: [
        {
          path: '',
          element: <Solo />,
        },
      ],
    },
    {
      path: SOLO_DEMO,
      element: <Layout />,
      children: [
        {
          path: '',
          element: <Solo />,
        },
      ],
    },
    {
      path: MULTIPLAYER,
      element: <Layout />,
      children: [
        {
          path: '',
          element: <MultiPlayer />,
        },
      ],
    },
    {
      path: COMPUTER,
      element: <Layout />,
      children: [
        {
          path: '',
          element: <Computer />,
        },
      ],
    },
    {
      path: TOURNAMENT,
      element: <Layout />,
      children: [
        {
          path: '',
          element: <Tournament />,
        },
      ],
    },
    {
      path: LADDER_GAME,
      element: <Layout />,
      children: [
        {
          path: '',
          element: <LadderGame />,
        },
      ],
    },
    {
      path: INFO,
      element: <Layout />,
      children: [
        {
          path: '',
          element: <Info />,
        },
      ],
    },
    {
      path: PRIVACY_POLICY,
      element: <Layout />,
      children: [
        {
          path: '',
          element: <PrivacyPolicy />,
        },
      ],
    },
    {
      path: TERMS_OF_SERVICE,
      element: <Layout />,
      children: [
        {
          path: '',
          element: <TermsOfService />,
        },
      ],
    },
    {
      path: AUTH,
      element: <AuthLayout />,
      children: [
        {
          path: '',
          element: <Login />,
        },
        {
          path: SIGN_IN,
          element: <Login />,
        },
        {
          path: SIGN_UP,
          element: <SignUp />,
        },
        {
          path: RESET,
          element: <Reset />,
        },
        {
          path: RECOVER,
          element: <Recover />,
        },
      ],
    },
    {
      path: '*',
      element: <Page404 />,
    },
  ];

  return [
    {
      path: `/${language}`,
      children: privateRoutes,
      element: ['en', 'uk'].includes(language) ? undefined : <Page404 />,
    },
    {
      path: '/',
      element: <Navigate to={`${ROUTES.ROOT}${search}`} />,
    },
    {
      path: '*',
      element: <Page404 />,
    },
  ];
};

const Routes = ({ language = 'en' }: { language: string }) => {
  const { user, loading, initiated } = useAuth();
  const ROUTES = makeRoutes(language);
  const { search } = useLocation();

  const loader = (
    <Stack justifyContent="center" alignItems="center" height="inherit">
      <CircularProgress color="primary" />
    </Stack>
  );

  const rootElement =
    !initiated || loading ? loader : user ? <Navigate to={`${ROUTES.DASHBOARD}${search}`} /> : <Landing />;

  return useRoutes(CreateRoutes(rootElement, language, search));
};

export default Routes;
