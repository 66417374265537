import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Card, CardsState } from '../types/cards';

const initialState: CardsState = {};

export const cardsSlice = createSlice({
  name: 'cards',
  initialState,
  reducers: {
    addFolder: (state, action: PayloadAction<{ folderName: string; cards: Array<Card> }>) => {
      Object.assign(state, { [action.payload.folderName]: action.payload.cards });
    },
    deleteFolder: (state, action: PayloadAction<{ folderName: string }>) => {
      delete state[action.payload.folderName];
    },
  },
});

export const { addFolder, deleteFolder } = cardsSlice.actions;

export const getCards = ({ cards }: { cards: CardsState }) => cards;
export const getFolder =
  (folder: string) =>
  ({ cards }: { cards: CardsState }) =>
    getCards({ cards })[folder];

export const { reducer: cardsReducer } = cardsSlice;
