import { Player } from '../types';

export const players: Array<Player> = [
  { emoji: 128005, name: '' },
  { emoji: 128006, name: '' },
  { emoji: 128007, name: '' },
  { emoji: 128008, name: '' },
  { emoji: 128010, name: '' },
  { emoji: 128011, name: '' },
  { emoji: 128012, name: '' },
  { emoji: 128013, name: '' },
  { emoji: 128017, name: '' },
  { emoji: 128018, name: '' },
  { emoji: 128019, name: '' },
  { emoji: 128021, name: '' },
  { emoji: 128022, name: '' },
  { emoji: 128024, name: '' },
  { emoji: 128025, name: '' },
  { emoji: 128027, name: '' },
  { emoji: 128028, name: '' },
  { emoji: 128029, name: '' },
  { emoji: 128030, name: '' },
  { emoji: 128032, name: '' },
  { emoji: 128034, name: '' },
  { emoji: 128037, name: '' },
  { emoji: 128042, name: '' },
  { emoji: 128044, name: '' },
];

export const computer: Player = { emoji: 129302, name: '' };
export const mainPlayer: Player = { emoji: 128578, name: '' };
