import React, { FC, useEffect, useState, useRef, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { IconButton, Tooltip, Stack, CircularProgress } from '@mui/material';
import { ArrowIcon } from '../../assets/icons';
import { cardRatio, carouselCardBorder, carouselCardMargin } from '../../constants';
import styles from './HorizontalCarousel.module.scss';

interface HorizontalCarouselProps {
  items: Array<{ src: string; hint: string }>;
  selectedItem: number;
  onItemChange: (index: number) => void;
  elementsNumber?: number;
  stretch?: boolean;
}

const HorizontalCarouselLegacy: FC<HorizontalCarouselProps> = ({
  items,
  selectedItem,
  onItemChange,
  elementsNumber = 3,
}) => {
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const { t: altT } = useTranslation('translation', { keyPrefix: 'ALTERNATIVE_TEXT' });
  const centralElementsNumber = elementsNumber < 3 ? 3 : elementsNumber;
  const [cardWidth, setCardWidth] = useState(0);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [placement, setPlacement] = useState<'start' | 'middle' | 'end'>('start');
  const ref = useRef<HTMLDivElement>(null);
  const listWrapRef = useRef<HTMLDivElement>(null);
  const listWrapElement = listWrapRef.current;
  const listRef = useRef<HTMLDivElement>(null);

  const cardWithMargin = cardWidth + 2 * carouselCardBorder + carouselCardMargin;
  const loophole =
    (cardWidth + 2 * carouselCardBorder) * centralElementsNumber + carouselCardMargin * (centralElementsNumber - 1);
  const maxRightShift = 0;

  useEffect(() => {
    const element = ref.current;

    if (element) {
      const maxCardWidth =
        (element.clientWidth - carouselCardMargin * (elementsNumber - 1)) / elementsNumber - 2 * carouselCardBorder;
      setCardWidth(maxCardWidth);
    }

    const listScrollHandler = (e: Event) => {
      // setCurrentPosition(Math.ceil((e.target as HTMLElement).scrollLeft));
      setCurrentPosition((e.target as HTMLElement).scrollLeft);
    };

    if (listWrapElement) {
      listWrapElement.addEventListener('scroll', listScrollHandler);
    }

    return () => {
      if (listWrapElement) {
        listWrapElement.removeEventListener('scroll', listScrollHandler);
      }
    };
  }, [listWrapElement]);

  useEffect(() => {
    const listElement = listRef.current;
    const maxLeftShift = (listElement?.clientWidth || 0) - loophole;

    setPlacement(
      currentPosition === maxRightShift
        ? 'start'
        : Math.floor(Math.abs(currentPosition - maxLeftShift))
        ? 'middle'
        : 'end'
    );
  }, [currentPosition]);

  const handlePrevClick = () => {
    const result = currentPosition - cardWithMargin;
    const correctedResult = result < maxRightShift ? maxRightShift : result;

    if (listWrapElement) {
      listWrapElement.scrollTo({
        left: correctedResult,
        behavior: 'smooth',
      });
    }
  };

  const handleNextClick = () => {
    const listElement = listRef.current;
    const maxLeftShift = (listElement?.clientWidth || 0) - loophole;

    const result = currentPosition + cardWithMargin;
    const correctedResult = result > maxLeftShift ? maxLeftShift : result;

    if (listWrapElement) {
      listWrapElement.scrollTo({
        left: correctedResult,
        behavior: 'smooth',
      });
    }
  };

  const handleItemChange = (index: number) => {
    onItemChange(index);

    const leftFit = index * cardWithMargin;
    const rightFit = (index + 1 - elementsNumber) * cardWithMargin;
    const isOnTheLeft = leftFit < Math.abs(currentPosition);
    const isOnTheRight = rightFit > Math.abs(currentPosition);
    const correctedResult = isOnTheLeft ? leftFit : isOnTheRight ? rightFit : currentPosition;

    if (listWrapElement) {
      listWrapElement.scrollTo({
        left: correctedResult,
        behavior: 'smooth',
      });
    }
  };

  const makeOnItemClick = (index: number) => (e: MouseEvent<HTMLDivElement>) => {
    if (index === selectedItem) {
      e.preventDefault();
    } else {
      handleItemChange(index);
    }
  };

  const buttonSize = (cardWidth / cardRatio) * 0.3;
  const buttonStyle = { width: `${buttonSize}px`, height: `${buttonSize}px`, borderRadius: `${buttonSize / 2}px` };

  return (
    <div
      className={styles['horizontal-carousel-wrap']}
      ref={ref}
      style={{ height: `${Math.ceil(cardWidth / cardRatio + 2 * carouselCardBorder)}px` }}
    >
      <div className={styles['horizontal-carousel-root']} style={{ width: `${loophole}px` }} ref={listWrapRef}>
        <Stack
          flexDirection="row"
          className={clsx(styles['horizontal-carousel-list'], {
            [styles['horizontal-carousel-list-empty']]: !items.length,
          })}
          ref={listRef}
          gap={2}
        >
          {items.length ? (
            items.map(({ src, hint }, index) => (
              <Tooltip
                key={index}
                title={index === selectedItem && hint}
                placement="bottom"
                arrow
                onClick={makeOnItemClick(index)}
              >
                <img
                  src={src}
                  alt={altT('CAROUSEL_ELEMENT') as string}
                  className={clsx(styles['horizontal-carousel-img'], {
                    [styles['horizontal-carousel-img-selected']]: index === selectedItem,
                  })}
                  width={cardWidth + 2 * carouselCardBorder}
                />
              </Tooltip>
            ))
          ) : (
            <CircularProgress color="primary" />
          )}
        </Stack>
      </div>
      <div className={styles['buttons-wrap']}>
        {placement !== 'start' && (
          <IconButton
            className={clsx(styles['horizontal-carousel-arrow'], styles['left-arrow'])}
            onClick={handlePrevClick}
            aria-label={ariaLabelT('BUTTON.SELECT_PREVIOUS_ITEM') as string}
            style={buttonStyle}
          >
            <ArrowIcon />
          </IconButton>
        )}
        {placement !== 'end' && (
          <IconButton
            className={clsx(styles['horizontal-carousel-arrow'], styles['right-arrow'])}
            onClick={handleNextClick}
            aria-label={ariaLabelT('BUTTON.SELECT_NEXT_ITEM') as string}
            style={buttonStyle}
          >
            <ArrowIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default HorizontalCarouselLegacy;
