import React, { FC, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, Tooltip } from '@mui/material';
import { getFolder, setOpeningMethod } from '../../redux/slices';
import { HorizontalCarousel } from '../../components';
import { cardsOpeningMethods } from '../../constants';
import { HelpOutlinedRounded } from '../../assets/icons';
import { Method } from '../../types';
import styles from './DesktopSettings.module.scss';

interface OpeningMethodSettingProps {
  elementsNumber?: number;
  isMobile?: boolean;
}
const OpeningMethodSetting: FC<OpeningMethodSettingProps> = ({ elementsNumber, isMobile }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'SETTINGS' });
  const dispatch = useDispatch();
  const cardOpeningMethods = useSelector(getFolder('card_opening_methods'));
  const [selectedOpeningMethodIndex, setSelectedOpeningMethodIndex] = useState(0);

  const handleOpeningMethodIndexChange = (index: number) => {
    setSelectedOpeningMethodIndex(index);
    dispatch(setOpeningMethod(cardsOpeningMethods[index] as Method));
  };

  const cardOpeningMethodsMap = useMemo(
    () =>
      (cardOpeningMethods || []).reduce((acc: { [key: string]: string }, { fullPath, url }) => {
        const key = fullPath.split('/')[1].split('.')[0];
        acc[key] = url;
        return acc;
      }, {}),
    [cardOpeningMethods]
  );

  const cardsOpeningMethodsCarouselItems = useMemo(
    () =>
      cardsOpeningMethods.reduce((acc: Array<{ src: string; hint: string }>, method) => {
        const element = {
          src: cardOpeningMethodsMap[method],
          hint: t(`CARDS_OPENING_METHODS.${method}`),
        };

        return [...acc, ...(element.src ? [element] : [])];
      }, []),
    [cardOpeningMethods]
  );

  return (
    <Stack direction="column" gap={isMobile ? 4 : 2} className={styles.setting}>
      <Stack
        direction={isMobile ? 'column' : 'row'}
        alignItems={isMobile ? 'start' : 'center'}
        gap={isMobile ? 1 : 2}
        className={styles['setting-head']}
      >
        <Typography variant={isMobile ? 'h4' : 'h5'} color="var(--text-secondary)">
          {t('OPENING_METHOD_LABEL')}
        </Typography>
        {isMobile ? (
          <Typography variant="body2" color="var(--text-secondary)">
            {t('OPENING_METHOD_HINT')}
          </Typography>
        ) : (
          <Tooltip title={t('OPENING_METHOD_HINT')} placement="top" arrow>
            <HelpOutlinedRounded className={styles.hint} />
          </Tooltip>
        )}
      </Stack>
      <HorizontalCarousel
        items={cardsOpeningMethodsCarouselItems}
        selectedItem={selectedOpeningMethodIndex}
        onItemChange={handleOpeningMethodIndexChange}
        elementsNumber={elementsNumber}
      />
    </Stack>
  );
};

export default OpeningMethodSetting;
