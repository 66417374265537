import React, { FC } from 'react';
import clsx from 'clsx';
import { Stack } from '@mui/material';
import styles from './TopPanel.module.scss';
import { useMainLayout } from '../Layout/LayoutWrapper';

interface TopPanelProps {
  children: string | JSX.Element | JSX.Element[];
}
const TopPanel: FC<TopPanelProps> = ({ children }) => {
  const { isMobile } = useMainLayout();
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      className={clsx(styles['top-panel-wrap'], { [styles.mobile]: isMobile })}
    >
      <Stack direction="row" alignItems="center" justifyContent="center" className={styles['top-panel']}>
        {children}
      </Stack>
    </Stack>
  );
};

export default TopPanel;
