import { useContext, useLayoutEffect } from 'react';
import { BrowserHistory } from 'history';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

const useHistoryChange = (cb: () => void) => {
  const navigation = useContext(NavigationContext).navigator as BrowserHistory;

  useLayoutEffect(() => {
    const removeListener = navigation.listen(() => {
      cb();
    });

    return () => removeListener();
  }, []);
};

export default useHistoryChange;
