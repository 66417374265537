import React, { FC, CSSProperties, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { IconButton, Tooltip } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { getShowDonationButton, updateDonation } from '../../redux/slices';
import { useAuth } from '../../hooks';
import { DonateIcon } from '../../assets';
import { cookieDonationName, cookieDonationValue, PATREON } from '../../constants';
import { useMainLayout } from '../Layout/LayoutWrapper';
import { getCookie, setCookie } from '../../utils/cookie';
import styles from './DonationButton.module.scss';

const openPatreon = () => window.open(PATREON, 'patreon');

interface DonationButtonProps {
  onPlayground?: boolean;
}

const DonationButton: FC<DonationButtonProps> = ({ onPlayground }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'DONATION' });
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const { user } = useAuth();
  const showDonationButton = useSelector(getShowDonationButton);
  const dispatch = useDispatch();
  const { isMobile } = useMainLayout();
  const position: CSSProperties = onPlayground ? { bottom: '80px' } : {};

  const [cookieDonationHidden, setCookieDonationHidden] = useState(
    getCookie(cookieDonationName) === cookieDonationValue
  );

  const handleClosePatreonButton = () => {
    setCookie(cookieDonationName, cookieDonationValue, 1);
    setCookieDonationHidden(true);
    dispatch(updateDonation({ showDonationButton: false }));
  };

  if (cookieDonationHidden || !user || !showDonationButton) {
    return null;
  }

  return (
    <div className={clsx(styles['donate-wrap'], { [styles['donate-wrap-mobile']]: isMobile })} style={{ ...position }}>
      <Tooltip title={t('TOOLTIP')} placement="top" arrow>
        <IconButton
          onClick={openPatreon}
          edge="end"
          className={styles['donate-button']}
          aria-label={ariaLabelT('BUTTON.DONATION') as string}
        >
          <DonateIcon className={styles['donate-icon']} />
        </IconButton>
      </Tooltip>
      <CloseIcon
        className={clsx(styles['close-icon'], { [styles['close-icon-mobile']]: isMobile })}
        onClick={handleClosePatreonButton}
        aria-label={ariaLabelT('BUTTON.HIDE_DONATION') as string}
      />
    </div>
  );
};

export default DonationButton;
