import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  movePlayerOnTurn,
  resetTournamentStep,
  moveTournamentStep,
  resetLadderGameStep,
  resetGameCardsNumber,
  moveLadderGameStep,
  resetPlayerOnTurn,
  resetPlayersScores,
} from '../redux/slices';
import { useGame, useEfficiency, useRoutes } from './index';

const useStartGameHandler = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const ROUTES = useRoutes();

  const [isVirginGame, setIsVirginGame] = useState<boolean>(false);
  const [isLoadingGame, setIsLoadingGame] = useState<boolean>(false);

  const { startGame } = useGame();

  const { lastTournamentStep, lastLadderGameStep } = useEfficiency();

  const startGameHandler = async () => {
    setIsLoadingGame(true);

    if ([ROUTES.MULTIPLAYER, ROUTES.COMPUTER].includes(pathname)) {
      dispatch(resetPlayersScores());
      dispatch(resetPlayerOnTurn());
      dispatch(movePlayerOnTurn());
    }

    if (pathname === ROUTES.TOURNAMENT) {
      if (lastTournamentStep) {
        dispatch(resetTournamentStep());
      } else {
        dispatch(moveTournamentStep());
      }
    }

    if (pathname === ROUTES.LADDER_GAME) {
      if (lastLadderGameStep) {
        dispatch(resetLadderGameStep());
        dispatch(resetGameCardsNumber());
      } else {
        dispatch(moveLadderGameStep());
      }
    }

    await startGame();

    setIsLoadingGame(false);
    setIsVirginGame(true);
  };

  return {
    startGameHandler,
    isVirginGame,
    setIsVirginGame,
    isLoadingGame,
  };
};

export default useStartGameHandler;
