import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/material';
import { useRoutes } from '../../hooks';
import styles from './Auth.module.scss';

const Recover: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'AUTH' });
  const ROUTES = useRoutes();

  return (
    <Stack direction="column" gap={8}>
      <Stack direction="column" gap={3}>
        <Typography variant="h1">{t('RECOVERY_LINK_SENT_TITLE')}</Typography>
        <Typography variant="body-1">{t('RECOVERY_CHECK_ACCOUNT')}</Typography>
      </Stack>

      <Button
        component={RouterLink}
        to={ROUTES.AUTH_SIGN_IN}
        variant="text"
        className={styles['text-button']}
        aria-label={t('BACK_TO_LOGIN') as string}
      >
        <Typography variant="h4">{t('BACK_TO_LOGIN')}</Typography>
      </Button>
    </Stack>
  );
};

export default Recover;
