import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GameState, CardInGame, LadderGameStatus } from '../types/game';
import { blindMethod, defaultGameFolderPath, MIN_CARDS_NUMBER } from '../../constants';
import { Method, Player } from '../../types';
import { makeRandom } from '../../utils';

const initialState: GameState = {
  cardsInGame: [],
  playersInGame: [],
  playersMap: [],
  availablePlayers: [],
  playerOnTurn: null,
  gameCardsNumber: MIN_CARDS_NUMBER,
  gameFolder: defaultGameFolderPath,
  gameBackUrl: '',
  openingMethod: blindMethod,
  cardsAreOpening: false,
  computerGrade: 3,
  tournamentStep: -1,
  ladderGameStep: -1,
  ladderGameStatus: 'progress',
};

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setCardsInGame: (state, action: PayloadAction<Array<CardInGame>>) => {
      Object.assign(state, { cardsInGame: action.payload });
    },
    resetCardsInGame: (state) => {
      Object.assign(state, { cardsInGame: [] });
    },
    guessCards: (state, action: PayloadAction<Array<number>>) => {
      const cardsInGame = [...state.cardsInGame];
      action.payload.forEach((cardIndex) => {
        cardsInGame[cardIndex].guessed = true;
      });
      Object.assign(state, { cardsInGame });
    },
    openCard: (state, action: PayloadAction<number>) => {
      const cardsInGame = [...state.cardsInGame];
      cardsInGame[action.payload] = {
        ...cardsInGame[action.payload],
        openedTimes: cardsInGame[action.payload].openedTimes + 1,
      };
      Object.assign(state, { cardsInGame });
    },
    generatePlayerInGame: (state) => {
      const index = makeRandom(state.availablePlayers.length);
      Object.assign(state, {
        playersInGame: [...state.playersInGame, { ...state.availablePlayers[index], score: 0 }],
        availablePlayers: [...state.availablePlayers.slice(0, index), ...state.availablePlayers.slice(index + 1)],
      });
    },
    addPlayerInGame: (state, action: PayloadAction<Player>) => {
      Object.assign(state, { playersInGame: [...state.playersInGame, { ...action.payload, score: 0 }] });
    },
    removePlayerFromGame: (state, action: PayloadAction<number>) => {
      const copy = [...state.playersInGame];
      const position = copy.findIndex(({ emoji }) => action.payload === emoji);

      const { emoji, name } = copy[position];
      Object.assign(state, {
        playersInGame: [...copy.slice(0, position), ...copy.slice(position + 1)],
        availablePlayers: [...state.availablePlayers, { emoji, name }],
      });
    },
    resetPlayersInGame: (state) => {
      Object.assign(state, { playersInGame: [], availablePlayers: state.playersMap });
    },
    initPlayers: (state, action: PayloadAction<Array<Player>>) => {
      Object.assign(state, { playersMap: action.payload, availablePlayers: action.payload });
    },
    updatePlayer: (state, action: PayloadAction<Player>) => {
      const copy = [...state.playersInGame];
      const position = copy.findIndex(({ emoji }) => action.payload.emoji === emoji);

      Object.assign(state, {
        playersInGame: [
          ...copy.slice(0, position),
          { ...copy[position], ...action.payload },
          ...copy.slice(position + 1),
        ],
      });
    },
    risePlayerOnTurnScore: (state) => {
      const copy = [...state.playersInGame];
      const position = copy.findIndex(({ emoji }) => state.playerOnTurn === emoji);

      if (position !== -1) {
        Object.assign(state, {
          playersInGame: [
            ...copy.slice(0, position),
            { ...copy[position], score: copy[position].score + 1 },
            ...copy.slice(position + 1),
          ],
        });
      }
    },
    resetPlayersScores: (state) => {
      const playersInGame = [...state.playersInGame].map((player) => ({ ...player, score: 0 }));
      Object.assign(state, { playersInGame });
    },
    movePlayerOnTurn: (state) => {
      let position = state.playersInGame.findIndex(({ emoji }) => state.playerOnTurn === emoji);
      if (position === state.playersInGame.length - 1) {
        position = -1;
      }
      Object.assign(state, {
        playerOnTurn: state.playersInGame.length ? state.playersInGame[position + 1].emoji : null,
      });
    },
    resetPlayerOnTurn: (state) => {
      Object.assign(state, { playerOnTurn: null });
    },
    setGameCardsNumber: (state, action: PayloadAction<number>) => {
      Object.assign(state, { gameCardsNumber: action.payload });
    },
    incrementGameCardsNumber: (state) => {
      Object.assign(state, { gameCardsNumber: state.gameCardsNumber + 2 });
    },
    resetGameCardsNumber: (state) => {
      Object.assign(state, { gameCardsNumber: MIN_CARDS_NUMBER });
    },
    setGameFolder: (state, action: PayloadAction<string>) => {
      Object.assign(state, { gameFolder: action.payload });
    },
    setGameBackUrl: (state, action: PayloadAction<string>) => {
      Object.assign(state, { gameBackUrl: action.payload });
    },
    setOpeningMethod: (state, action: PayloadAction<Method>) => {
      Object.assign(state, { openingMethod: action.payload });
    },
    resetOpeningMethod: (state) => {
      Object.assign(state, { openingMethod: blindMethod });
    },
    setCardsAreOpening: (state, action: PayloadAction<boolean>) => {
      Object.assign(state, { cardsAreOpening: action.payload });
    },
    setComputerGrade: (state, action: PayloadAction<number>) => {
      Object.assign(state, { computerGrade: action.payload });
    },
    moveTournamentStep: (state) => {
      Object.assign(state, { tournamentStep: state.tournamentStep + 1 });
    },
    resetTournamentStep: (state) => {
      Object.assign(state, { tournamentStep: -1 });
    },
    moveLadderGameStep: (state) => {
      const newLadderGameStep = state.ladderGameStep + 1;
      Object.assign(state, { ladderGameStep: newLadderGameStep });
    },
    resetLadderGameStep: (state) => {
      Object.assign(state, { ladderGameStep: -1 });
    },
    setLadderGameStatus: (state, action: PayloadAction<LadderGameStatus>) => {
      Object.assign(state, { ladderGameStatus: action.payload });
    },
  },
});

export const {
  setCardsInGame,
  resetCardsInGame,
  guessCards,
  openCard,
  generatePlayerInGame,
  addPlayerInGame,
  removePlayerFromGame,
  initPlayers,
  resetPlayersInGame,
  updatePlayer,
  risePlayerOnTurnScore,
  resetPlayersScores,
  movePlayerOnTurn,
  resetPlayerOnTurn,
  setGameCardsNumber,
  incrementGameCardsNumber,
  resetGameCardsNumber,
  setGameFolder,
  setGameBackUrl,
  setOpeningMethod,
  resetOpeningMethod,
  setCardsAreOpening,
  setComputerGrade,
  moveTournamentStep,
  resetTournamentStep,
  moveLadderGameStep,
  resetLadderGameStep,
  setLadderGameStatus,
} = gameSlice.actions;

export const getGame = ({ game }: { game: GameState }) => game;
export const getCardsInGame = ({ game }: { game: GameState }) => getGame({ game }).cardsInGame;
export const getPlayersInGame = ({ game }: { game: GameState }) => getGame({ game }).playersInGame;
export const getPlayerOnTurn = ({ game }: { game: GameState }) => getGame({ game }).playerOnTurn;
export const getGameCardsNumber = ({ game }: { game: GameState }) => getGame({ game }).gameCardsNumber;
export const getGameFolder = ({ game }: { game: GameState }) => getGame({ game }).gameFolder;
export const getGameBackUrl = ({ game }: { game: GameState }) => getGame({ game }).gameBackUrl;
export const getOpeningMethod = ({ game }: { game: GameState }) => getGame({ game }).openingMethod;
export const getCardsAreOpening = ({ game }: { game: GameState }) => getGame({ game }).cardsAreOpening;
export const getComputerGrade = ({ game }: { game: GameState }) => getGame({ game }).computerGrade;
export const getTournamentStep = ({ game }: { game: GameState }) => getGame({ game }).tournamentStep;
export const getLadderGameStep = ({ game }: { game: GameState }) => getGame({ game }).ladderGameStep;
export const getLadderGameStatus = ({ game }: { game: GameState }) => getGame({ game }).ladderGameStatus;

export const { reducer: gameReducer } = gameSlice;
