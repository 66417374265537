import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { JoystickIcon, RocketIcon, SoloIcon, LadderIcon, ChartIcon } from '../../assets';
import styles from './AnimatedItems.module.scss';

interface ItemProps {
  icon: JSX.Element;
  textKey: string;
  backgroundColorVariable: string;
  gap?: number;
  isStatic?: boolean;
}
const Item: FC<ItemProps> = ({ icon, textKey, backgroundColorVariable, gap, isStatic }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'LANDING' });
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      gap={gap || 2.5}
      className={styles[isStatic ? 'static-item' : 'animated-item']}
      style={{ backgroundColor: `var(${backgroundColorVariable})`, transform: isStatic ? 'unset' : undefined }}
    >
      {icon}
      <Typography variant={isStatic ? 'h5' : 'h4'}>{t(textKey)}</Typography>
    </Stack>
  );
};

interface AnimatedItemsProps {
  isMobile: boolean;
}
const AnimatedItems: FC<AnimatedItemsProps> = ({ isMobile }) => {
  const items = [
    { icon: <JoystickIcon />, textKey: 'FEATURES.FEATURE_1', backgroundColorVariable: '--feature-bg-1' },
    { icon: <RocketIcon />, textKey: 'FEATURES.FEATURE_2', backgroundColorVariable: '--feature-bg-2', gap: 2 },
    { icon: <SoloIcon />, textKey: 'FEATURES.FEATURE_3', backgroundColorVariable: '--feature-bg-3' },
    { icon: <ChartIcon />, textKey: 'FEATURES.FEATURE_4', backgroundColorVariable: '--feature-bg-4' },
    { icon: <LadderIcon />, textKey: 'FEATURES.FEATURE_5', backgroundColorVariable: '--feature-bg-5' },
  ];

  if (isMobile) {
    return (
      <Stack direction="column" gap={5} className={styles['static-items']}>
        {items.map((params) => (
          <Item {...params} key={params.textKey} isStatic />
        ))}
      </Stack>
    );
  }

  return (
    <Stack direction="column" gap={4} className={styles['animated-items']}>
      <Stack direction="row" gap={4} className={styles['animated-line']}>
        {items.slice(0, 2).map((params) => (
          <Item {...params} key={params.textKey} />
        ))}
      </Stack>
      <Stack direction="row" gap={4} className={styles['animated-line']}>
        {items.slice(2, 4).map((params) => (
          <Item {...params} key={params.textKey} />
        ))}
      </Stack>
      <Stack direction="row" gap={4} className={styles['animated-line']}>
        {items.slice(4).map((params) => (
          <Item {...params} key={params.textKey} />
        ))}
      </Stack>
    </Stack>
  );
};

export default AnimatedItems;
