import { TFunction } from 'i18next';
import { BasicGame, ComputerGame, LadderGame, Tournament } from '../redux/types/achievements';

export const makeUiDate = (timeStamp: number, t: TFunction) => {
  const date = new Date(timeStamp);
  const minutes = date.getMinutes();
  return `${date.getDate()} ${t(`MONTH_MAP.${date.getMonth()}`)} ${date.getFullYear()} ${date.getHours()}:${
    minutes < 10 ? '0' : ''
  }${minutes}`;
};

const compareStrings = (a: string, b: string) => {
  if (a < b) {
    return 1;
  }
  if (a > b) {
    return -1;
  }
  return 0;
};

export const sortByTimeStamp = (games: Array<BasicGame>) => games.sort((a, b) => b.timeStamp - a.timeStamp);

export const calculateFavoriteCategory = (games: Array<BasicGame>) => {
  const map = games.reduce((acc: { [key: string]: number }, { category }) => {
    acc[category] = (acc[category] || 0) + 1;
    return acc;
  }, {});
  const sortedKeyValues = Object.entries(map).sort((a, b) => b[1] - a[1] || compareStrings(b[0], a[0]));
  return sortedKeyValues[0]?.[0];
};

export const calculateAverageComputerGrade = (games: Array<ComputerGame>) =>
  games.length ? Math.round(games.reduce((acc, { computerGrade }) => acc + computerGrade, 0) / games.length) : 0;

export const sortTournaments = (tournaments: Array<Tournament>) =>
  [...tournaments].sort((a, b) => b.overall - a.overall || b.timeStamp - a.timeStamp);

export const sortLadderGames = (ladderGames: Array<LadderGame>) =>
  [...ladderGames].sort((a, b) => b.result - a.result || b.timeStamp - a.timeStamp);
