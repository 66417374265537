import React, { FC, HTMLAttributes } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, Slider, SliderThumb } from '@mui/material';
import { getComputerGrade, setComputerGrade } from '../../redux/slices';
import { MAX_COMPUTER_GRADE } from '../../constants';
import { SliderThumbIcon } from '../../assets';
import styles from './DesktopSettings.module.scss';

interface ThumbComponentProps extends HTMLAttributes<unknown> {}

const ThumbComponent: FC<ThumbComponentProps> = ({ children, ...other }) => (
  <SliderThumb {...other}>
    {children}
    <SliderThumbIcon />
  </SliderThumb>
);

const ComputerGradeSetting: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'SETTINGS' });
  const dispatch = useDispatch();
  const computerGrade = useSelector(getComputerGrade);

  const onSliderChange = (event: Event, newValue: number | Array<number>) => {
    dispatch(setComputerGrade(newValue as number));
  };

  return (
    <Stack direction="column" spacing={2} alignSelf="center" width="inherit">
      <Typography variant="h5" color="var(--text-secondary)">
        {t('COMPUTER_GRADE_LABEL')}
      </Typography>
      <Stack justifyContent="center" className={styles['computer-grade-slider-wrap']}>
        <Slider
          className={styles['computer-grade-slider']}
          slots={{ thumb: ThumbComponent }}
          onChange={onSliderChange}
          valueLabelDisplay="auto"
          step={1}
          marks
          min={1}
          max={MAX_COMPUTER_GRADE}
          value={computerGrade}
        />
      </Stack>
    </Stack>
  );
};

export default ComputerGradeSetting;
