import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './en.json';
import ukTranslation from './uk.json';

const instance = i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      uk: {
        translation: ukTranslation,
      },
    },
    fallbackLng: 'en',
    supportedLngs: ['en', 'uk'],
    detection: {
      order: ['path', 'navigator', 'htmlTag'],
      convertDetectedLanguage: (lng) => lng.substring(0, 2),
    },
  });

export default instance;
