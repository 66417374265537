import React, { useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Stack, Button } from '@mui/material';
import { DashboardIcon, InfoIcon, JoystickIcon } from '../../assets/icons';
import { LogoutModal, LoginModal } from '../../components';
import { useAuth, useRoutes } from '../../hooks';
import BottomNavigation from './BottomNavigation';
import { getCardsInGame } from '../../redux/slices';
import styles from './BottomBar.module.scss';

const BottomBar = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'NAVIGATION_BAR' });
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const ROUTES = useRoutes();
  const { logout, outOfSession } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const cardsInGame = useSelector(getCardsInGame);
  const [isOpenNavigation, setIsOpenNavigation] = useState(false);
  const openNavigation = () => setIsOpenNavigation(true);
  const closeNavigation = () => setIsOpenNavigation(false);
  const [logoutModalIsOpen, setLogoutModalIsOpen] = useState<boolean>(false);
  const openLogoutModal = () => setLogoutModalIsOpen(true);
  const closeLogoutModal = () => setLogoutModalIsOpen(false);
  const [loginModalIsOpen, setLoginModalIsOpen] = useState<boolean>(false);
  const openLoginModal = () => setLoginModalIsOpen(true);
  const closeLoginModal = () => setLoginModalIsOpen(false);

  const onLogOut = () => logout().then(() => navigate(ROUTES.AUTH_SIGN_IN));

  const handleSessionContentAccess = (e: MouseEvent<HTMLDivElement>) => {
    if (outOfSession) {
      e.stopPropagation();
      openLoginModal();
    }
  };

  if (cardsInGame.length) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-around" gap={4} className={styles['bottom-bar']}>
      <div onClick={handleSessionContentAccess}>
        <Button
          component={RouterLink}
          to={ROUTES.DASHBOARD}
          variant="text"
          className={clsx(styles['icon-button'], {
            [styles.active]: pathname.startsWith(ROUTES.DASHBOARD),
            [styles.disabled]: outOfSession,
          })}
          disabled={outOfSession}
          aria-label={ariaLabelT('LINK.GENERIC', { pageName: ROUTES.DASHBOARD.substring(3) }) as string}
        >
          <Stack direction="column" alignItems="center" gap={1}>
            <DashboardIcon />
            <Typography variant="body2">{t(`ROUTE_TO_TITLE_MAP.${ROUTES.DASHBOARD.substring(3)}`)}</Typography>
          </Stack>
        </Button>
      </div>

      <Button
        variant="text"
        className={styles['play-icon-button']}
        onClick={openNavigation}
        aria-label={ariaLabelT('BUTTON.SHOW_GAME_MODES') as string}
      >
        <Stack direction="column" alignItems="center" gap={3}>
          <Stack className={styles['play-icon-wrap']}>
            <JoystickIcon />
          </Stack>
        </Stack>
      </Button>

      <Button
        component={RouterLink}
        to={ROUTES.INFO}
        variant="text"
        className={clsx(styles['icon-button'], {
          [styles.active]: pathname.startsWith(ROUTES.INFO),
        })}
        aria-label={ariaLabelT('LINK.GENERIC', { pageName: ROUTES.INFO.substring(3) }) as string}
      >
        <Stack direction="column" alignItems="center" gap={1}>
          <InfoIcon />
          <Typography variant="body2">{t(`ROUTE_TO_TITLE_MAP.${ROUTES.INFO.substring(3)}`)}</Typography>
        </Stack>
      </Button>

      <BottomNavigation {...{ openLoginModal, openLogoutModal, isOpenNavigation, closeNavigation }} />

      <LoginModal open={loginModalIsOpen} onClose={closeLoginModal} />
      <LogoutModal open={logoutModalIsOpen} onClose={closeLogoutModal} onLogOut={onLogOut} />
    </Stack>
  );
};

export default BottomBar;
