import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography, Stack, Link } from '@mui/material';
import clsx from 'clsx';
import { LINKEDIN, ADMIN_EMAIL } from '../../constants';
import AuthorLogo from '../../assets/icons/author.jpeg';
import styles from './Author.module.scss';

interface AuthorProps {
  aside?: boolean;
}
const Author: FC<AuthorProps> = ({ aside }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'INFO' });
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const { t: altT } = useTranslation('translation', { keyPrefix: 'ALTERNATIVE_TEXT' });

  return (
    <Stack direction="column" gap={1} className={clsx(styles.copyright, { [styles.aside]: aside })}>
      <img src={AuthorLogo} alt={altT('AUTHOR') as string} loading="lazy" className={styles.author} />
      <Typography variant="body-1">{t('COPYRIGHT_BY')}</Typography>

      <Typography variant="h4">
        <Link
          href={LINKEDIN}
          underline="none"
          target="my-linkedin-profile"
          color="var(--primary-main)"
          aria-label={t('LINKEDIN_LINK_ARIA_LABEL') as string}
        >
          {t('COPYRIGHT_AUTHOR')}
        </Link>
      </Typography>

      <Typography variant="body-2">
        <Trans
          i18nKey="INFO.CONTACT_ME_TEXT"
          components={[
            <Link
              href={`mailto:${ADMIN_EMAIL}`}
              target="send-email"
              className={styles.link}
              aria-label={ariaLabelT('LINK.SEND_EMAIL', { email: ADMIN_EMAIL }) as string}
            />,
          ]}
          values={{ email: ADMIN_EMAIL }}
        />
      </Typography>
    </Stack>
  );
};

export default Author;
