import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { getUserName, setUserData } from '../redux/slices';
import useAuth from './useAuth';

const useUserData = () => {
  const dispatch = useDispatch();
  const { user, loading: authLoading } = useAuth();

  const userName = useSelector(getUserName);
  const [loading, setLoading] = useState(true);

  const fetchUserName = async () => {
    try {
      const q = query(collection(db, 'users'), where('uid', '==', user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      dispatch(setUserData({ name: data.name, uid: data.uid }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  useEffect(() => {
    if (user) {
      if (userName) {
        setLoading(false);
      } else {
        fetchUserName().finally(() => setLoading(false));
      }
    } else if (!authLoading) {
      setLoading(false);
    }
  }, [user, authLoading, userName]);

  return {
    userName,
    loading,
  };
};

export default useUserData;
