import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Drawer, ListItemButton, CircularProgress, Typography, Stack, IconButton } from '@mui/material';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { getCardsAreOpening } from '../../redux/slices';
import {
  LogoWide,
  Logo,
  DashboardIcon,
  SoloIcon,
  ComputerIcon,
  MultiplayerIcon,
  LadderIcon,
  TournamentIcon,
  InfoIcon,
  LoginIcon,
  LogoutIcon,
} from '../../assets/icons';
import { SideBarItem, SideBarButton, LogoutModal, LoginModal } from '../../components';
import { useAuth, useRoutes } from '../../hooks';
import { useMainLayout } from '../Layout/LayoutWrapper';
import { ROUTES_WITHOUT_LANGUAGE } from '../../constants';
import styles from './SideBar.module.scss';

interface SideBarProps {
  userDataLoading: boolean;
  userName: string;
}
const SideBar: FC<SideBarProps> = ({ userDataLoading, userName }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'NAVIGATION_BAR' });
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const ROUTES = useRoutes();
  const { logout, outOfSession } = useAuth();
  const navigate = useNavigate();
  const { narrowBreakPoint, isOpen, toggle, close } = useMainLayout();
  const [transitionedIsOpen, setTransitionedIsOpen] = useState(isOpen);
  const [logoutModalIsOpen, setLogoutModalIsOpen] = useState<boolean>(false);
  const openLogoutModal = () => setLogoutModalIsOpen(true);
  const closeLogoutModal = () => setLogoutModalIsOpen(false);
  const [loginModalIsOpen, setLoginModalIsOpen] = useState<boolean>(false);
  const openLoginModal = () => setLoginModalIsOpen(true);
  const closeLoginModal = () => setLoginModalIsOpen(false);

  const cardsAreOpening = useSelector(getCardsAreOpening);

  const navigationStructure = [
    { route: ROUTES.COMPUTER, icon: <ComputerIcon /> },
    { route: ROUTES.MULTIPLAYER, icon: <MultiplayerIcon /> },
    { route: ROUTES.LADDER_GAME, icon: <LadderIcon /> },
    { route: ROUTES.TOURNAMENT, icon: <TournamentIcon /> },
  ];

  const sideBarNavigation = useMemo(() => {
    const soloNavigationItem = {
      route: ROUTES[outOfSession ? 'SOLO_DEMO' : 'SOLO'],
      icon: <SoloIcon />,
      disabled: false,
    };
    return [soloNavigationItem, ...navigationStructure].map((v) => ({
      title: t(`ROUTE_TO_TITLE_MAP.${v.route.substring(3)}`),
      disabled: outOfSession,
      ...v,
    }));
  }, [outOfSession]);

  const onLogOut = () => logout().then(() => navigate(ROUTES.AUTH_SIGN_IN));

  const clickOutside = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const isClickOutside = !target.closest(`.${styles.sideBar}`);
      const isClickInApp = target.closest('#root');

      if (isClickInApp && isClickOutside && isOpen && narrowBreakPoint) {
        e.stopPropagation();
        e.preventDefault();
        close();
      }
    },
    [isOpen, narrowBreakPoint]
  );

  useEffect(() => {
    document.addEventListener('click', clickOutside, true);
    return () => document.removeEventListener('click', clickOutside, true);
  }, [isOpen, narrowBreakPoint]);

  useEffect(() => {
    setTransitionedIsOpen(isOpen);
  }, [isOpen]);

  return (
    <Stack className={clsx(styles.sideBar, { [styles.open]: isOpen })}>
      <IconButton
        className={clsx(styles['toggle-button'], { [styles.open]: isOpen })}
        size="small"
        disabled={cardsAreOpening}
        onClick={toggle}
        aria-label={ariaLabelT(`BUTTON.${isOpen ? 'COLLAPSE_SIDE_BAR' : 'EXPAND_SIDE_BAR'}`) as string}
      >
        {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton>

      <Drawer variant="permanent" open={isOpen} onClose={toggle}>
        <Stack direction="column" justifyContent="space-between" className={styles['sideBar-paper']}>
          <Stack direction="column" alignItems="self-start" gap={6} className={styles.items}>
            <ListItemButton
              component={NavLink}
              to="/"
              className={styles['list-item-button']}
              aria-label={ariaLabelT('LINK.ROOT') as string}
            >
              {transitionedIsOpen ? (
                <LogoWide className={styles['brand-icon']} />
              ) : (
                <Logo className={styles['brand-icon']} />
              )}
            </ListItemButton>

            <Stack direction="column" alignItems="self-start" gap={3}>
              <SideBarItem
                title={t(`ROUTE_TO_TITLE_MAP./${ROUTES_WITHOUT_LANGUAGE.DASHBOARD}`)}
                route={ROUTES.DASHBOARD}
                icon={<DashboardIcon />}
                transitionedIsOpen={transitionedIsOpen}
                disabled={outOfSession}
                openLoginModal={openLoginModal}
              />

              <Stack
                direction="column"
                className={clsx(styles['game-items-list'], { [styles.collapsed]: !transitionedIsOpen })}
              >
                {transitionedIsOpen && (
                  <Typography variant="body-2" className={styles['game-modes-title']}>
                    {t('GAME_MODES')}
                  </Typography>
                )}
                {sideBarNavigation.map(({ route, title, icon, disabled }) => (
                  <SideBarItem
                    key={route}
                    route={route}
                    title={title}
                    icon={icon}
                    transitionedIsOpen={transitionedIsOpen}
                    disabled={disabled}
                    openLoginModal={openLoginModal}
                  />
                ))}
              </Stack>
            </Stack>
          </Stack>

          <Stack justifyContent="center" className={styles['sideBar-footer']}>
            {userDataLoading ? (
              <div className={styles['loader-wrap']}>
                <CircularProgress color="primary" />
              </div>
            ) : (
              <Stack direction="column" gap={6} alignItems="start">
                {transitionedIsOpen && (
                  <Typography variant="body-2" color="var(--text-secondary)">
                    {userName}
                  </Typography>
                )}

                <SideBarButton
                  title={t(`ROUTE_TO_TITLE_MAP./${ROUTES_WITHOUT_LANGUAGE.INFO}`)}
                  route={ROUTES.INFO}
                  icon={<InfoIcon />}
                  transitionedIsOpen={transitionedIsOpen}
                />

                {outOfSession ? (
                  <SideBarButton
                    title={t(`ROUTE_TO_TITLE_MAP./${ROUTES_WITHOUT_LANGUAGE.AUTH_SIGN_IN}`)}
                    route={ROUTES.AUTH_SIGN_IN}
                    icon={<LoginIcon />}
                    transitionedIsOpen={transitionedIsOpen}
                  />
                ) : (
                  <SideBarButton
                    title={t('LOG_OUT')}
                    onClick={openLogoutModal}
                    icon={<LogoutIcon />}
                    transitionedIsOpen={transitionedIsOpen}
                    ariaLabel={ariaLabelT('BUTTON.OPEN_LOGOUT_MODAL') as string}
                  />
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Drawer>

      <LoginModal open={loginModalIsOpen} onClose={closeLoginModal} />
      <LogoutModal open={logoutModalIsOpen} onClose={closeLogoutModal} onLogOut={onLogOut} />
    </Stack>
  );
};

export default SideBar;
