import React, { FC, useEffect, useState, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import {
  getCardsInGame,
  openCard,
  guessCards,
  getGameBackUrl,
  getCardsAreOpening,
  setCardsAreOpening,
  getOpeningMethod,
  setGameBackUrl,
  getFolder,
  movePlayerOnTurn,
  risePlayerOnTurnScore,
  resetPlayersScores,
  resetPlayerOnTurn,
  resetCardsInGame,
  getPlayerOnTurn,
  getComputerGrade,
  resetOpeningMethod,
  resetTournamentStep,
  resetGameCardsNumber,
  setTournamentEfficiency,
  setSoloEfficiency,
  resetTournamentEfficiency,
  resetLadderGameStep,
  setLadderGameStatus,
  incrementGameCardsNumber,
} from '../../redux/slices';
import {
  useUpdateSoloDataMutation,
  useUpdateTournamentDataMutation,
  useUpdateMultiPlayerDataMutation,
  useUpdateComputerDataMutation,
  useUpdateLadderGameDataMutation,
} from '../../redux/api';
import { Card, GameResultModal, LeaveGameModal } from '../index';
import {
  blindMethod,
  CARD_TIMER,
  SHOWING_CARD_DELAY,
  CARDS_LOADING_MIN_TIMER,
  CARDS_CLICK_CHECK_DELAY,
  CARDS_CLICK_SET_DELAY,
  computer,
  TURN_CARD_TIMEOUT,
  cardCornerRadiusRatio,
  maxCardCornerRadius,
} from '../../constants';
import {
  fitCardsFromHeight,
  fitCardsFromWidth,
  makeRandom,
  openCards,
  getNotGuessedCards,
  getFirstCorrectIndex,
  getFirstRemainingCopy,
  getSecondRemainingCopy,
  getReplyIndexInNotGuessed,
  maximiseCardDimensions,
} from '../../utils';
import { Method } from '../../types';
import { useEfficiency, useBlocker, Transaction, useRoutes } from '../../hooks';
import { useGameContext } from '../Layout/GameWrapper';
import scssVariables from '../../assets/styles/variables.module.scss';
import { useMainLayout } from '../Layout/LayoutWrapper';
import styles from './Playground.module.scss';

const PlaygroundLoader: FC = () => (
  <Stack className={styles['game-loader-wrap']}>
    <Stack className={styles['loader-wrap']}>
      <CircularProgress color="primary" />
    </Stack>
  </Stack>
);

const initCardStyle = { width: 0, height: 0 };

const Playground: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'PLAYGROUND' });
  const ROUTES = useRoutes();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { isMobile } = useMainLayout();

  const [updateSoloData, { isLoading: isUpdatingSoloData }] = useUpdateSoloDataMutation();
  const [updateMultiPlayerData, { isLoading: isUpdatingMultiPlayerData }] = useUpdateMultiPlayerDataMutation();
  const [updateComputerData, { isLoading: isUpdatingComputerData }] = useUpdateComputerDataMutation();
  const [updateTournamentData, { isLoading: isUpdatingTournamentData }] = useUpdateTournamentDataMutation();
  const [updateLadderGameData, { isLoading: isUpdatingLadderGameData }] = useUpdateLadderGameDataMutation();
  const isUpdatingGameData =
    isUpdatingSoloData ||
    isUpdatingMultiPlayerData ||
    isUpdatingComputerData ||
    isUpdatingTournamentData ||
    isUpdatingLadderGameData;

  const cardsBacks = useSelector(getFolder('card_backs'));
  const cardsInGame = useSelector(getCardsInGame);
  const gameBackUrl = useSelector(getGameBackUrl);
  const openingMethod = useSelector(getOpeningMethod);
  const cardsAreOpening = useSelector(getCardsAreOpening);
  const playerOnTurn = useSelector(getPlayerOnTurn);
  const computerGrade = useSelector(getComputerGrade);

  const clearInternalShowCardsTimeouts = useRef(() => {});
  const clearShowCardsTimeout = useRef(() => {});
  const windowHeight = useRef(window.innerHeight);
  const cardBox = useRef(null as HTMLDivElement | null);
  const cards = useRef([] as Array<{ el: HTMLDivElement | null; i: number }>);
  const retryLeave = useRef<(() => void) | null>(null);

  const { getCurrentEfficiency, currentMethodType, lastTournamentStep, lastLadderGameStep } = useEfficiency();

  const { startGameHandler, isVirginGame, setIsVirginGame, isLoadingGame } = useGameContext();

  const [openedCards, _setOpenedCards] = useState<Array<number>>([]);
  const [cumulativeOpenedCards, _setCumulativeOpenedCards] = useState<Array<number>>([]);
  const [isCorrect, setIsCorrect] = useState<boolean | undefined>();
  const [cardStyle, setCardStyle] = useState<{ width: number; height: number }>(initCardStyle);
  const resetCardStyle = () => setCardStyle(initCardStyle);
  const [computerIsPlaying, setComputerIsPlaying] = useState<boolean>(false);

  const [gameResultModalIsOpen, setGameResultModalIsOpen] = useState<boolean>(false);
  const openGameResultModal = () => setGameResultModalIsOpen(true);

  const [leaveGameModalIsOpen, setLeaveGameModalIsOpen] = useState<boolean>(false);
  const openLeaveGameModal = () => setLeaveGameModalIsOpen(true);
  const closeLeaveGameModal = () => {
    setLeaveGameModalIsOpen(false);
    retryLeave.current = null;
  };

  const resetGame = () => {
    dispatch(resetCardsInGame());
    resetPlaygroundState();
  };

  const closeGameResultModal = () => {
    resetPlaygroundState();
    setGameResultModalIsOpen(false);
  };

  const setCumulativeOpenedCards = (arg: number[]) =>
    _setCumulativeOpenedCards((currentCumulativeOpenedCards) =>
      Array.from(new Set([...currentCumulativeOpenedCards, ...arg]))
    );

  const resetCumulativeOpenedCards = () => _setCumulativeOpenedCards([]);

  const setOpenedCards = (newOpenedCards: number[] | ((currentOpenedCards: number[]) => number[])) => {
    if (typeof newOpenedCards === 'function') {
      const forwardCb = (currentOpenedCards: number[]) => {
        const result = newOpenedCards(currentOpenedCards);
        setCumulativeOpenedCards(result);
        return result;
      };

      return _setOpenedCards(forwardCb);
    }

    setCumulativeOpenedCards(newOpenedCards);
    return _setOpenedCards(newOpenedCards);
  };

  const resetPlaygroundState = () => {
    setOpenedCards([]);
    resetCumulativeOpenedCards();
    setIsCorrect(undefined);
    resetCardStyle();
  };

  const gameInProgress = useMemo(() => cardsInGame.findIndex((card) => !card.guessed) !== -1, [cardsInGame]);

  const blocker = (tx: Transaction) => {
    openLeaveGameModal();
    retryLeave.current = () => tx.retry();
  };

  useBlocker(blocker, pathname !== ROUTES.SOLO_DEMO);

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(
    () => () => {
      dispatch(resetCardsInGame());
      dispatch(resetOpeningMethod());
      dispatch(resetGameCardsNumber());

      if ([ROUTES.MULTIPLAYER, ROUTES.COMPUTER].includes(pathname)) {
        dispatch(resetPlayersScores());
      }

      if (pathname === ROUTES.TOURNAMENT) {
        dispatch(resetTournamentStep());
        dispatch(resetTournamentEfficiency());
      }

      if (pathname === ROUTES.LADDER_GAME) {
        dispatch(resetLadderGameStep());
        dispatch(setLadderGameStatus('progress'));
      }
    },
    []
  );

  useEffect(() => {
    if (cardsInGame.length && cardBox.current && isVirginGame) {
      setIsVirginGame(false);
      dispatch(setCardsAreOpening(true));
      const timeout = setTimeout(() => {
        clearInternalShowCardsTimeouts.current = showCards(cardsInGame.length);
      }, SHOWING_CARD_DELAY); // start showing cards in 0.2 second -- for better UI

      const setCardsAreOpeningTimeout = setTimeout(
        () => dispatch(setCardsAreOpening(false)),
        openingMethod === blindMethod
          ? 0
          : SHOWING_CARD_DELAY + CARDS_LOADING_MIN_TIMER + cardsInGame.length * CARD_TIMER
      );

      clearShowCardsTimeout.current = () => {
        clearTimeout(timeout);
        clearTimeout(setCardsAreOpeningTimeout);
      };
    }
  }, [cardsInGame, isVirginGame, openingMethod]);

  useEffect(() => {
    if (cardBox.current) {
      const { top, width: availableWidth } = cardBox.current.getBoundingClientRect();
      const playgroundPadding = parseInt(scssVariables['playground-padding'], 10);
      const availableHeight = windowHeight.current - top - playgroundPadding;

      if (cardsInGame.length) {
        const fromWidth = fitCardsFromWidth(availableHeight, availableWidth, cardsInGame.length, isMobile);
        const fromHeight = fitCardsFromHeight(availableHeight, availableWidth, cardsInGame.length, isMobile);
        const biggerCardDimensions = fromWidth.surface > fromHeight.surface ? fromWidth.style : fromHeight.style;

        const maximisedCardDimensions = maximiseCardDimensions({
          cardDimensions: biggerCardDimensions,
          availableHeight,
          availableWidth,
          cardsNumber: cardsInGame.length,
          isMobile,
        });

        setCardStyle(maximisedCardDimensions);
      }
    }
  }, [cardsInGame, isVirginGame]);

  useEffect(() => {
    if (!isLoadingGame && !gameInProgress) {
      const currentEfficiency = getCurrentEfficiency();
      if ([ROUTES.SOLO, ROUTES.SOLO_DEMO].includes(pathname)) {
        dispatch(setSoloEfficiency(currentEfficiency));
        updateSoloData({}).finally();
      } else if (pathname === ROUTES.MULTIPLAYER) {
        updateMultiPlayerData({}).finally();
      } else if (pathname === ROUTES.COMPUTER) {
        updateComputerData({}).finally();
      } else if (pathname === ROUTES.TOURNAMENT) {
        dispatch(setTournamentEfficiency({ method: currentMethodType, value: currentEfficiency }));
      } else if (pathname === ROUTES.LADDER_GAME && lastLadderGameStep) {
        dispatch(setLadderGameStatus('won'));
        updateLadderGameData({}).finally();
      } else if (pathname === ROUTES.LADDER_GAME) {
        dispatch(incrementGameCardsNumber());
      }

      if (pathname === ROUTES.TOURNAMENT && lastTournamentStep) {
        updateTournamentData({}).finally();
      }

      openGameResultModal();
    }
  }, [isLoadingGame, getCurrentEfficiency, lastLadderGameStep]);

  useEffect(() => {
    if (gameInProgress && playerOnTurn === computer.emoji && !openedCards.length && !computerIsPlaying) {
      makeComputerPlaying();
    }
  }, [playerOnTurn, openedCards, cardsInGame, computerIsPlaying]);

  const getColumnsNumber = () => {
    if (!cardBox.current) return 0;
    const firstCardRect = cards.current[0].el?.getBoundingClientRect() || { left: 0, right: 0 };
    const secondCardRect = cards.current[1].el?.getBoundingClientRect() || { left: 0, right: 0 };
    const cardWidthWithGap = secondCardRect.left - firstCardRect.left;
    const gap = secondCardRect.left - firstCardRect.right;

    return Math.floor((cardBox.current.offsetWidth + gap) / cardWidthWithGap);
  };

  const showCards = (cardsNumber: number) => {
    const columnsNumber = getColumnsNumber();
    if (!columnsNumber) return () => {};
    const rowsNumber = Math.ceil(cardsNumber / columnsNumber);

    const increase = (i: number) => _setOpenedCards((currentOpenedCards) => [...currentOpenedCards, i]);
    const decrease = () => _setOpenedCards((currentOpenedCards) => currentOpenedCards.slice(1));

    return openCards({ cardsNumber, columnsNumber, rowsNumber, increase, decrease, method: openingMethod as Method });
  };

  const makeTurnCard = (i: number) => () => {
    if (cardsAreOpening || cardsInGame[i].guessed) return;

    setOpenedCards((currentOpenedCards) => {
      if (currentOpenedCards.length === 2) return currentOpenedCards;

      const alreadyClicked = currentOpenedCards.includes(i);
      if (!alreadyClicked) {
        setTimeout(() => dispatch(openCard(i)), 0);
      }

      const result = alreadyClicked ? currentOpenedCards : [...currentOpenedCards, i];
      if (result.length === 2) {
        const pairIsCorrect = cardsInGame[result[0]].fullPath === cardsInGame[result[1]].fullPath;
        setTimeout(() => setIsCorrect(pairIsCorrect), CARDS_CLICK_CHECK_DELAY);

        setTimeout(() => {
          if (pairIsCorrect) {
            dispatch(guessCards(result));

            if ([ROUTES.MULTIPLAYER, ROUTES.COMPUTER].includes(pathname)) {
              dispatch(risePlayerOnTurnScore());
            }
          } else {
            if ([ROUTES.MULTIPLAYER, ROUTES.COMPUTER].includes(pathname)) {
              dispatch(movePlayerOnTurn());
            }

            if (pathname === ROUTES.LADDER_GAME) {
              dispatch(setLadderGameStatus('failed'));
              updateLadderGameData({}).finally();
              openGameResultModal();
              return;
            }
          }
          setOpenedCards([]);
          setIsCorrect(undefined);
        }, CARDS_CLICK_SET_DELAY);
      }
      return result;
    });
  };

  const makeComputerPlaying = () => {
    setComputerIsPlaying(true);
    let notGuessedCards = getNotGuessedCards(cardsInGame);

    const firstCorrectIndex = getFirstCorrectIndex({ notGuessedCards, cumulativeOpenedCards });
    const firstRemainingCopy = getFirstRemainingCopy({ notGuessedCards, cumulativeOpenedCards });
    const firstRandomIndex = makeRandom(firstRemainingCopy.length);

    // comp opens firstCorrectIndex if he knows it's locations and duplicate location as well
    const { position: firstOpenedCardPosition, fullPath: firstOpenedCardFullPath } =
      (firstCorrectIndex === -1 ? firstRemainingCopy[firstRandomIndex] : notGuessedCards[firstCorrectIndex]) || {};

    notGuessedCards = notGuessedCards.filter(({ position }) => position !== firstOpenedCardPosition);
    //----------------------------------------------------------------------------------------------------------------------
    const replyIndexInNotGuessed = getReplyIndexInNotGuessed({
      notGuessedCards,
      cumulativeOpenedCards,
      firstOpenedCardFullPath,
      firstOpenedCardPosition,
    });
    const secondRemainingCopy = getSecondRemainingCopy({
      notGuessedCards,
      cumulativeOpenedCards,
      replyIndexInNotGuessed,
    });
    const secondRandomIndex = makeRandom(secondRemainingCopy.length);

    const computerWillGuess =
      [
        ...Array.from({ length: computerGrade }, () => true),
        ...Array.from({ length: 10 - computerGrade }, () => false),
      ][makeRandom(10)] && replyIndexInNotGuessed !== -1;

    const { position: secondOpenedCardPosition } =
      (computerWillGuess ? notGuessedCards[replyIndexInNotGuessed] : secondRemainingCopy[secondRandomIndex]) || {};

    if (typeof firstOpenedCardPosition === 'number') {
      setTimeout(() => {
        makeTurnCard(firstOpenedCardPosition)();
      }, TURN_CARD_TIMEOUT);
    }

    if (typeof secondOpenedCardPosition === 'number') {
      setTimeout(() => {
        makeTurnCard(secondOpenedCardPosition)();
        setComputerIsPlaying(false);
      }, TURN_CARD_TIMEOUT * 2);
    }
  };

  const leaveGameHandler = () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);

    if (typeof retryLeave.current === 'function') {
      retryLeave.current();
      retryLeave.current();
      retryLeave.current = null;
    } else {
      clearShowCardsTimeout.current();
      clearInternalShowCardsTimeouts.current();
      resetGame();

      dispatch(setGameBackUrl(cardsBacks[0].url));

      if ([ROUTES.MULTIPLAYER, ROUTES.COMPUTER].includes(pathname)) {
        dispatch(resetPlayerOnTurn());
        dispatch(resetPlayersScores());
      }

      if (pathname === ROUTES.TOURNAMENT) {
        dispatch(resetTournamentStep());
      }

      if (pathname === ROUTES.LADDER_GAME) {
        dispatch(resetLadderGameStep());
        dispatch(resetGameCardsNumber());
      }

      closeLeaveGameModal();
    }
  };

  return (
    <>
      {isLoadingGame ? (
        <PlaygroundLoader />
      ) : (
        <Stack className={styles.playground}>
          <Stack direction="row" className={styles['cards-box']} ref={cardBox}>
            {cardsInGame.map(({ fullPath, url, guessed }, i) => (
              <Card
                key={`${fullPath}_${i}`}
                frontImageSrc={url}
                backPatternSrc={gameBackUrl}
                opened={guessed || openedCards.includes(i)}
                turnCard={makeTurnCard(i)}
                prevent={computerIsPlaying}
                correct={openedCards.includes(i) ? isCorrect : undefined}
                ref={(el) => {
                  cards.current[i] = { el, i };
                }}
                forwardStyle={{
                  width: `${cardStyle.width}px`,
                  height: `${cardStyle.height}px`,
                  borderRadius: `${Math.min(maxCardCornerRadius, cardStyle.width * cardCornerRadiusRatio)}px`,
                }}
              />
            ))}
          </Stack>
        </Stack>
      )}

      {!!cardsInGame.length && (
        <Button
          variant="contained"
          className={clsx(styles['action-button'], styles['stop-game-button'], { [styles.mobile]: isMobile })}
          onClick={pathname === ROUTES.SOLO_DEMO ? leaveGameHandler : openLeaveGameModal}
          aria-label={t('STOP_BUTTON') as string}
        >
          <Typography variant="button">{t('STOP_BUTTON')}</Typography>
        </Button>
      )}

      {isUpdatingGameData && <PlaygroundLoader />}

      <GameResultModal open={gameResultModalIsOpen} onClose={closeGameResultModal} onStartGame={startGameHandler} />
      {pathname !== ROUTES.SOLO_DEMO && (
        <LeaveGameModal open={leaveGameModalIsOpen} onClose={closeLeaveGameModal} onLeaveGame={leaveGameHandler} />
      )}
    </>
  );
};

export default Playground;
