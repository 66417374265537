import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth, innerHeight, outerWidth, outerHeight } = window;

  return {
    width: innerWidth < outerWidth ? innerWidth : outerWidth || innerWidth,
    height: innerHeight < outerHeight ? innerHeight : outerHeight || innerHeight,
  };
}

const getScreenDimensions = () => {
  const { width, height } = window.screen;
  return { width, height };
};

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
      document.documentElement.style.setProperty('--doc-height', `${window.innerHeight}px`);
    };
    const handleOrientationChange = () => setWindowDimensions(getScreenDimensions());

    window.addEventListener('resize', handleResize);
    window.screen.orientation.addEventListener('change', handleOrientationChange);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.screen.orientation.removeEventListener('change', handleOrientationChange);
    };
  }, []);

  return windowDimensions;
}
