import { useSelector, useDispatch } from 'react-redux';
import { getRandomCollection, sleep } from '../utils';
import { getGameFolder, setCardsInGame, getGameCardsNumber } from '../redux/slices';
import { useCards } from './index';
import { CARDS_LOADING_MIN_TIMER } from '../constants';

const useGame = () => {
  const dispatch = useDispatch();
  const gameFolder = useSelector(getGameFolder);
  const gameCardsNumber = useSelector(getGameCardsNumber);

  const { fetchFolder } = useCards();

  const startGame = async () => {
    const cardsInGame = await fetchFolder(gameFolder);
    return getRandomCollection(cardsInGame, gameCardsNumber / 2);
  };

  const startGameThrottled = async () => {
    const res = await Promise.all([
      startGame(),
      sleep(CARDS_LOADING_MIN_TIMER), // for consistent UX we display a loader at least 0.5s
    ]);
    dispatch(setCardsInGame(res[0]));
  };

  return {
    startGame: startGameThrottled,
  };
};

export default useGame;
