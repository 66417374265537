import { Method, ClockMethod, BlindMethod, MethodType, OpenMethod } from '../types';

export const SHOWING_CARD_DELAY = 200;
export const CARDS_LOADING_MIN_TIMER = 500;
export const CARDS_CLICK_CHECK_DELAY = 500;
export const CARDS_CLICK_SET_DELAY = 1000;
export const CARD_TIMER = 1000;
export const TURN_CARD_TIMEOUT = 1000;

export const methodMap: Record<MethodType, Array<Method>> = {
  blind: ['blind'],
  open: ['open'],
  byOne: ['a-z', 'z-a', 'snake', 'snake-reverse', 'clockwise', 'counterclockwise', 'random'],
  byLine: [
    'column',
    'column-reverse',
    'row',
    'row-reverse',
    'diagonal-bottom-right',
    'diagonal-top-left',
    'diagonal-bottom-left',
    'diagonal-top-right',
  ],
};

export const tournamentSteps: Array<MethodType> = ['open', 'byOne', 'byLine', 'blind'];
export const ladderGameSteps: Array<number> = Array.from({ length: 19 }, (v, i) => i + 1);

export const cardsOpeningMethods: Array<Method> = [
  'blind',
  'open',
  'a-z',
  'z-a',
  'snake',
  'snake-reverse',
  'clockwise',
  'counterclockwise',
  'random',
  'column',
  'column-reverse',
  'row',
  'row-reverse',
  'diagonal-bottom-right',
  'diagonal-top-left',
  'diagonal-bottom-left',
  'diagonal-top-right',
];

export const blindMethod: BlindMethod = 'blind';
export const openMethod: OpenMethod = 'open';
export const clockwiseMethod: ClockMethod = 'clockwise';

export const tournamentsColumns = ['ranking', 'overallResult', ...tournamentSteps, 'date', 'category'];
export const ladderGamesColumns = ['ranking', 'result', 'date', 'category'];
export const defaultGameFolderPath = 'card_fronts/animals';
