import React, { FC } from 'react';
import { DesktopSettings, MobileSettings } from './index';
import { useMainLayout } from '../Layout/LayoutWrapper';

interface SettingsProps {
  withOpeningMethod?: boolean;
  withCardsNumber?: boolean;
  withPlayers?: boolean;
  withComputerGrade?: boolean;
  cardBackElementsNumber?: number;
}
const Settings: FC<SettingsProps> = ({
  withOpeningMethod,
  withCardsNumber,
  withPlayers,
  withComputerGrade,
  cardBackElementsNumber,
}) => {
  const { isMobile } = useMainLayout();
  const Component = isMobile ? MobileSettings : DesktopSettings;
  return (
    <Component {...{ withOpeningMethod, withCardsNumber, withPlayers, withComputerGrade, cardBackElementsNumber }} />
  );
};

export default Settings;
