import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import {
  SoloIllustration,
  TournamentIllustration,
  MultiplayerIllustration,
  LadderGameIllustration,
  ComputerIllustration,
} from '../../assets/icons';
import { GameModeWidget } from '../../components';
import { useMainLayout } from '../Layout/LayoutWrapper';
import { useRoutes } from '../../hooks';

const GameModeWidgetSet: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'DASHBOARD' });
  const { t: commonT } = useTranslation('translation', { keyPrefix: 'COMMON' });
  const ROUTES = useRoutes();
  const { narrowBreakPoint, isMobile } = useMainLayout();

  const gamesModesData = [
    {
      title: commonT('GAME_TYPES.SOLO') as string,
      icon: <SoloIllustration />,
      background: 'var(--gradient-1-dark)',
      route: ROUTES.SOLO,
    },
    {
      title: commonT('GAME_TYPES.MULTIPLAYER') as string,
      icon: <MultiplayerIllustration />,
      background: 'var(--gradient-3-dark)',
      route: ROUTES.MULTIPLAYER,
    },
    {
      title: commonT('GAME_TYPES.TOURNAMENT') as string,
      icon: <TournamentIllustration />,
      background: 'var(--gradient-5-dark)',
      route: ROUTES.TOURNAMENT,
    },
    {
      title: commonT('GAME_TYPES.COMPUTER') as string,
      icon: <ComputerIllustration />,
      background: 'var(--gradient-2-dark)',
      route: ROUTES.COMPUTER,
    },
    {
      title: commonT('GAME_TYPES.LADDER_GAME') as string,
      icon: <LadderGameIllustration />,
      background: 'var(--gradient-4-dark)',
      route: ROUTES.LADDER_GAME,
    },
  ];

  const parts =
    [
      [gamesModesData.slice(0, 3), gamesModesData.slice(3)],
      [gamesModesData.slice(0, 2), gamesModesData.slice(2, 4), [gamesModesData[4]]],
      [gamesModesData.slice(0, 2), [gamesModesData[2]], [gamesModesData[3]], [gamesModesData[4]]],
      gamesModesData.map((v) => [v]),
    ][narrowBreakPoint] || gamesModesData.map((v) => [v]);

  return (
    <Stack direction="column" gap={4} width="stretch">
      <Typography variant={isMobile ? 'h3' : 'h2'}>{t('GAME_MODES_TITLE')}</Typography>
      <Stack direction="column" gap={4} width="stretch">
        {parts.map((part, i) => (
          <Stack direction="row" justifyContent="space-between" gap={4} key={i}>
            {part.map(({ title, ...rest }) => (
              <GameModeWidget key={title} {...{ title, ...rest }} />
            ))}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default GameModeWidgetSet;
