import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogContentText, DialogActions, Button, Typography, Stack } from '@mui/material';
import clsx from 'clsx';
import { useWindowDimensions } from '../../hooks';
import { checkNarrowBreakPoint } from '../../utils';
import styles from '../../assets/styles/modal.module.scss';

const NarrowScreenModal: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'NARROW_SCREEN_MODAL' });
  const [narrowScreenModalIsOpen, setNarrowScreenModalIsOpen] = useState<boolean>(false);
  const openNarrowScreenModal = () => setNarrowScreenModalIsOpen(true);
  const closeNarrowScreenModal = () => setNarrowScreenModalIsOpen(false);

  const { width } = useWindowDimensions();
  const narrowBreakPoint = checkNarrowBreakPoint(width);

  useEffect(() => {
    if (narrowBreakPoint >= 5) {
      openNarrowScreenModal();
    } else {
      closeNarrowScreenModal();
    }
  }, [narrowBreakPoint]);

  return (
    <Dialog open={narrowScreenModalIsOpen} transitionDuration={0} className={styles.modal}>
      <Stack className={styles.header} alignItems="center">
        <Typography variant="h3">{t('TITLE')}</Typography>
      </Stack>
      <DialogContent className={styles.body}>
        <DialogContentText textAlign="center">
          <Typography variant="body-1">{t('TEXT')}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={clsx(styles.footer, styles.centered)}>
        <Button
          variant="contained"
          className={styles['action-button']}
          aria-label={t('OK_BUTTON') as string}
          onClick={closeNarrowScreenModal}
        >
          <Typography variant="h4">{t('OK_BUTTON')}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NarrowScreenModal;
