import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Stepper, Step, StepLabel, Typography } from '@mui/material';
import { setOpeningMethod, getTournamentStep } from '../../redux/slices';
import { makeRandom } from '../../utils';
import { TopPanel } from '../../components';
import { methodMap, tournamentSteps } from '../../constants';
import styles from './TournamentStepper.module.scss';

const TournamentStepper: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'TOURNAMENT' });
  const dispatch = useDispatch();
  const tournamentStep = useSelector(getTournamentStep);

  useEffect(() => {
    if (tournamentStep !== -1 && tournamentStep < tournamentSteps.length) {
      const methodsByType = methodMap[tournamentSteps[tournamentStep]];
      dispatch(setOpeningMethod(methodsByType[makeRandom(methodsByType.length)]));
    }
  }, [tournamentStep]);

  return (
    <TopPanel>
      <Stepper activeStep={tournamentStep} alternativeLabel className={styles.stepper}>
        {tournamentSteps.map((step) => (
          <Step key={step}>
            <StepLabel>
              <Typography variant="body-2">{t(`STEPS.${step}`)}</Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </TopPanel>
  );
};

export default TournamentStepper;
