import React, { FC } from 'react';
import clsx from 'clsx';
import { Stack } from '@mui/material';
import styles from './Burger.module.scss';

interface BurgerProps {
  opened: boolean;
  handleClick: () => void;
}
const Burger: FC<BurgerProps> = ({ opened, handleClick }) => (
  <Stack
    direction="column"
    gap={1.5}
    justifyContent="space-between"
    className={clsx(styles.burger, { [styles['burger-opened']]: opened })}
    onClick={handleClick}
  >
    <div className={styles['burger-bar']} />
    <div className={styles['burger-bar']} />
    <div className={styles['burger-bar']} />
  </Stack>
);

export default Burger;
