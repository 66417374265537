export const setCookie = (name: string, value: string, days: number): void => {
  const expires = days ? new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString() : '';
  document.cookie = `${name}=${value || ''}; expires=${expires}; path=/`;
};

export const getCookie = (name: string): string | null => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
};
