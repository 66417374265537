import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Stack, Typography, List, ListItem, Link } from '@mui/material';
import clsx from 'clsx';
import { LEGAL_EMAIL } from '../../constants';
import { useMainLayout } from '../../components/Layout/LayoutWrapper';
import { MobileTopNotch } from '../../components/MobileTopNotch';
import styles from './PrivacyPolicy.module.scss';

const PrivacyPolicy: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'PRIVACY_POLICY' });
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const { isMobile } = useMainLayout();

  return (
    <>
      <MobileTopNotch title={t('TITLE')} />

      <Stack
        flexDirection="column"
        gap={8}
        alignItems="left"
        className={clsx(styles['page-wrap'], {
          [styles['page-wrap-mobile']]: isMobile,
          [styles['page-wrap-mobile-with-notch']]: isMobile,
        })}
      >
        {!isMobile && <Typography variant="h1">{t('TITLE')}</Typography>}

        <Typography variant="body-2">{t('LAST_UPDATED', { date: '11-22-2023' })}</Typography>

        <Typography variant="body-1">{t('INTRO')}</Typography>

        <Stack flexDirection="column" gap={6}>
          <Typography variant="h2">{t('INFO_COLLECT_SUB_TITLE')}</Typography>
          <List>
            <ListItem>
              <Typography variant="body-1">
                <Trans i18nKey="PRIVACY_POLICY.INFO_COLLECT_TEXT_1" components={[<b />]} />
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body-1">
                <Trans i18nKey="PRIVACY_POLICY.INFO_COLLECT_TEXT_2" components={[<b />]} />
              </Typography>
            </ListItem>
          </List>
        </Stack>

        <Stack flexDirection="column" gap={6}>
          <Typography variant="h2">{t('INFO_USE_SUB_TITLE')}</Typography>
          <Typography variant="body-1">{t('INFO_USE_TEXT')}</Typography>
        </Stack>

        <Stack flexDirection="column" gap={6}>
          <Typography variant="h2">{t('COOKIES_SUB_TITLE')}</Typography>
          <Typography variant="body-1">{t('COOKIES_TEXT')}</Typography>
        </Stack>

        <Stack flexDirection="column" gap={6}>
          <Typography variant="h2">{t('THIRD_PARTY_LINKS_SUB_TITLE')}</Typography>
          <Typography variant="body-1">{t('THIRD_PARTY_LINKS_TEXT')}</Typography>
        </Stack>

        <Stack flexDirection="column" gap={6}>
          <Typography variant="h2">{t('SECURITY_SUB_TITLE')}</Typography>
          <Typography variant="body-1">{t('SECURITY_TEXT')}</Typography>
        </Stack>

        <Stack flexDirection="column" gap={6}>
          <Typography variant="h2">{t('YOUR_CHOICES_SUB_TITLE')}</Typography>
          <Typography variant="body-1">{t('YOUR_CHOICES_TEXT')}</Typography>
        </Stack>

        <Stack flexDirection="column" gap={6}>
          <Typography variant="h2">{t('CHANGES_SUB_TITLE')}</Typography>
          <Typography variant="body-1">{t('CHANGES_TEXT')}</Typography>
        </Stack>

        <Stack flexDirection="column" gap={6}>
          <Typography variant="h2">{t('CONTACT_US_SUB_TITLE')}</Typography>
          <Typography variant="body-1">
            <Trans
              i18nKey="PRIVACY_POLICY.CONTACT_US_TEXT"
              components={[
                <Link
                  href={`mailto:${LEGAL_EMAIL}`}
                  target="send-email"
                  className={styles.link}
                  aria-label={ariaLabelT('LINK.SEND_EMAIL', { email: LEGAL_EMAIL }) as string}
                />,
              ]}
              values={{ email: LEGAL_EMAIL }}
            />
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default PrivacyPolicy;
