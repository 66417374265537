import React, { FC, useEffect, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Typography, Button, Stack } from '@mui/material';
import clsx from 'clsx';
import { AnimatedItems, HorizontalCarouselInfiniteAuto, LandingHeader } from '../../components';
import { useRoutes, useWindowDimensions } from '../../hooks';
import { checkNarrowBreakPoint } from '../../utils';
import {
  SoloScroll,
  ComputerScroll,
  MultiplayerScroll,
  LadderGameScroll,
  TournamentScroll,
  MemoryLabel,
  BgEl1,
  BgEl2,
  BgEl3,
  BgEl4,
  BgEl5,
  BgEl6,
  gameDemoMp4,
  videoPoster,
  Icon01,
  Icon02,
  ArrowConnectIcon,
  BgStar1,
  BgStar2,
} from '../../assets';
import styles from './Landing.module.scss';

const currentYear = new Date().getFullYear();

const LandingPage: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'LANDING' });
  const { t: commonT } = useTranslation('translation', { keyPrefix: 'COMMON' });
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });

  const ROUTES = useRoutes();

  const homeRef = useRef<HTMLElement | null>(null);
  const aboutRef = useRef<HTMLElement | null>(null);
  const howToPlayRef = useRef<HTMLElement | null>(null);

  const { width } = useWindowDimensions();
  const narrowBreakPoint = checkNarrowBreakPoint(width);
  const isMobile = narrowBreakPoint >= 3;

  useEffect(() => {
    if (isMobile) document.body.classList.add('mobile-height');
  }, [narrowBreakPoint]);

  return (
    <Stack className={clsx(styles['landing-page'], { [styles['landing-page-mobile']]: isMobile })}>
      <LandingHeader {...{ isMobile, homeRef, aboutRef, howToPlayRef, narrowBreakPoint }} />

      <Stack className={styles['landing-body']}>
        <Stack alignItems="center" className={styles['welcome-section']} ref={homeRef}>
          <Stack flexDirection="column" gap={12} className={styles['welcome-section-content']}>
            <Stack flexDirection="column" gap={4} className={styles['welcome-section-internal']}>
              <Typography variant={isMobile ? 'h1' : 'landing-h1'}>
                <Trans i18nKey="LANDING.WELCOME_TITLE" components={[<br />]} />
              </Typography>
              <Typography variant="landing-body-1" className={styles['welcome-section-text']}>
                {t('WELCOME_TEXT')}
              </Typography>
            </Stack>
            <Button
              component={RouterLink}
              to={ROUTES.SOLO_DEMO}
              variant="contained"
              className={clsx(styles['action-button'], styles['play-now-button'])}
              aria-label={ariaLabelT('LINK.SOLO_DEMO') as string}
            >
              <Typography variant="button">{t('PLAY_NOW')}</Typography>
            </Button>
          </Stack>

          <BgEl1 className={clsx(styles['bg-el'], styles['bg-el-1'])} />
          <BgEl2 className={clsx(styles['bg-el'], styles['bg-el-2'])} />
          <BgEl3 className={clsx(styles['bg-el'], styles['bg-el-3'])} />
          <BgEl4 className={clsx(styles['bg-el'], styles['bg-el-4'])} />
        </Stack>

        <div className={styles.carousel}>
          <HorizontalCarouselInfiniteAuto
            elements={[
              { SvgComponent: SoloScroll, title: commonT('GAME_TYPES.SOLO') as string },
              { SvgComponent: ComputerScroll, title: commonT('GAME_TYPES.COMPUTER') as string },
              { SvgComponent: MultiplayerScroll, title: commonT('GAME_TYPES.MULTIPLAYER') as string },
              { SvgComponent: LadderGameScroll, title: commonT('GAME_TYPES.LADDER_GAME') as string },
              { SvgComponent: TournamentScroll, title: commonT('GAME_TYPES.TOURNAMENT') as string },
            ]}
          />
        </div>

        <Stack className={styles['skills-section']} ref={aboutRef}>
          <Stack
            flexDirection={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'center' : 'unset'}
            gap={narrowBreakPoint > 1 ? 16 : 27}
            className={styles['skills-section-content']}
          >
            <Typography variant={isMobile ? 'h1' : 'landing-h2'}>
              <Trans i18nKey="LANDING.ENHANCE_TITLE" components={[<MemoryLabel />]} />
            </Typography>
            <Typography variant="body-1">{t('ENHANCE_TEXT')}</Typography>
          </Stack>

          <BgEl5 className={clsx(styles['bg-el'], styles['bg-el-5'])} />
          <BgEl6 className={clsx(styles['bg-el'], styles['bg-el-6'])} />
        </Stack>

        <Stack className={styles['features-section']}>
          <Stack flexDirection="column" gap={isMobile ? 6 : 2}>
            <Typography variant={isMobile ? 'h1' : 'landing-h2'}>{t('FEATURES_TITLE')}</Typography>
            <AnimatedItems isMobile={isMobile} />
          </Stack>
        </Stack>

        <Stack className={styles['how-to-play-section']} ref={howToPlayRef}>
          <Stack
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems="center"
            gap={isMobile ? 8 : 6}
            className={styles['how-to-play-section-internal']}
          >
            <Stack flexDirection="column" gap={isMobile ? 7 : 12} className={styles['how-to-play-left-section']}>
              <Typography variant={isMobile ? 'h1' : 'landing-h2'}>{t('HOW_TO_PLAY_TITLE')}</Typography>
              <Stack flexDirection="row" gap={6}>
                <Stack flexDirection="column">
                  <Icon01 className={styles['step-icon']} />
                  <ArrowConnectIcon className={styles['arrow-icon']} />
                </Stack>

                <Stack flexDirection="column" gap={isMobile ? 2 : 3}>
                  <Typography variant="h3">{t('HOW_TO_PLAY_STEP-01-TITLE')}</Typography>
                  <Typography variant="body-1">{t('HOW_TO_PLAY_STEP-01-TEXT')}</Typography>
                </Stack>
              </Stack>

              <Stack flexDirection="row" gap={6}>
                <Icon02 className={styles['step-icon']} />
                <Stack flexDirection="column" gap={isMobile ? 2 : 3}>
                  <Typography variant="h3">{t('HOW_TO_PLAY_STEP-02-TITLE')}</Typography>
                  <Typography variant="body-1">{t('HOW_TO_PLAY_STEP-02-TEXT')}</Typography>
                </Stack>
              </Stack>
            </Stack>

            <Stack flexDirection="column" className={styles['game-demo-mp4-wrap']}>
              <video autoPlay muted playsInline loop className={styles['game-demo-mp4']} poster={videoPoster}>
                <source src={gameDemoMp4} type="video/mp4" />
                <track src="" kind="captions" srcLang="en" label="game-demo" />
              </video>
            </Stack>
          </Stack>
        </Stack>

        <Stack className={styles['action-section']}>
          <Stack flexDirection={isMobile ? 'column' : 'row'} gap={isMobile ? 10 : 6}>
            <Stack className={styles.action}>
              <Stack flexDirection="column" gap={6} className={styles['action-content']}>
                <Typography variant={isMobile ? 'h1' : 'landing-h2'}>{t('ACTION_1_TITLE')}</Typography>
                <Typography variant="body-1">{t('ACTION_1_TEXT')}</Typography>
                <Button
                  component={RouterLink}
                  to={ROUTES.SOLO_DEMO}
                  variant="contained"
                  className={clsx(styles['action-button'], styles.tiny, styles.inverse)}
                  aria-label={ariaLabelT('LINK.SOLO_DEMO') as string}
                >
                  {t('ACTION_1_BTN')}
                </Button>
              </Stack>

              <Stack className={styles['bg-wrap']}>
                <BgStar1 className={clsx(styles['bg-el'], styles['bg-el-7'])} />
              </Stack>
            </Stack>

            <Stack className={clsx(styles.action, styles['action-inverse'])}>
              <Stack flexDirection="column" gap={6} className={styles['action-content']}>
                <Typography variant={isMobile ? 'h1' : 'landing-h2'}>{t('ACTION_2_TITLE')}</Typography>
                <Typography variant="body-1">{t('ACTION_2_TEXT')}</Typography>
                <Button
                  component={RouterLink}
                  to={ROUTES.AUTH_SIGN_UP}
                  variant="contained"
                  className={clsx(styles['action-button'], styles.tiny)}
                  aria-label={ariaLabelT('LINK.SIGN_UP') as string}
                >
                  {t('ACTION_2_BTN')}
                </Button>
              </Stack>

              <Stack className={styles['bg-wrap']}>
                <BgStar2 className={clsx(styles['bg-el'], styles['bg-el-8'])} />
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <footer>
          <Stack flexDirection={isMobile ? 'column' : 'row'} gap={4}>
            <Typography variant="body-2" color="var(--text-secondary)">
              {t('FOOTER_TEXT', { currentYear })}
            </Typography>

            <Stack flexDirection="row" gap={4}>
              <Typography variant="body-2">
                <Trans
                  i18nKey="LANDING.INFO_LINK"
                  components={[
                    <RouterLink
                      to={ROUTES.INFO}
                      className={styles.link}
                      aria-label={ariaLabelT('LINK.INFO') as string}
                    />,
                  ]}
                />
              </Typography>
              <Typography variant="body-2">
                <Trans
                  i18nKey="LANDING.PRIVACY_POLICY_LINK"
                  components={[
                    <RouterLink
                      to={ROUTES.PRIVACY_POLICY}
                      className={styles.link}
                      aria-label={ariaLabelT('LINK.PRIVACY_POLICY') as string}
                    />,
                  ]}
                />
              </Typography>
              <Typography variant="body-2">
                <Trans
                  i18nKey="LANDING.TERMS_OF_SERVICE_LINK"
                  components={[
                    <RouterLink
                      to={ROUTES.TERMS_OF_SERVICE}
                      className={styles.link}
                      aria-label={ariaLabelT('LINK.TERMS_OF_SERVICE') as string}
                    />,
                  ]}
                />
              </Typography>
            </Stack>
          </Stack>
        </footer>
      </Stack>
    </Stack>
  );
};

export default LandingPage;
