import React, { FC, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Stack, Button } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { ADMIN_EMAIL, cookieConsentName, cookieConsentValue } from '../../constants';
import { getCookie, setCookie } from '../../utils/cookie';
import { useRoutes, useWindowDimensions } from '../../hooks';
import { checkNarrowBreakPoint } from '../../utils';
import styles from './CookieBanner.module.scss';

const CookieBannerText: FC = () => {
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const ROUTES = useRoutes();

  return (
    <Typography variant="body-2">
      <Trans
        i18nKey="COOKIE_BANNER.TEXT"
        components={[
          <RouterLink
            to={ROUTES.PRIVACY_POLICY}
            className={styles.link}
            aria-label={ariaLabelT('LINK.PRIVACY_POLICY') as string}
          />,
          <RouterLink
            to={ROUTES.TERMS_OF_SERVICE}
            className={styles.link}
            aria-label={ariaLabelT('LINK.TERMS_OF_SERVICE') as string}
          />,
        ]}
        values={{ email: ADMIN_EMAIL }}
      />
    </Typography>
  );
};

interface CookieBannerMainButtonProps {
  acceptAll: () => void;
}
const CookieBannerMainButton: FC<CookieBannerMainButtonProps> = ({ acceptAll }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'COOKIE_BANNER' });

  return (
    <Button
      variant="contained"
      className={clsx(styles['action-button'], styles['accept-button'])}
      onClick={acceptAll}
      aria-label={t('BUTTON_TEXT') as string}
    >
      <Typography variant="button">{t('BUTTON_TEXT')}</Typography>
    </Button>
  );
};

interface CookieBannerCloseButtonProps {
  acceptAll: () => void;
}
const CookieBannerCloseButton: FC<CookieBannerCloseButtonProps> = ({ acceptAll }) => {
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });

  return (
    <CloseIcon
      className={styles['close-icon']}
      onClick={acceptAll}
      aria-label={ariaLabelT('BUTTON.HIDE_COOKIE_BANNER') as string}
    />
  );
};

const CookieBanner: FC = () => {
  const { width } = useWindowDimensions();
  const narrowBreakPoint = checkNarrowBreakPoint(width);
  const isMobile = narrowBreakPoint >= 3;

  const [cookieConsentAccepted, setCookieConsentAccepted] = useState(
    getCookie(cookieConsentName) === cookieConsentValue
  );

  const acceptAll = () => {
    setCookie(cookieConsentName, cookieConsentValue, 365);
    setCookieConsentAccepted(true);
  };

  if (cookieConsentAccepted) {
    return null;
  }

  if (isMobile) {
    return (
      <Stack direction="column" gap={5} alignItems="center" className={clsx(styles.wrap, styles['wrap-mobile'])}>
        <Stack direction="row" alignItems="start" gap={2}>
          <CookieBannerText />
          <CookieBannerCloseButton acceptAll={acceptAll} />
        </Stack>
        <CookieBannerMainButton acceptAll={acceptAll} />
      </Stack>
    );
  }

  return (
    <Stack direction="row" alignItems="center" gap={5} justifyContent="space-between" className={styles.wrap}>
      <CookieBannerText />
      <Stack direction="row" gap={5} alignItems="center">
        <CookieBannerMainButton acceptAll={acceptAll} />
        <CookieBannerCloseButton acceptAll={acceptAll} />
      </Stack>
    </Stack>
  );
};

export default CookieBanner;
