import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack, Button, Typography } from '@mui/material';
import { Icon404 } from '../../assets';
import styles from './Page404.module.scss';
import { useRoutes } from '../../hooks';

const Page404: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'PAGE_404' });
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const ROUTES = useRoutes();

  return (
    <Stack flexDirection="column" gap={26} alignItems="center" justifyContent="center" className={styles.wrapper}>
      <Icon404 />

      <Stack flexDirection="column" gap={8} alignItems="center">
        <Stack flexDirection="column" gap={6}>
          <Typography variant="h1" align="center">
            {t('PAGE_NOT_FOUND')}
          </Typography>
          <Typography variant="body-1" align="center" gutterBottom>
            {t('NO_SUCH_PAGE')}
          </Typography>
        </Stack>

        <Button
          component={RouterLink}
          to={ROUTES.ROOT}
          variant="contained"
          className={styles['action-button']}
          aria-label={ariaLabelT('LINK.BACK_TO_MEMO') as string}
        >
          <Typography variant="button">{t('BACK_TO_MEMO')}</Typography>
        </Button>
      </Stack>
    </Stack>
  );
};

export default Page404;
