import { cardRatio, cardGap, cardMaximisationStep } from '../constants';
import { CardInGame } from '../redux/types/game';

export const fitCardsFromWidth = (
  availableHeight: number,
  availableWidth: number,
  cardsNumber: number,
  isMobile: boolean
) => {
  const availableSurface = availableHeight * availableWidth;
  const { vertical: verticalCardGap, horizontal: horizontalCardGap } = cardGap[isMobile ? 'mobile' : 'desktop'];

  // preliminary calculation
  let availableSurfacePerCard = availableSurface / cardsNumber;
  const cardWidthWithGap = Math.sqrt(availableSurfacePerCard * cardRatio);
  let cardsInRow = Math.floor(availableWidth / cardWidthWithGap);
  let rowsNumber = Math.ceil(cardsNumber / cardsInRow);

  // correction
  const duplicatedSquaresSurface = verticalCardGap * horizontalCardGap * rowsNumber * cardsInRow;
  const totalGapSurface =
    horizontalCardGap * cardsInRow * availableHeight +
    verticalCardGap * rowsNumber * availableWidth -
    duplicatedSquaresSurface;
  availableSurfacePerCard = (availableSurface - totalGapSurface) / cardsNumber;
  const cardWidth = Math.sqrt(availableSurfacePerCard * cardRatio);
  cardsInRow = Math.floor(availableWidth / (cardWidth + horizontalCardGap));
  rowsNumber = Math.ceil(cardsNumber / cardsInRow);

  const cardHeightWithGap = availableHeight / rowsNumber;
  const height = cardHeightWithGap - verticalCardGap;
  const width = height * cardRatio;

  return { style: { width, height }, surface: width * height };
};

export const fitCardsFromHeight = (
  availableHeight: number,
  availableWidth: number,
  cardsNumber: number,
  isMobile: boolean
) => {
  const availableSurface = availableHeight * availableWidth;
  const { vertical: verticalCardGap, horizontal: horizontalCardGap } = cardGap[isMobile ? 'mobile' : 'desktop'];

  // preliminary calculation
  let availableSurfacePerCard = availableSurface / cardsNumber;
  const cardHeightWithGap = Math.sqrt(availableSurfacePerCard / cardRatio);
  let rowsNumber = Math.floor(availableHeight / cardHeightWithGap);
  let cardsInRow = Math.ceil(cardsNumber / rowsNumber);

  // correction
  const duplicatedSquaresSurface = verticalCardGap * horizontalCardGap * rowsNumber * cardsInRow;
  const totalGapSurface =
    horizontalCardGap * cardsInRow * availableHeight +
    verticalCardGap * rowsNumber * availableWidth -
    duplicatedSquaresSurface;
  availableSurfacePerCard = (availableSurface - totalGapSurface) / cardsNumber;
  const cardHeight = Math.sqrt(availableSurfacePerCard / cardRatio);
  rowsNumber = Math.floor(availableHeight / (cardHeight + verticalCardGap));
  cardsInRow = Math.ceil(cardsNumber / rowsNumber);

  const cardWidthWithGap = availableWidth / cardsInRow;
  const width = cardWidthWithGap - horizontalCardGap;
  const height = width / cardRatio;

  return { style: { width, height }, surface: width * height };
};

interface CardDimensions {
  width: number;
  height: number;
}

interface MaximiseCardDimensionsProps {
  cardDimensions: CardDimensions;
  availableHeight: number;
  availableWidth: number;
  cardsNumber: number;
  isMobile: boolean;
}

type RecursiveMaximiseCardDimensions = (props: MaximiseCardDimensionsProps) => CardDimensions;
// type RecursiveMaximiseCardDimensions = (props: MaximiseCardDimensionsProps) => RecursiveMaximiseCardDimensions | CardDimensions;

export const maximiseCardDimensions: RecursiveMaximiseCardDimensions = ({
  cardDimensions,
  availableHeight,
  availableWidth,
  cardsNumber,
  isMobile,
}) => {
  const availableSurface = availableHeight * availableWidth;
  const { vertical: verticalCardGap, horizontal: horizontalCardGap } = cardGap[isMobile ? 'mobile' : 'desktop'];

  const updatedWidth = cardDimensions.width + cardMaximisationStep;
  const updatedHeight = updatedWidth / cardRatio;
  const cardsInRow = Math.floor(availableWidth / (updatedWidth + horizontalCardGap));
  const rowsNumber = Math.ceil(cardsNumber / cardsInRow);

  if ((updatedHeight + verticalCardGap) * rowsNumber * availableWidth < availableSurface) {
    return maximiseCardDimensions({
      cardDimensions: { height: updatedHeight, width: updatedWidth },
      availableHeight,
      availableWidth,
      cardsNumber,
      isMobile,
    });
  }

  return cardDimensions;
};

// --------------------------------------------- computer logic --------------------------------------------------------
interface CardInGameWithPosition extends CardInGame {
  position: number;
}

export const getNotGuessedCards = (cardsInGame: Array<CardInGame>): Array<CardInGameWithPosition> =>
  cardsInGame.reduce((acc: Array<CardInGameWithPosition>, { guessed, ...rest }, index) => {
    if (!guessed) {
      acc.push({ ...rest, position: index });
    }
    return acc;
  }, []);

export const getFirstCorrectIndex = ({
  notGuessedCards,
  cumulativeOpenedCards,
}: {
  notGuessedCards: Array<CardInGameWithPosition>;
  cumulativeOpenedCards: Array<number>;
}) =>
  cumulativeOpenedCards.reduce(
    (acc: { fullPaths: string[]; firstCorrectIndex: number }, i) => {
      const notGuessedCardsIndex = notGuessedCards.findIndex(({ position }) => position === i);
      const { fullPath } = notGuessedCards[notGuessedCardsIndex] || {};

      if (fullPath) {
        if (acc.firstCorrectIndex === -1 && acc.fullPaths.includes(fullPath)) {
          acc.firstCorrectIndex = notGuessedCardsIndex;
        }

        acc.fullPaths.push(fullPath);
      }
      return acc;
    },
    { fullPaths: [], firstCorrectIndex: -1 }
  ).firstCorrectIndex;

export const getFirstRemainingCopy = ({
  notGuessedCards,
  cumulativeOpenedCards,
}: {
  notGuessedCards: Array<CardInGameWithPosition>;
  cumulativeOpenedCards: Array<number>;
}) =>
  notGuessedCards.reduce((acc: Array<CardInGameWithPosition>, v) => {
    if (!cumulativeOpenedCards.includes(v.position)) {
      acc.push(v);
    }
    return acc;
  }, []);

export const getReplyIndexInNotGuessed = ({
  notGuessedCards,
  cumulativeOpenedCards,
  firstOpenedCardFullPath,
  firstOpenedCardPosition,
}: {
  notGuessedCards: Array<CardInGameWithPosition>;
  cumulativeOpenedCards: Array<number>;
  firstOpenedCardFullPath: string;
  firstOpenedCardPosition: number;
}) =>
  notGuessedCards.findIndex(
    (card) =>
      card.fullPath === firstOpenedCardFullPath &&
      cumulativeOpenedCards.includes(card.position) &&
      card.position !== firstOpenedCardPosition
  );

export const getSecondRemainingCopy = ({
  notGuessedCards,
  cumulativeOpenedCards,
  replyIndexInNotGuessed,
}: {
  notGuessedCards: Array<CardInGameWithPosition>;
  cumulativeOpenedCards: Array<number>;
  replyIndexInNotGuessed: number;
}) =>
  notGuessedCards.reduce((acc: Array<CardInGameWithPosition>, v) => {
    if (
      (!cumulativeOpenedCards.includes(v.position) || replyIndexInNotGuessed !== -1) &&
      (notGuessedCards.length <= 1 || v.position !== replyIndexInNotGuessed)
    ) {
      acc.push(v);
    }
    return acc;
  }, []);
