import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserDataState } from '../types/userData';

const initialState: UserDataState = {
  uid: '',
  name: '',
};

export const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserDataState>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { setUserData } = userDataSlice.actions;

export const getUserData = ({ userData }: { userData: UserDataState }) => userData;
export const getUserName = ({ userData }: { userData: UserDataState }) => getUserData({ userData }).name;
export const getUid = ({ userData }: { userData: UserDataState }) => getUserData({ userData }).uid;

export const { reducer: userDataReducer } = userDataSlice;
