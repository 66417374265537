import React, { FC } from 'react';
import { BottomBar, SideBar } from '../../components';
import { useMainLayout } from '../Layout/LayoutWrapper';
import { useUserData } from '../../hooks';

const NavigationBar: FC = () => {
  const { isMobile } = useMainLayout();
  const { loading: userDataLoading, userName } = useUserData();

  return isMobile ? <BottomBar /> : <SideBar {...{ userDataLoading, userName }} />;
};

export default NavigationBar;
