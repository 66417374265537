import React, { ChangeEvent, FC, MouseEvent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, Button, TextField, InputAdornment, IconButton, Tooltip } from '@mui/material';
import { RemoveCircleOutlineRounded } from '@mui/icons-material';
import {
  getPlayersInGame,
  generatePlayerInGame,
  addPlayerInGame,
  getUserName,
  updatePlayer,
  initPlayers,
  removePlayerFromGame,
  resetPlayersInGame,
} from '../../redux/slices';
import { computer, mainPlayer, MAX_PLAYER_NUMBER, MIN_PLAYER_NUMBER, players } from '../../constants';
import { HelpOutlinedRounded, PlusIcon } from '../../assets';
import { useRoutes } from '../../hooks';
import styles from './DesktopSettings.module.scss';

interface PlayersSettingProps {
  isMobile?: boolean;
}
const PlayersSetting: FC<PlayersSettingProps> = ({ isMobile }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'SETTINGS' });
  const { t: tPlayersMap } = useTranslation('translation', { keyPrefix: 'PLAYERS_MAP' });
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const ROUTES = useRoutes();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const vsComputer = ROUTES.COMPUTER === pathname;
  const playersInGame = useSelector(getPlayersInGame);
  const mainPlayerName = useSelector(getUserName);

  const generatePlayer = () => dispatch(generatePlayerInGame());
  const addComputer = () =>
    dispatch(addPlayerInGame({ emoji: computer.emoji, name: tPlayersMap(`${computer.emoji}`) }));
  const addMainPlayer = () =>
    dispatch(addPlayerInGame({ emoji: mainPlayer.emoji, name: tPlayersMap(`${mainPlayer.emoji}`) }));

  useEffect(() => {
    dispatch(resetPlayersInGame());
    dispatch(initPlayers(players.map(({ emoji }) => ({ emoji, name: tPlayersMap(`${emoji}`) }))));
    (vsComputer ? addMainPlayer : generatePlayer)();
    (vsComputer ? addComputer : generatePlayer)();
  }, []);

  useEffect(() => {
    if (vsComputer && mainPlayerName) {
      dispatch(updatePlayer({ ...mainPlayer, name: mainPlayerName }));
    }
  }, [mainPlayerName]);

  const handleNameChange = (emoji: number) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(updatePlayer({ emoji, name: e.target.value }));
  };
  const handleRemovePlayer = (emoji: number) => () => dispatch(removePlayerFromGame(emoji));
  const preventMouseDown = (event: MouseEvent<HTMLButtonElement>) => event.preventDefault();

  return (
    <Stack direction="column" gap={isMobile ? 4 : 2} alignItems="baseline" className={styles['player-settings']}>
      <Stack
        direction={isMobile ? 'column' : 'row'}
        alignItems={isMobile ? 'start' : 'center'}
        gap={isMobile ? 1 : 2}
        className={styles['setting-head']}
      >
        <Typography variant="h5" color="var(--text-secondary)">
          {t('PLAYERS_LABEL')}
        </Typography>

        {isMobile ? (
          <Typography variant="body2" color="var(--text-secondary)">
            {t(vsComputer ? 'PLAYERS_COMPUTER_HINT' : 'PLAYERS_MULTIPLAYER_HINT')}
          </Typography>
        ) : (
          <Tooltip title={t(vsComputer ? 'PLAYERS_COMPUTER_HINT' : 'PLAYERS_MULTIPLAYER_HINT')} placement="top" arrow>
            <HelpOutlinedRounded className={styles.hint} />
          </Tooltip>
        )}
      </Stack>

      <Stack direction="row" gap={4} className={styles['player-inputs-wrap']}>
        {playersInGame.map(({ name, emoji }) => (
          <TextField
            key={emoji}
            value={name}
            onChange={handleNameChange(emoji)}
            className={styles['player-input']}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography variant="h2">{String.fromCodePoint(emoji)}</Typography>
                </InputAdornment>
              ),
              endAdornment: playersInGame.length > MIN_PLAYER_NUMBER && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleRemovePlayer(emoji)}
                    onMouseDown={preventMouseDown}
                    edge="end"
                    className={styles['remove-player-button']}
                    aria-label={ariaLabelT('BUTTON.REMOVE_PLAYER') as string}
                  >
                    <RemoveCircleOutlineRounded />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}
      </Stack>

      {!vsComputer && playersInGame.length < MAX_PLAYER_NUMBER && (
        <Button
          className={styles['add-player-button']}
          onClick={generatePlayer}
          startIcon={<PlusIcon />}
          aria-label={t('ADD_PLAYER') as string}
        >
          <Typography variant="body-2">{t('ADD_PLAYER')}</Typography>
        </Button>
      )}
    </Stack>
  );
};

export default PlayersSetting;
