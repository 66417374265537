import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setGameFolder, setOpeningMethod, setGameBackUrl } from '../redux/slices';
import { blindMethod, defaultGameFolderPath } from '../constants';
import { useAuth, useCards } from './index';

const useInit = (fetchData: boolean) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { fetchFolder, fetchFolders } = useCards();
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!fetchData) {
      setIsInitialLoading(false);
      return;
    }

    Promise.all([fetchFolder('card_backs'), fetchFolder('card_opening_methods'), fetchFolders('card_fronts')]).then(
      ([backs, openingMethods, foldersList]) => {
        if (backs.length) {
          dispatch(setGameBackUrl(backs[0].url));
        }
        if (openingMethods.length) {
          dispatch(setOpeningMethod(blindMethod));
        }
        if (foldersList.length) {
          const animalsFolder = foldersList.find(({ fullPath }) => fullPath === defaultGameFolderPath);
          dispatch(setGameFolder((animalsFolder || foldersList[0]).fullPath));
        }
        setIsInitialLoading(false);
      }
    );
  }, [user]);

  return {
    isInitialLoading,
  };
};

export default useInit;
