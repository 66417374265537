import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import { getPlayersInGame, getPlayerOnTurn } from '../../redux/slices';
import { Player, TopPanel } from '../index';
import { PlayersArrow } from '../../assets';
import styles from './PlayersPanel.module.scss';

interface PlayersPanelProps {
  vsComputer?: boolean;
}
const PlayersPanel: FC<PlayersPanelProps> = ({ vsComputer }) => {
  const activePlayerRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<HTMLDivElement>(null);
  const pointerRef = useRef<SVGSVGElement>(null);
  const playersInGame = useSelector(getPlayersInGame);
  const playerOnTurn = useSelector(getPlayerOnTurn);
  const [pointerStyle, setPointerStyle] = useState({});

  useEffect(() => {
    if (activePlayerRef.current && pointerRef.current) {
      const { left, top, height } = activePlayerRef.current.getBoundingClientRect();
      const { width: pointerWidth, height: pointerHeight } = pointerRef.current.getBoundingClientRect();
      setPointerStyle({ left: `${left - pointerWidth}px`, top: `${top + height / 2 - pointerHeight / 2}px` });
    }
    if (!activePlayerRef.current && pointerRef.current) {
      setPointerStyle({ left: '0px' });
    }
  }, [playerOnTurn]);

  return (
    <>
      <TopPanel>
        <Stack direction="row" alignItems="center" justifyContent="center" flexWrap="wrap" gap={8}>
          {playersInGame.map(({ name, emoji, score }) => (
            <Player
              {...{ name, emoji, score }}
              key={emoji}
              playerRef={playerOnTurn === emoji ? activePlayerRef : playerRef}
              active={playerOnTurn === emoji}
            />
          ))}
        </Stack>
      </TopPanel>
      <PlayersArrow className={styles.pointer} style={pointerStyle} ref={pointerRef} />
    </>
  );
};

export default PlayersPanel;
