import React, { FC, cloneElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Stack } from '@mui/material';
import { useMainLayout } from '../Layout/LayoutWrapper';
import styles from './GameModeWidget.module.scss';

interface GameModeWidgetProps {
  title: string;
  icon: JSX.Element;
  background: string;
  route: string;
  offsetIcon?: boolean;
}
const GameModeWidget: FC<GameModeWidgetProps> = ({ title, icon, background, route, offsetIcon }) => {
  const { t: navigationBarT } = useTranslation('translation', { keyPrefix: 'NAVIGATION_BAR' });
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const pageName = navigationBarT(`ROUTE_TO_TITLE_MAP.${route.substring(3)}`);
  const { isMobile } = useMainLayout();
  const enhancedIcon = cloneElement(icon, { ...icon.props, style: { background } });

  if (isMobile) {
    return (
      <Stack
        direction="row"
        className={styles['mobile-widget']}
        gap={4}
        alignItems="center"
        component={RouterLink}
        to={route}
        aria-label={ariaLabelT('LINK.GENERIC', { pageName }) as string}
      >
        {enhancedIcon}
        <Typography variant="h4" className={styles.title}>
          {title}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack
      direction="row"
      justifyContent="end"
      className={clsx(styles.widget, { [styles.offset]: offsetIcon })}
      style={{ background }}
      component={RouterLink}
      to={route}
      aria-label={ariaLabelT('LINK.GENERIC', { pageName }) as string}
    >
      <Typography variant="h1" className={styles.title}>
        {title}
      </Typography>
      {icon}
    </Stack>
  );
};

export default GameModeWidget;
