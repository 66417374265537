import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField, Typography, Stack, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { emailRegexp } from '../../constants';
import useAuth from '../../hooks/useAuth';
import { AuthEmail } from '../../types';
import { useRoutes } from '../../hooks';
import styles from './Auth.module.scss';

const Reset: FC = () => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'AUTH' });
  const ROUTES = useRoutes();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AuthEmail>();

  const navigate = useNavigate();
  const { user, sendPasswordReset } = useAuth();

  const [isSending, setIsSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (user) navigate(ROUTES.DASHBOARD);
  }, [user]);

  const resetHandler: SubmitHandler<AuthEmail> = async (formData) => {
    const { email } = formData;

    setIsSending(true);
    try {
      await sendPasswordReset(email);
      reset(formData);
      navigate(ROUTES.AUTH_RECOVER);
    } catch (errCode) {
      const errorLocalUser = `ERROR_MAP.USER.${errCode}`;
      setErrorMessage((i18n.exists(`AUTH.${errorLocalUser}`) ? t(errorLocalUser) : t('UNEXPECTED_ERROR')) as string);
    } finally {
      setIsSending(false);
    }
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => setErrorMessage('');

  return (
    <Stack direction="column" gap={10}>
      <Typography variant="h1">{t('RESET_TITLE')}</Typography>

      <Stack direction="column" gap={6}>
        <TextField
          className={styles['input-box']}
          onInput={handleInput}
          {...register('email', {
            required: { value: true, message: t('EMAIL.ERROR.REQUIRED') },
            pattern: { value: emailRegexp, message: t('EMAIL.ERROR.PATTERN') },
          })}
          label={t('EMAIL.PLACEHOLDER')}
          helperText={errors.email?.message || errorMessage}
          error={!!errors.email?.type || !!errorMessage}
          fullWidth
          autoFocus
        />

        <LoadingButton
          size="small"
          variant="contained"
          onClick={handleSubmit(resetHandler)}
          className={styles['action-button']}
          loading={isSending}
        >
          <Typography variant="button">{t('RESET_PASSWORD_BUTTON')}</Typography>
        </LoadingButton>

        <Button
          component={RouterLink}
          to={ROUTES.AUTH_SIGN_IN}
          variant="text"
          className={styles['text-button']}
          aria-label={t('BACK_TO_LOGIN') as string}
        >
          <Typography variant="h4">{t('BACK_TO_LOGIN')}</Typography>
        </Button>
      </Stack>
    </Stack>
  );
};

export default Reset;
