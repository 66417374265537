import React, { FC } from 'react';
import { Link as RouterLink, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { CircularProgress, Stack, Grid, Paper, Typography } from '@mui/material';
import { AuthIllustration, BigStar, LogoWide, SmallStar } from '../../assets';
import { useAuth, useRoutes, useWindowDimensions } from '../../hooks';
import { checkNarrowBreakPoint } from '../../utils';
import styles from './Auth.module.scss';

const AuthLayout: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'AUTH' });
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const ROUTES = useRoutes();
  const { loading } = useAuth();
  const { width } = useWindowDimensions();
  const narrowBreakPoint = checkNarrowBreakPoint(width);
  const isMobile = narrowBreakPoint >= 3;

  return (
    <Grid container spacing={0} className={styles.container}>
      <Grid item xs={narrowBreakPoint ? 12 : 6}>
        <Stack justifyContent="center" alignItems="center" height="100%">
          {loading ? (
            <CircularProgress color="primary" />
          ) : (
            <Stack gap={10} className={clsx(styles.wrap, { [styles['wrap-mobile']]: isMobile })}>
              <RouterLink to={ROUTES.ROOT} className={styles.link} aria-label={ariaLabelT('LINK.LANDING') as string}>
                <LogoWide className={styles.logo} />
              </RouterLink>
              <Outlet />
            </Stack>
          )}
        </Stack>
      </Grid>
      {!narrowBreakPoint && (
        <Grid item xs={6}>
          <Paper className={styles['illustration-section']}>
            <SmallStar className={styles['small-star']} />
            <BigStar className={styles['big-star']} />
            <AuthIllustration className={styles.illustration} />
            <Stack className={styles['illustration-text-wrap']}>
              <Stack direction="column" gap={4} className={styles['illustration-text']}>
                <Typography variant="h1">{t('ILLUSTRATION_TITLE')}</Typography>
                <Typography variant="h4">{t('ILLUSTRATION_TEXT')}</Typography>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default AuthLayout;
