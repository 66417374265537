import { useSelector, useDispatch } from 'react-redux';
import { getDownloadURL, ref, listAll /* updateMetadata */ } from 'firebase/storage';
import { storage } from '../firebase';
import { getCards, addFolder, setFolders } from '../redux/slices';
import { FoldersState } from '../redux/types/folders';
import { CardInGame } from '../redux/types/game';

const useCards = () => {
  const dispatch = useDispatch();
  const fetchedCards = useSelector(getCards);

  const fetchFolders = async (motherFolderName: string): Promise<FoldersState> => {
    const motherFolderReference = ref(storage, motherFolderName);
    const listResult = await listAll(motherFolderReference);
    const folders = listResult.prefixes.map((folder) => ({ fullPath: folder.fullPath }));
    dispatch(setFolders(folders));
    return folders;
  };

  const fetchFolder = async (folderName: string): Promise<Array<CardInGame>> => {
    if (fetchedCards[folderName]) return fetchedCards[folderName].map((card) => ({ ...card, openedTimes: 0 }));

    const folderReference = ref(storage, folderName);
    const listResult = await listAll(folderReference);

    // TODO: run this for each folder once, and it will configure a firestore BE Cache-Control header
    // don't forget to update allow write: if true; in storage.rules
    // const metadataUpdateMap = listResult.items.map(
    //   (itemRef) => updateMetadata(itemRef, {cacheControl: 'max-age=7200'})
    // );
    // await Promise.all(metadataUpdateMap);

    const fullPaths = listResult.items.map((item) => item.fullPath);

    if (!fullPaths.length) return [];

    const firstFileFullPath = fullPaths[0];
    const downloadUrl = await getDownloadURL(ref(storage, firstFileFullPath));
    const decoded = decodeURIComponent(downloadUrl);

    const cards: Array<CardInGame> = fullPaths.map((fullPath: string) => ({
      url: decoded.replace(firstFileFullPath, encodeURIComponent(fullPath)),
      fullPath,
      folderName,
      openedTimes: 0,
    }));

    dispatch(addFolder({ folderName, cards }));

    try {
      const cache: Cache = await caches.open(folderName);
      await cache.addAll(cards.map(({ url }) => url));
    } catch (err) {
      // do nothing
    }

    return cards;
  };

  return {
    fetchFolders,
    fetchFolder,
  };
};

export default useCards;
