const AUTH = 'auth';

export const AUTH_ROUTES = {
  SIGN_IN: 'sign-in',
  SIGN_UP: 'sign-up',
  RESET: 'reset',
  RECOVER: 'recover',
};

export const ROUTES_WITHOUT_LANGUAGE = {
  ROOT: '',
  AUTH,
  AUTH_SIGN_IN: `${AUTH}/${AUTH_ROUTES.SIGN_IN}`,
  AUTH_SIGN_UP: `${AUTH}/${AUTH_ROUTES.SIGN_UP}`,
  AUTH_RESET: `${AUTH}/${AUTH_ROUTES.RESET}`,
  AUTH_RECOVER: `${AUTH}/${AUTH_ROUTES.RECOVER}`,
  '404': '404',
  DASHBOARD: 'dashboard',
  SOLO: 'solo',
  MULTIPLAYER: 'multiplayer',
  COMPUTER: 'computer',
  TOURNAMENT: 'tournament',
  LADDER_GAME: 'ladder-game',
  INFO: 'info',
  PRIVACY_POLICY: 'privacy-policy',
  TERMS_OF_SERVICE: 'terms-of-service',
  SOLO_DEMO: 'solo-demo',
};

export const makeRoutes = (language: string) =>
  Object.entries(ROUTES_WITHOUT_LANGUAGE).reduce(
    (acc: { [key: string]: string }, [key, value]) => ({ ...acc, [key]: `/${language}/${value}` }),
    {}
  );
