import React, { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ListItemButton, Typography, ListItemText, Tooltip } from '@mui/material';
import styles from './SideBarItem.module.scss';

interface SideBarItemProps {
  title: string;
  route: string;
  icon: JSX.Element;
  transitionedIsOpen: boolean;
  openLoginModal: () => void;
  disabled?: boolean;
}

interface WrapProps {
  title: string;
  children: JSX.Element;
  transitionedIsOpen: boolean;
  disabled?: boolean;
}

const Wrap: FC<WrapProps> = ({ title, transitionedIsOpen, children, disabled }) =>
  !transitionedIsOpen || disabled ? (
    <Tooltip title={title} placement="right" arrow>
      {children}
    </Tooltip>
  ) : (
    children
  );

const SideBarItem: FC<SideBarItemProps> = ({ transitionedIsOpen, route, icon, title, openLoginModal, disabled }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'NAVIGATION_BAR' });
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const { pathname } = useLocation();

  const handleClick = () => {
    if (disabled) {
      openLoginModal();
    }
  };

  return (
    <Wrap
      title={disabled ? t('DISABLED_ITEM_TOOLTIP') : title}
      transitionedIsOpen={transitionedIsOpen}
      disabled={disabled}
    >
      <div onClick={handleClick} className={styles['item-wrap']}>
        <ListItemButton
          component={NavLink}
          to={route}
          className={clsx(styles.item, {
            [styles.active]: pathname.startsWith(route),
            [styles.expanded]: transitionedIsOpen,
          })}
          disabled={disabled}
          aria-label={ariaLabelT('LINK.GENERIC', { pageName: title }) as string}
        >
          <>
            <div className={styles['icon-wrap']}>{icon}</div>
            {transitionedIsOpen && (
              <ListItemText className={styles.title}>
                <Typography variant="h5">{title}</Typography>
              </ListItemText>
            )}
          </>
        </ListItemButton>
      </div>
    </Wrap>
  );
};

export default SideBarItem;
