import '@mui/lab/themeAugmentation';
import { createTheme as createMuiTheme } from '@mui/material/styles';
import { muiTypography } from '../assets/mui';
import breakpoints from './breakpoints';

const createTheme = () =>
  createMuiTheme({
    spacing: 4,
    breakpoints,
    typography: muiTypography,
  });

export default createTheme;
