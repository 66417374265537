import React, { forwardRef, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Stack } from '@mui/material';
import { useMainLayout } from '../Layout/LayoutWrapper';
import styles from './Card.module.scss';

interface CardProps {
  frontImageSrc: string;
  backPatternSrc: string;
  opened: boolean;
  turnCard: () => void;
  prevent: boolean;
  correct?: boolean;
  forwardStyle?: CSSProperties;
}

const Card = forwardRef<HTMLDivElement, CardProps>(
  ({ frontImageSrc, backPatternSrc, opened, turnCard, prevent, correct, forwardStyle }, ref) => {
    const { t: altT } = useTranslation('translation', { keyPrefix: 'ALTERNATIVE_TEXT' });
    const { isMobile } = useMainLayout();
    const handleClick = () => {
      if (!prevent) {
        turnCard();
      }
    };

    return (
      <div
        className={clsx(styles.card, { [styles.mobile]: isMobile, [styles.flipped]: !opened })}
        onClick={handleClick}
        ref={ref}
        style={forwardStyle}
      >
        <Stack justifyContent="center" className={styles['card-front']}>
          {typeof correct === 'boolean' && (
            <div
              className={clsx(styles['card-overlay'], styles[correct ? 'correct' : 'not-correct'])}
              style={{ borderRadius: forwardStyle?.borderRadius }}
            />
          )}
          <img src={frontImageSrc} alt={altT('CARD_FRONT') as string} />
        </Stack>
        <div className={styles['card-back']}>
          <img src={backPatternSrc} alt={altT('CARD_BACK') as string} />
        </div>
      </div>
    );
  }
);

export default Card;
