import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { Stepper, Step, StepLabel } from '@mui/material';
import { getLadderGameStep, setOpeningMethod } from '../../redux/slices';
import { openMethod, ladderGameSteps } from '../../constants';
import { TopPanel } from '../index';
import styles from './LadderGameStepper.module.scss';

const visibleBuffer = 3;
const sideVisibleBuffer = 4;
const threshold = ladderGameSteps.length - sideVisibleBuffer;

const LadderGameStepper: FC = () => {
  const dispatch = useDispatch();
  const ladderGameStep = useSelector(getLadderGameStep);

  useEffect(() => {
    dispatch(setOpeningMethod(openMethod));
  }, []);

  const firstPart = useMemo(() => ladderGameStep < visibleBuffer, [ladderGameStep]);
  const lastPart = useMemo(() => ladderGameStep - 1 >= threshold, [ladderGameStep]);

  const uiSteps = useMemo(() => {
    // @ts-ignore
    const cutFrom = ladderGameStep === -1 ? 0 : lastPart ? threshold : firstPart ? 0 : ladderGameStep - 1;
    return ladderGameSteps.slice(cutFrom, cutFrom + (firstPart || lastPart ? sideVisibleBuffer : visibleBuffer));
  }, [ladderGameStep]);

  const uiStep = useMemo(
    () => (firstPart ? ladderGameStep : lastPart ? ladderGameStep - threshold + 1 : 2),
    [ladderGameStep]
  );

  return (
    <TopPanel>
      <Stepper activeStep={uiStep} alternativeLabel className={styles.stepper}>
        {!firstPart && (
          <Step key="1">
            <StepLabel icon="1" />
          </Step>
        )}
        {uiSteps.map((step, i) => (
          <Step key={step} className={clsx({ [styles.trace]: !i && ladderGameStep >= 3 })}>
            <StepLabel icon={step} />
          </Step>
        ))}
        {!lastPart && (
          <Step key="19" className={styles.target}>
            <StepLabel icon="19" />
          </Step>
        )}
      </Stepper>
    </TopPanel>
  );
};

export default LadderGameStepper;
