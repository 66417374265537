import React, { FC, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Stack, Tooltip, Typography } from '@mui/material';
import { getFolder, setGameBackUrl } from '../../redux/slices';
import { HorizontalCarousel } from '../../components';
import { HelpOutlinedRounded } from '../../assets/icons';
import styles from './DesktopSettings.module.scss';

interface CardBackSettingProps {
  elementsNumber?: number;
  isMobile?: boolean;
}
const CardBackSetting: FC<CardBackSettingProps> = ({ elementsNumber, isMobile }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'SETTINGS' });
  const dispatch = useDispatch();
  const cardsBacks = useSelector(getFolder('card_backs'));
  const [gameBackIndex, setGameBackIndex] = useState(0);

  const handleCardBackChange = (index: number) => {
    setGameBackIndex(index);
    dispatch(setGameBackUrl(cardsBacks[index].url));
  };

  const cardsBacksCarouselItems = useMemo(
    () => (cardsBacks || []).map(({ url }) => ({ src: url, hint: '' })),
    [cardsBacks]
  );

  return (
    <Stack direction="column" gap={isMobile ? 4 : 2} className={styles.setting}>
      <Stack
        direction={isMobile ? 'column' : 'row'}
        alignItems={isMobile ? 'start' : 'center'}
        gap={isMobile ? 1 : 2}
        className={styles['setting-head']}
      >
        <Typography variant={isMobile ? 'h4' : 'h5'} color="var(--text-secondary)">
          {t('CARD_BACK_LABEL')}
        </Typography>
        {isMobile ? (
          <Typography variant="body2" color="var(--text-secondary)">
            {t('CARD_BACK_HINT')}
          </Typography>
        ) : (
          <Tooltip title={t('CARD_BACK_HINT')} placement="top" arrow>
            <HelpOutlinedRounded className={styles.hint} />
          </Tooltip>
        )}
      </Stack>
      <HorizontalCarousel
        items={cardsBacksCarouselItems}
        selectedItem={gameBackIndex}
        onItemChange={handleCardBackChange}
        elementsNumber={elementsNumber}
      />
    </Stack>
  );
};

export default CardBackSetting;
