import React, { FC, useEffect, useRef, SVGProps } from 'react';
import { Stack, Typography } from '@mui/material';
import styles from './HorizontalCarouselInfiniteAuto.module.scss';

const isWindows = window.navigator.userAgent.toLowerCase().includes('windows');
const scrollStep = isWindows ? 1.5 : 1; // Windows OS don't recognise a change of 1px, minimum - 1.5px;
const scrollTimer = isWindows ? 15 : 10;

interface Element {
  SvgComponent: FC<SVGProps<SVGSVGElement>>;
  title: string;
}

interface HorizontalCarouselInfiniteAutoProps {
  elements: Array<Element>;
}

const HorizontalCarouselInfiniteAuto: FC<HorizontalCarouselInfiniteAutoProps> = ({ elements }) => {
  const refWrap = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = refWrap.current;
    let scrollInterval: NodeJS.Timeout;
    if (element) {
      setTimeout(() => {
        scrollInterval = setInterval(() => {
          element.scrollLeft += scrollStep;
        }, scrollTimer);
      }, 1000); // looks better on UI if start scrolling a bit later

      element.addEventListener('scroll', () => {
        const { scrollLeft, clientWidth, scrollWidth } = element;

        if (scrollLeft + clientWidth >= scrollWidth) {
          element.scrollLeft = scrollWidth / 2 - clientWidth - 18; // 18 is a half of gap={9}
        }
      });
    }

    return () => clearInterval(scrollInterval);
  }, []);

  const imagesIndexMap = elements.map(({ SvgComponent, title }, i) => ({ SvgComponent, title, i }));
  const infiniteImages = [
    ...imagesIndexMap,
    ...imagesIndexMap.map(({ SvgComponent, title, i }) => ({ SvgComponent, title, i: i + elements.length })),
  ];

  return (
    <div className={styles['horizontal-carousel-infinite-auto-wrap']} ref={refWrap}>
      <Stack flexDirection="row" gap={9} className={styles['horizontal-carousel-infinite-auto']}>
        {infiniteImages.map(({ SvgComponent, title, i }) => (
          <Stack key={`horizontal-carousel-infinite-auto-img-${i}`} className={styles['icon-wrap']}>
            <Typography variant="h1" className={styles['icon-title']}>
              {title}
            </Typography>
            <SvgComponent />
          </Stack>
        ))}
      </Stack>
    </div>
  );
};

export default HorizontalCarouselInfiniteAuto;
