import React, { FC, Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import Settings from '../../components/Settings/Settings';
import DonationButton from '../../components/DonationButton/DonationButton';
import { getCardsInGame } from '../../redux/slices';

const Playground = lazy(() => import('../../components/Playground/Playground'));
const PlayersPanel = lazy(() => import('../../components/PlayersPanel/PlayersPanel'));

const Computer: FC = () => {
  const cardsInGame = useSelector(getCardsInGame);

  return (
    <>
      {cardsInGame.length ? (
        <Suspense fallback={<CircularProgress color="primary" />}>
          <PlayersPanel vsComputer />
          <Playground />
        </Suspense>
      ) : (
        <Settings withOpeningMethod withCardsNumber withComputerGrade withPlayers />
      )}
      <DonationButton onPlayground={!!cardsInGame.length} />
    </>
  );
};

export default Computer;
