import React, { FC, useEffect, useState, useRef, ChangeEvent, MouseEvent } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, TextField, Stack, Typography, Divider, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { emailRegexp, nameRegexp, passwordRegexp } from '../../constants';
import useAuth from '../../hooks/useAuth';
import { AuthSignUp } from '../../types';
import styles from './Auth.module.scss';
import { GoogleIcon } from '../../assets';
import { useRoutes } from '../../hooks';

const Register: FC = () => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'AUTH' });
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const ROUTES = useRoutes();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<AuthSignUp>();

  const passwordRef = useRef({});
  passwordRef.current = watch('password', '');

  const navigate = useNavigate();
  const { user, registerWithEmailAndPassword, signInWithGoogle } = useAuth();

  const [isSending, setIsSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowRepeatPassword = () => setShowRepeatPassword((show) => !show);

  const preventMouseDown = (event: MouseEvent<HTMLButtonElement>) => event.preventDefault();

  useEffect(() => {
    if (user) navigate(ROUTES.DASHBOARD);
  }, [user]);

  const signUpHandler: SubmitHandler<AuthSignUp> = async (formData) => {
    const { name, email, password } = formData;

    setIsSending(true);
    try {
      await registerWithEmailAndPassword(name, email, password);
      reset(formData);
    } catch (errCode) {
      const errorLocalUser = `ERROR_MAP.USER.${errCode}`;
      setErrorMessage((i18n.exists(`AUTH.${errorLocalUser}`) ? t(errorLocalUser) : t('UNEXPECTED_ERROR')) as string);
    } finally {
      setIsSending(false);
    }
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => setErrorMessage('');

  return (
    <Stack direction="column" gap={10}>
      <Typography variant="h1">{t('SIGN_UP_TITLE')}</Typography>

      <Stack direction="column" gap={6}>
        <Button
          className={styles['google-button']}
          startIcon={<GoogleIcon />}
          onClick={signInWithGoogle}
          aria-label={t('GOOGLE_SIGN_UP_BUTTON') as string}
        >
          <Typography variant="button" color="var(--text-lightest)">
            {t('GOOGLE_SIGN_UP_BUTTON')}
          </Typography>
        </Button>

        <Divider className={styles.divider}>
          <Typography variant="body-1" color="var(--text-lightest)">
            {t('OR')}
          </Typography>
        </Divider>

        <TextField
          className={styles['input-box']}
          {...register('name', {
            required: { value: true, message: t('NAME.ERROR.REQUIRED') },
            pattern: { value: nameRegexp, message: t('NAME.ERROR.PATTERN') },
            minLength: { value: 2, message: t('NAME.ERROR.MIN_LENGTH') },
            maxLength: { value: 40, message: t('NAME.ERROR.MAX_LENGTH') },
          })}
          label={t('NAME.PLACEHOLDER')}
          helperText={errors.name?.message}
          error={!!errors.name?.type}
          fullWidth
          autoFocus
        />

        <TextField
          className={styles['input-box']}
          onInput={handleInput}
          {...register('email', {
            required: { value: true, message: t('EMAIL.ERROR.REQUIRED') },
            pattern: { value: emailRegexp, message: t('EMAIL.ERROR.PATTERN') },
          })}
          label={t('EMAIL.PLACEHOLDER')}
          helperText={errors.email?.message || errorMessage}
          error={!!errors.email?.type || !!errorMessage}
          fullWidth
        />

        <TextField
          className={styles['input-box']}
          {...register('password', {
            required: { value: true, message: t('PASSWORD.ERROR.REQUIRED') },
            pattern: { value: passwordRegexp, message: t('PASSWORD.ERROR.PATTERN') },
            minLength: { value: 6, message: t('PASSWORD.ERROR.MIN_LENGTH') },
            maxLength: { value: 16, message: t('PASSWORD.ERROR.MAX_LENGTH') },
          })}
          label={t('PASSWORD.PLACEHOLDER')}
          helperText={errors.password?.message}
          error={!!errors.password?.type}
          fullWidth
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={preventMouseDown}
                  edge="end"
                  aria-label={ariaLabelT(`BUTTON.${showPassword ? 'HIDE_PASSWORD' : 'SHOW_PASSWORD'}`) as string}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          className={styles['input-box']}
          {...register('passwordRepeat', {
            required: { value: true, message: t('PASSWORD_REPEAT.ERROR.REQUIRED') },
            validate: (value) => value === passwordRef.current || (t('PASSWORD_REPEAT.ERROR.VALIDATE') as string),
          })}
          label={t('PASSWORD_REPEAT.PLACEHOLDER')}
          helperText={errors.passwordRepeat?.message}
          error={!!errors.passwordRepeat?.type}
          fullWidth
          type={showRepeatPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowRepeatPassword}
                  onMouseDown={preventMouseDown}
                  edge="end"
                  aria-label={ariaLabelT(`BUTTON.${showRepeatPassword ? 'HIDE_PASSWORD' : 'SHOW_PASSWORD'}`) as string}
                >
                  {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          size="small"
          variant="contained"
          onClick={handleSubmit(signUpHandler)}
          className={styles['action-button']}
          loading={isSending}
        >
          <Typography variant="button">{t('SIGN_UP')}</Typography>
        </LoadingButton>

        <Stack direction="row" gap={2}>
          <Typography variant="body-1">{t('ALREADY_HAVE_AN_ACCOUNT')}</Typography>
          <Button
            component={RouterLink}
            to={ROUTES.AUTH_SIGN_IN}
            variant="text"
            className={styles['text-button']}
            aria-label={ariaLabelT('LINK.SIGN_IN') as string}
          >
            <Typography variant="h4">{t('LOGIN')}</Typography>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Register;
