import React, { FC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { PieChart } from 'react-minimal-pie-chart';
import clsx from 'clsx';
import {
  useGetSoloDataQuery,
  useGetMultiPlayerDataQuery,
  useGetComputerDataQuery,
  useGetTournamentDataQuery,
  useGetLadderGameDataQuery,
} from '../../redux/api';
import { MAX_COMPUTER_GRADE } from '../../constants';
import { CardsIcon } from '../../assets/icons';
import { calculateAverageComputerGrade, calculateFavoriteCategory } from '../../utils';
import { useMainLayout } from '../Layout/LayoutWrapper';
import useAuth from '../../hooks/useAuth';
import styles from './GameAnalyticsWidgetSet.module.scss';

const GameAnalyticsWidgetSet: FC = () => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'DASHBOARD' });
  const { t: commonT } = useTranslation('translation', { keyPrefix: 'COMMON' });
  const { t: settingsT } = useTranslation('translation', { keyPrefix: 'SETTINGS' });
  const { user, loading: authLoading } = useAuth();
  const { narrowBreakPoint, isMobile } = useMainLayout();

  const { data: soloData } = useGetSoloDataQuery({ uid: user?.uid! }, { skip: authLoading });
  const { data: multiPlayerData } = useGetMultiPlayerDataQuery({ uid: user?.uid! }, { skip: authLoading });
  const { data: computerData } = useGetComputerDataQuery({ uid: user?.uid! }, { skip: authLoading });
  const { data: tournamentData } = useGetTournamentDataQuery({ uid: user?.uid! }, { skip: authLoading });
  const { data: ladderGameData } = useGetLadderGameDataQuery({ uid: user?.uid! }, { skip: authLoading });

  const gamesPieChartData = [
    { title: commonT('GAME_TYPES.SOLO') as string, value: soloData?.total || 0, color: 'var(--hex-1-dark)' },
    { title: commonT('GAME_TYPES.COMPUTER') as string, value: computerData?.total || 0, color: 'var(--hex-2-dark)' },
    {
      title: commonT('GAME_TYPES.MULTIPLAYER') as string,
      value: multiPlayerData?.total || 0,
      color: 'var(--hex-3-dark)',
    },
    {
      title: commonT('GAME_TYPES.LADDER_GAME') as string,
      value: ladderGameData?.total || 0,
      color: 'var(--hex-4-dark)',
    },
    {
      title: commonT('GAME_TYPES.TOURNAMENT') as string,
      value: tournamentData?.total || 0,
      color: 'var(--hex-5-dark)',
    },
  ].sort((a, b) => a.value - b.value);

  const gamesPieChartDataWoEmpty = gamesPieChartData.filter((a) => a.value);

  const gamesPieChartDataEmpty = [{ title: t('NO_GAMES') as string, value: 1, color: 'var(--gray-bg-3)' }];

  const { totalGames, favoriteCategory, averageComputerGrade } = useMemo(
    () => ({
      totalGames: [
        soloData?.total || 0,
        multiPlayerData?.total || 0,
        computerData?.total || 0,
        tournamentData?.total || 0,
        ladderGameData?.total || 0,
      ].reduce((acc, v) => acc + v, 0),
      favoriteCategory: calculateFavoriteCategory(
        [
          soloData?.last10 || [],
          multiPlayerData?.last10 || [],
          computerData?.last10 || [],
          tournamentData?.last10 || [],
          ladderGameData?.last10 || [],
        ].flat()
      ),
      averageComputerGrade: calculateAverageComputerGrade(computerData?.last10 || []),
    }),
    [soloData, multiPlayerData, computerData, tournamentData, ladderGameData]
  );

  const computerGradePieChardData = [
    { title: t('YOU_ARE_HERE') as string, value: averageComputerGrade, color: 'var(--hex-4-dark)' },
    {
      title: t('TRY_HARDER') as string,
      value: MAX_COMPUTER_GRADE - averageComputerGrade,
      color: 'var(--gray-bg-3)',
    },
  ];

  const computerGradePieChardDataEmpty = [
    { title: t('NO_COMPUTER_GAMES') as string, value: 1, color: 'var(--gray-bg-3)' },
  ];

  return (
    <Stack direction="column" gap={4} width="stretch">
      <Typography variant={isMobile ? 'h3' : 'h2'}>{t('ANALYTICS_TITLE')}</Typography>
      <Stack direction={isMobile ? 'column' : 'row'} gap={6} width="stretch">
        <Stack
          direction="column"
          gap={4}
          width="stretch"
          className={clsx(styles.widget, { [styles['widget-mobile']]: isMobile })}
        >
          <Typography variant={isMobile ? 'h4' : 'h3'}>{t('NUMBER_OF_GAMES')}</Typography>
          <Stack direction={isMobile ? 'column' : 'row'} gap={6} position="relative">
            <Stack direction="column" gap={4} alignItems="center">
              <Stack
                direction="column"
                className={clsx(styles['total-games'], { [styles.narrow]: narrowBreakPoint > 1 })}
                alignItems="center"
              >
                <Typography variant={narrowBreakPoint <= 1 ? 'h1' : isMobile ? 'h1' : 'h2'}>{totalGames}</Typography>
                <Typography variant="body-2">
                  <Trans i18nKey="DASHBOARD.TOTAL_GAMES" context={totalGames === 1 ? 'ONE' : ''} />
                </Typography>
              </Stack>

              <PieChart
                className={styles['games-pie-chart']}
                data={totalGames ? gamesPieChartDataWoEmpty : gamesPieChartDataEmpty}
                lineWidth={30}
                paddingAngle={totalGames ? 3 : 0}
                startAngle={270}
                animate
              />
            </Stack>
            <Stack direction="column" gap={3} alignItems="start" justifyContent="center">
              {gamesPieChartData.map(({ title, value, color }) => (
                <Stack direction="row" gap={3} key={title} alignItems="center">
                  <span
                    style={{ background: value ? color : 'var(--gray-bg-3)' }}
                    className={styles['game-color-chip']}
                  />
                  <Stack direction="row" gap={1} alignItems="center">
                    <Typography variant="h5">{value}</Typography>
                    <Typography variant="body-1" color="var(--text-lightest)">{` ${title}`}</Typography>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>

        <Stack direction="column" gap={6} width="stretch">
          <Stack direction="column" gap={1} className={clsx(styles.widget, { [styles['widget-mobile']]: isMobile })}>
            <Typography variant={isMobile ? 'h4' : 'h3'}>{t('FAVORITE_CATEGORY')}</Typography>
            {i18n.exists(`SETTINGS.FOLDERS_MAP.${favoriteCategory}`) ? (
              <Typography variant={isMobile ? 'h2' : 'h1'}>{`{ ${settingsT(
                `FOLDERS_MAP.${favoriteCategory}`
              )} }`}</Typography>
            ) : (
              <Typography variant="body-2" color="var(--text-secondary)">
                {t('PLAY_FIRST_GAME')}
              </Typography>
            )}
            <CardsIcon className={clsx(styles['cards-icon'], { [styles['cards-icon-mobile']]: isMobile })} />
          </Stack>

          <Stack
            direction={isMobile ? 'column' : 'row'}
            justifyContent="space-between"
            className={clsx(styles.widget, { [styles['widget-mobile']]: isMobile })}
            gap={isMobile ? 4 : 0}
          >
            <Stack direction="column" gap={1}>
              <Typography variant="h3">{t('AVERAGE_COMPUTER_GRADE')}</Typography>
              <Typography variant="body-2" color="var(--text-secondary)">
                {averageComputerGrade ? t('WELL_DONE') : t('NO_GAMES_PLAYED')}
              </Typography>
            </Stack>

            <Stack direction="column" position="relative">
              <Stack direction="column" className={styles['computer-grade']}>
                <Typography variant="h2">{averageComputerGrade || '-'}</Typography>
                <Typography variant="body-2" color="var(--text-secondary)">
                  {t('OUT_OF', { MAX_COMPUTER_GRADE })}
                </Typography>
              </Stack>

              <PieChart
                className={styles['computer-grade-pie-chart']}
                data={averageComputerGrade ? computerGradePieChardData : computerGradePieChardDataEmpty}
                startAngle={180}
                lengthAngle={180}
                viewBoxSize={[100, 50]}
                lineWidth={30}
                paddingAngle={3}
                animate
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default GameAnalyticsWidgetSet;
