import React, { FC, useState, SyntheticEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { Typography, Stack, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Author, DonationButton, MobileTopNotch } from '../../components';
import { ComputerIcon, LadderIcon, MultiplayerIcon, SoloIcon, TournamentIcon } from '../../assets';
import { useMainLayout } from '../../components/Layout/LayoutWrapper';
import { useRoutes } from '../../hooks';
import styles from './Info.module.scss';

const Info: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'INFO' });
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const ROUTES = useRoutes();
  const [expanded, setExpanded] = useState<string | false>(false);
  const { wideBreakPoint, isMobile } = useMainLayout();

  const handleChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <MobileTopNotch title={t('TITLE')} />

      <Stack
        direction="row"
        gap={8}
        className={clsx(styles['page-wrap'], {
          [styles['page-wrap-mobile']]: isMobile,
          [styles['page-wrap-mobile-with-notch']]: isMobile,
        })}
      >
        <Stack direction="column" gap={12} className={styles.info}>
          {!wideBreakPoint && <Author />}

          <Stack direction="column" gap={3}>
            <Typography variant="h2">{t('WELCOME')}</Typography>
            <Typography variant="body-1">
              <Trans i18nKey="INFO.DESCRIPTION" components={[<br />]} />
            </Typography>
          </Stack>

          <Stack direction="column" gap={6}>
            <Typography variant="h3">{t('GAME_MECHANICS_TITLE')}</Typography>
            <Stack direction="column" gap={4}>
              <Typography variant="h4">{t('FULLY_CONFIGURABLE_GAME')}</Typography>
              <Stack direction="column" gap={3}>
                <Accordion
                  expanded={expanded === 'solo'}
                  onChange={handleChange('solo')}
                  className={styles['accordion-item']}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Stack direction="row" gap={2} className={styles.title}>
                      <SoloIcon />
                      <Typography variant="body-1">{t('SOLO_LABEL')}</Typography>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body-2">{t('SOLO_DESCRIPTION')}</Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expanded === 'computer'}
                  onChange={handleChange('computer')}
                  className={styles['accordion-item']}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Stack direction="row" gap={2} className={styles.title}>
                      <ComputerIcon />
                      <Typography variant="body-1">{t('COMPUTER_LABEL')}</Typography>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body-2">
                      <Trans i18nKey="INFO.COMPUTER_DESCRIPTION" components={[<br />]} />
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expanded === 'multiplayer'}
                  onChange={handleChange('multiplayer')}
                  className={styles['accordion-item']}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Stack direction="row" gap={2} className={styles.title}>
                      <MultiplayerIcon />
                      <Typography variant="body-1">{t('MULTIPLAYER_LABEL')}</Typography>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body-2">
                      <Trans i18nKey="INFO.MULTIPLAYER_DESCRIPTION" components={[<br />]} />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Stack>
            </Stack>

            <Stack direction="column" gap={4}>
              <Typography variant="h4">{t('AUTOMATICALLY_CONFIGURABLE_GAME')}</Typography>
              <Stack direction="column" gap={3}>
                <Accordion
                  expanded={expanded === 'ladder-game'}
                  onChange={handleChange('ladder-game')}
                  className={styles['accordion-item']}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Stack direction="row" gap={2} className={styles.title}>
                      <LadderIcon />
                      <Typography variant="body-1">{t('LADDER_GAME_LABEL')}</Typography>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body-2">
                      <Trans
                        i18nKey="INFO.LADDER_GAME_DESCRIPTION"
                        components={[
                          <br />,
                          <RouterLink
                            to={ROUTES.DASHBOARD}
                            className={styles.link}
                            aria-label={ariaLabelT('LINK.DASHBOARD') as string}
                          />,
                        ]}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expanded === 'tournament'}
                  onChange={handleChange('tournament')}
                  className={styles['accordion-item']}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Stack direction="row" gap={2} className={styles.title}>
                      <TournamentIcon />
                      <Typography variant="body-1">{t('TOURNAMENT_LABEL')}</Typography>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails className={styles['accordion-item-details']}>
                    <Typography variant="body-2">
                      <Trans
                        i18nKey="INFO.TOURNAMENT_DESCRIPTION"
                        components={[
                          <br />,
                          <RouterLink
                            to={ROUTES.DASHBOARD}
                            className={styles.link}
                            aria-label={ariaLabelT('LINK.DASHBOARD') as string}
                          />,
                        ]}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Stack>
            </Stack>
          </Stack>

          <Stack direction="column" gap={6}>
            <Typography variant="h3">{t('GAME_CONFIGURATIONS_SUBTITLE')}</Typography>
            <Stack direction="column" gap={3}>
              <Typography variant="body-1">{t('GAME_CONFIGURATION_1')}</Typography>
              <Typography variant="body-1">{t('GAME_CONFIGURATION_2')}</Typography>
              <Typography variant="body-1">{t('GAME_CONFIGURATION_3')}</Typography>
              <Typography variant="body-1">{t('GAME_CONFIGURATION_4')}</Typography>
            </Stack>
          </Stack>

          <Stack direction="column" gap={6}>
            <Typography variant="h3">{t('OPENING_METHODS_TITLE')}</Typography>

            <Stack direction="column" gap={4}>
              <Typography variant="h4">{t('GENERAL_OPENING_TYPE')}</Typography>
              <Stack direction="row" gap={3} className={styles['opening-methods-items']}>
                <Typography variant="body-1">{t('OPEN_GAME')}</Typography>
                <Typography variant="body-1">{t('BLIND_GAME')}</Typography>
              </Stack>
            </Stack>

            <Stack direction="column" gap={4}>
              <Typography variant="h4">{t('BY_ONE_GAMES_TYPE')}</Typography>
              <Stack direction="row" gap={3} className={styles['opening-methods-items']}>
                <Typography variant="body-1">{t('A-Z_GAME')}</Typography>
                <Typography variant="body-1">{t('Z-A_GAME')}</Typography>
                <Typography variant="body-1">{t('SNAKE_GAME')}</Typography>
                <Typography variant="body-1">{t('SNAKE_REVERSE_GAME')}</Typography>
                <Typography variant="body-1">{t('CLOCKWISE_GAME')}</Typography>
                <Typography variant="body-1">{t('COUNTERCLOCKWISE_GAME')}</Typography>
                <Typography variant="body-1">{t('RANDOM_GAME')}</Typography>
              </Stack>
            </Stack>

            <Stack direction="column" gap={4}>
              <Typography variant="h4">{t('BY_LINE_GAMES_TYPE')}</Typography>
              <Stack direction="row" gap={3} className={styles['opening-methods-items']}>
                <Typography variant="body-1">{t('COLUMN_GAME')}</Typography>
                <Typography variant="body-1">{t('COLUMN_REVERSE_GAME')}</Typography>
                <Typography variant="body-1">{t('ROW_GAME')}</Typography>
                <Typography variant="body-1">{t('ROW_REVERSE_GAME')}</Typography>
                <Typography variant="body-1">{t('DIAGONAL_BOTTOM_RIGHT_GAME')}</Typography>
                <Typography variant="body-1">{t('DIAGONAL_TOP_LEFT_GAME')}</Typography>
                <Typography variant="body-1">{t('DIAGONAL_BOTTOM_LEFT_GAME')}</Typography>
                <Typography variant="body-1">{t('DIAGONAL_TOP_RIGHT_GAME')}</Typography>
              </Stack>
            </Stack>

            <Stack direction="column" gap={3}>
              <Typography variant="body-1">{t('OPENING_DURATION_DESCRIPTION')}</Typography>
              <Typography variant="body-1">{t('GAME_EFFICIENCY_DESCRIPTION')}</Typography>
            </Stack>
          </Stack>

          <Stack direction="column" gap={6}>
            <Typography variant="h3">{t('PRIVACY_POLICY_SUBTITLE')}</Typography>
            <Typography variant="body-1">
              <Trans
                i18nKey="INFO.PRIVACY_POLICY_TEXT"
                components={[
                  <RouterLink
                    to={ROUTES.PRIVACY_POLICY}
                    className={styles.link}
                    aria-label={ariaLabelT('LINK.PRIVACY_POLICY') as string}
                  />,
                ]}
              />
            </Typography>
          </Stack>

          <Stack direction="column" gap={6}>
            <Typography variant="h3">{t('TERMS_OF_SERVICE_SUBTITLE')}</Typography>
            <Typography variant="body-1">
              <Trans
                i18nKey="INFO.TERMS_OF_SERVICE_TEXT"
                components={[
                  <RouterLink
                    to={ROUTES.TERMS_OF_SERVICE}
                    className={styles.link}
                    aria-label={ariaLabelT('LINK.TERMS_OF_SERVICE') as string}
                  />,
                ]}
              />
            </Typography>
          </Stack>
        </Stack>

        {!!wideBreakPoint && (
          <Stack direction="column" position="relative">
            <Author aside />
          </Stack>
        )}
        <DonationButton />
      </Stack>
    </>
  );
};

export default Info;
