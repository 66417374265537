import { CARD_TIMER, clockwiseMethod } from '../constants';
import { ClockMethod, ColumnMethod, DiagonalMethod, LineType, Method, RowMethod, SnakeMethod } from '../types';

export const makeRandom = (n: number) => Math.floor(Math.random() * n); // 0 including, n - not including

const multiply = (arr: Array<any>, factor: number) => {
  let result = arr;
  for (let i = 1; i < factor; i++) {
    result = result.concat(arr);
  }
  return result;
};

const shuffle = <T>(arr: Array<T>) => arr.sort(() => Math.random() - 0.5);

export const toFixedNoRound = (num: number, precision = 1) => {
  const factor = Math.pow(10, precision);
  return Math.floor(num * factor) / factor;
};

export const getRandomCollection = <T>(entities: Array<T>, number: number): Array<T> => {
  const entriesCopy = [...entities];

  if (entriesCopy.length < number) {
    return entities;
  }

  const result = [];
  while (result.length < number) {
    result.push(entriesCopy.splice(makeRandom(entriesCopy.length), 1)[0]);
  }

  return shuffle(multiply(result, 2));
};

const arrayFromFlat = <T>(flatArray: T[], rowLength: number): T[][] => {
  const result: T[][] = [];
  for (let i = 0; i < flatArray.length; i += rowLength) {
    result.push(flatArray.slice(i, i + rowLength));
  }
  return result;
};

const flattenByClockMethod = (arr: number[][], method: ClockMethod): number[] => {
  const result: number[] = [];
  let top = 0;
  let bottom = arr.length - 1;
  let left = 0;
  let right = arr[0].length - 1;
  const isClockwise = method === clockwiseMethod;
  let direction = isClockwise ? 0 : 1;

  while (top <= bottom && left <= right) {
    if (direction === 0) {
      // going right

      for (let i = left; i <= right; i++) {
        const value = isClockwise ? arr[top]?.[i] : arr[bottom]?.[i];
        if (typeof value === 'number') {
          result.push(value);
        }
      }

      if (isClockwise) top++;
      else bottom--;
    } else if (direction === 1) {
      // going down

      for (let i = top; i <= bottom; i++) {
        const value = isClockwise ? arr[i]?.[right] : arr[i]?.[left];
        if (typeof value === 'number') {
          result.push(value);
        }
      }

      if (isClockwise) right--;
      else left++;
    } else if (direction === 2) {
      // going left
      for (let i = right; i >= left; i--) {
        const value = isClockwise ? arr[bottom]?.[i] : arr[top]?.[i];
        if (typeof value === 'number') {
          result.push(value);
        }
      }

      if (isClockwise) bottom--;
      else top++;
    } else if (direction === 3) {
      // going up
      for (let i = bottom; i >= top; i--) {
        const value = isClockwise ? arr[i]?.[left] : arr[i]?.[right];
        if (typeof value === 'number') {
          result.push(value);
        }
      }

      if (isClockwise) left++;
      else right--;
    }
    direction = method === clockwiseMethod ? (direction + 1) % 4 : (direction + 3) % 4;
  }

  return result;
};

const flattenBySnakeMethod = (arr: number[][], method: SnakeMethod): number[] =>
  (method === 'snake' ? arr : arr.reverse()).reduce((acc, v, i) => {
    const isOdd = (i + 1) % 2 === 1;

    if (isOdd ? method === 'snake' : method === 'snake-reverse') {
      acc.push(...v);
    } else {
      acc.push(...[...v].reverse());
    }

    return acc;
  }, []);

interface TimerGetterProps {
  i: number;
  timeShift: number;
}

const makeGetForwardTimer = ({
  method,
  cardsNumber,
}: {
  method: Method;
  cardsNumber: number;
}): ((params: TimerGetterProps) => number) =>
  ({
    blind: () => Infinity,
    open: () => 0,
    'a-z': ({ i }: TimerGetterProps) => i * CARD_TIMER,
    'z-a': ({ i }: TimerGetterProps) => (cardsNumber - (i + 1)) * CARD_TIMER,
    snake: ({ i }: TimerGetterProps) => i * CARD_TIMER,
    'snake-reverse': ({ i }: TimerGetterProps) => i * CARD_TIMER,
    clockwise: ({ i }: TimerGetterProps) => i * CARD_TIMER,
    counterclockwise: ({ i }: TimerGetterProps) => i * CARD_TIMER,
    random: ({ i }: TimerGetterProps) => i * CARD_TIMER,
    column: ({ timeShift }: TimerGetterProps) => timeShift * CARD_TIMER,
    'column-reverse': ({ timeShift }: TimerGetterProps) => timeShift * CARD_TIMER,
    row: ({ timeShift }: TimerGetterProps) => timeShift * CARD_TIMER,
    'row-reverse': ({ timeShift }: TimerGetterProps) => timeShift * CARD_TIMER,
    'diagonal-bottom-right': ({ timeShift }: TimerGetterProps) => timeShift * CARD_TIMER,
    'diagonal-top-left': ({ timeShift }: TimerGetterProps) => timeShift * CARD_TIMER,
    'diagonal-bottom-left': ({ timeShift }: TimerGetterProps) => timeShift * CARD_TIMER,
    'diagonal-top-right': ({ timeShift }: TimerGetterProps) => timeShift * CARD_TIMER,
  }[method]);

const makeGetTimeFrame = ({
  method,
  cardsNumber,
}: {
  method: Method;
  cardsNumber: number;
}): ((params: TimerGetterProps) => number) =>
  ({
    blind: () => 0,
    open: () => cardsNumber * CARD_TIMER,
    'a-z': () => CARD_TIMER,
    'z-a': () => CARD_TIMER,
    snake: () => CARD_TIMER,
    'snake-reverse': () => CARD_TIMER,
    clockwise: () => CARD_TIMER,
    counterclockwise: () => CARD_TIMER,
    random: () => CARD_TIMER,
    column: ({ timeShift }: TimerGetterProps) => timeShift * CARD_TIMER,
    'column-reverse': ({ timeShift }: TimerGetterProps) => timeShift * CARD_TIMER,
    row: ({ timeShift }: TimerGetterProps) => timeShift * CARD_TIMER,
    'row-reverse': ({ timeShift }: TimerGetterProps) => timeShift * CARD_TIMER,
    'diagonal-bottom-right': ({ timeShift }: TimerGetterProps) => timeShift * CARD_TIMER,
    'diagonal-top-left': ({ timeShift }: TimerGetterProps) => timeShift * CARD_TIMER,
    'diagonal-bottom-left': ({ timeShift }: TimerGetterProps) => timeShift * CARD_TIMER,
    'diagonal-top-right': ({ timeShift }: TimerGetterProps) => timeShift * CARD_TIMER,
  }[method]);

const getShift = ({ method, exactRectangle }: { method: DiagonalMethod; exactRectangle: boolean }) =>
  ({
    'diagonal-bottom-right': exactRectangle ? 1 : 2,
    'diagonal-top-left': exactRectangle ? 1 : 2,
    'diagonal-bottom-left': 1,
    'diagonal-top-right': 1,
  }[method]);

const getDiagonal = ({
  method,
  rowsNumber,
  columnsNumber,
  row,
  column,
  shift,
}: {
  method: DiagonalMethod;
  rowsNumber: number;
  columnsNumber: number;
  row: number;
  column: number;
  shift: number;
}) =>
  ({
    'diagonal-bottom-right': row + column,
    'diagonal-top-left': rowsNumber + columnsNumber - row - column - shift - 1,
    'diagonal-bottom-left': columnsNumber - column + row - shift,
    'diagonal-top-right': rowsNumber - row + column - shift,
  }[method]);

const getColumns = (columns: number, rows: number, totalElements: number, method: ColumnMethod) => {
  const lastLineCols = totalElements % columns || columns;
  const columnForm = Array.from({ length: columns }, (v, i) => (i + 1 <= lastLineCols ? rows : rows - 1));

  return {
    column: columnForm,
    'column-reverse': [...columnForm].reverse(),
  }[method];
};

const getRows = (columns: number, rows: number, totalElements: number, method: RowMethod) => {
  const lastLineCols = totalElements % columns || columns;
  const rowForm = Array.from({ length: rows }, (v, i) => (i + 1 === rows ? lastLineCols : columns));

  return {
    row: rowForm,
    'row-reverse': [...rowForm].reverse(),
  }[method];
};

const getDiagonals = (columns: number, rows: number, totalElements: number, method: DiagonalMethod): Array<number> => {
  const maxDiagonalSize = Math.min(rows, columns);
  const lastLineCols = totalElements % columns || columns;
  const lastLineMissingCols = columns - lastLineCols;
  const maxDiagonalsNumber = columns + rows - 1;

  const perfectForm: number[] = [];
  for (let i = 1; i <= maxDiagonalSize; i++) {
    if (i === maxDiagonalSize) {
      perfectForm.push(
        ...Array.from({ length: maxDiagonalsNumber - perfectForm.length * 2 }, () => maxDiagonalSize),
        ...[...perfectForm].reverse()
      );
    } else {
      perfectForm.push(i);
    }
  }

  const leftForm = perfectForm.reduce((acc: number[], v: number, i, arr) => {
    if (arr.length - (i + 1) < lastLineMissingCols) {
      if (v - 1 > 0) {
        acc.push(v - 1);
      }
    } else {
      acc.push(v);
    }
    return acc;
  }, []);

  const rightForm = perfectForm.reduce((acc: number[], v: number, i, arr) => {
    const position = i + 1;

    if (position >= rows && position !== maxDiagonalsNumber && arr.length - position >= lastLineCols) {
      acc.push(v - 1);
    } else {
      acc.push(v);
    }
    return acc;
  }, []);

  return {
    'diagonal-bottom-right': leftForm,
    'diagonal-top-left': [...leftForm].reverse(),
    'diagonal-bottom-left': rightForm,
    'diagonal-top-right': [...rightForm].reverse(),
  }[method];
};

const accumulateCards = (arr: Array<number>, position: number) => {
  const part = arr.slice(0, position);
  return part.reduce((acc, v) => acc + v, 0);
};

const getLineWithPosition = ({
  rows,
  columns,
  diagonals,
  row,
  column,
  diagonal,
}: {
  rows: Array<number> | undefined;
  columns: Array<number> | undefined;
  diagonals: Array<number> | undefined;
  row: number;
  column: number;
  diagonal: number;
}): { line: Array<number>; position: number } => {
  const linesMap: { [key in LineType]: Array<number> | undefined } = {
    row: rows,
    column: columns,
    diagonal: diagonals,
  };

  const positionMap: Array<{ key: LineType; value: number }> = [
    { key: 'row', value: row },
    { key: 'column', value: column },
    { key: 'diagonal', value: diagonal },
  ];

  const position = positionMap.find(({ key }) => linesMap[key]);

  if (position) {
    const line = linesMap[position.key];
    return { line: line!, position: position.value };
  }

  return { line: [], position: 0 };
};

interface OpenCardsProps {
  method: Method;
  cardsNumber: number;
  columnsNumber: number;
  rowsNumber: number;
  increase: (i: number) => void;
  decrease: () => void;
}

export const openCards = ({ method, cardsNumber, columnsNumber, rowsNumber, increase, decrease }: OpenCardsProps) => {
  if (method === 'blind') return () => {};

  const diagonalMethod = method as DiagonalMethod;
  const columnMethod = method as ColumnMethod;
  const rowMethod = method as RowMethod;
  let indexArray = Array.from({ length: cardsNumber }, (v, i) => i);
  const exactRectangle = indexArray.length % columnsNumber === 0;
  const getForwardTimer = makeGetForwardTimer({ method, cardsNumber });
  const getTimeFrame = makeGetTimeFrame({ method, cardsNumber });
  const shift = getShift({ method: diagonalMethod, exactRectangle });
  const diagonals = getDiagonals(columnsNumber, rowsNumber, cardsNumber, diagonalMethod);
  const columns = getColumns(columnsNumber, rowsNumber, cardsNumber, columnMethod);
  const rows = getRows(columnsNumber, rowsNumber, cardsNumber, rowMethod);

  if (method === 'random') {
    shuffle(indexArray);
  }

  if (['clockwise', 'counterclockwise'].includes(method)) {
    const table = arrayFromFlat(indexArray, columnsNumber);
    indexArray = flattenByClockMethod(table, method as ClockMethod);
  }

  if (['snake', 'snake-reverse'].includes(method)) {
    const table = arrayFromFlat(indexArray, columnsNumber);
    indexArray = flattenBySnakeMethod(table, method as SnakeMethod);
  }

  const timers: Array<NodeJS.Timeout> = [];

  indexArray.forEach((cardIndex, i) => {
    // cardIndex and i are different only in 'random' mode
    const row = Math.floor(i / columnsNumber);
    const column = i % columnsNumber;
    const diagonal = getDiagonal({ method: diagonalMethod, rowsNumber, columnsNumber, row, column, shift });
    const relationalRow = rowMethod === 'row' ? row : rowsNumber - row - 1;
    const relationalColumn = columnMethod === 'column' ? column : columnsNumber - column - 1;

    const { line, position } = getLineWithPosition({
      rows,
      columns,
      diagonals,
      row: relationalRow,
      column: relationalColumn,
      diagonal,
    });

    const forwardTimer = getForwardTimer({ i, timeShift: accumulateCards(line, position) || 0 });
    const timeFrame = getTimeFrame({ i, timeShift: line[position] || 0 });

    const increaseTimer = setTimeout(() => increase(cardIndex), forwardTimer);
    const decreaseTimer = setTimeout(() => decrease(), forwardTimer + timeFrame);
    timers.push(increaseTimer, decreaseTimer);
  });

  return () => timers.forEach(clearTimeout);
};
