import React, { FC, RefObject } from 'react';
import clsx from 'clsx';
import { Stack, Typography } from '@mui/material';
import styles from './Player.module.scss';

interface PlayerProps {
  name: string;
  emoji: number;
  score: number;
  playerRef: RefObject<HTMLDivElement>;
  active: boolean;
}
const Player: FC<PlayerProps> = ({ name, emoji, score, playerRef, active }) => (
  <Stack
    direction="row"
    alignItems="center"
    className={clsx(styles['player-wrap'], { [styles.active]: active })}
    ref={playerRef}
  >
    <Stack direction="row" alignItems="center" gap={2} className={styles.player}>
      <Typography variant="h2">{String.fromCodePoint(emoji)}</Typography>
      <Typography variant="body-2" className={styles['player-name']}>
        {name}
      </Typography>
    </Stack>
    <Stack className={styles['score-wrap']}>
      <Typography variant="h5" className={styles.score}>
        {score}
      </Typography>
    </Stack>
  </Stack>
);

export default Player;
