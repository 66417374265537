import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton, Stack, Typography } from '@mui/material';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { useAuth, useRoutes } from '../../hooks';
import { useMainLayout } from '../Layout/LayoutWrapper';
import styles from './MobileTopNotch.module.scss';

interface MobileTopNotchProps {
  title: string;
}
const MobileTopNotch: FC<MobileTopNotchProps> = ({ title }) => {
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const { user } = useAuth();
  const ROUTES = useRoutes();
  const { isMobile } = useMainLayout();

  if (!isMobile) {
    return null;
  }

  return (
    <Stack direction="column" alignItems="center" justifyContent="end" className={styles['mobile-top-notch']}>
      <IconButton
        component={RouterLink}
        to={user ? ROUTES.DASHBOARD : ROUTES.ROOT}
        className={styles['mobile-top-notch-back-button']}
        aria-label={ariaLabelT(user ? 'LINK.DASHBOARD' : 'LINKS.ROOT') as string}
      >
        <ArrowBackIosNewRoundedIcon />
      </IconButton>
      <Typography variant="h2">{title}</Typography>
    </Stack>
  );
};

export default MobileTopNotch;
