import React, { createContext, useContext, useEffect, useState } from 'react';
import { checkWideBreakPoint, checkNarrowBreakPoint } from '../../utils';
import { useWindowDimensions } from '../../hooks';

type LayoutWrapperProps = {
  children: string | JSX.Element | JSX.Element[];
};

type LayoutContextType = {
  wideBreakPoint: number;
  narrowBreakPoint: number;
  isMobile: boolean;
  isNarrowMobile: boolean;
  isTooNarrowMobile: boolean;
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
  setOpen: (isOpen: boolean) => void;
};

const LayoutContext = createContext<LayoutContextType>({} as LayoutContextType);

export const useMainLayout = () => useContext(LayoutContext) as unknown as LayoutContextType;

const LayoutWrapper = ({ children }: LayoutWrapperProps) => {
  const { width } = useWindowDimensions();
  const wideBreakPoint = checkWideBreakPoint(width);
  const narrowBreakPoint = checkNarrowBreakPoint(width);
  const isMobile = narrowBreakPoint >= 3;
  const isNarrowMobile = narrowBreakPoint >= 4;
  const isTooNarrowMobile = narrowBreakPoint >= 5;
  const [isOpen, setOpen] = useState(!narrowBreakPoint);
  const layoutContext: LayoutContextType = {
    wideBreakPoint,
    narrowBreakPoint,
    isMobile,
    isNarrowMobile,
    isTooNarrowMobile,
    isOpen,
    setOpen,
    open: () => setOpen(true),
    close: () => setOpen(false),
    toggle: () => setOpen(!isOpen),
  };

  useEffect(() => {
    setOpen(!narrowBreakPoint);
    if (isMobile) document.body.classList.add('mobile-height');
  }, [narrowBreakPoint]);

  return <LayoutContext.Provider value={layoutContext}>{children}</LayoutContext.Provider>;
};

export default LayoutWrapper;
