import React, { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Dialog, DialogContent, DialogContentText, DialogActions, Button, Typography, Stack } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useMainLayout } from '../Layout/LayoutWrapper';
import {
  resetPlayerOnTurn,
  resetPlayersScores,
  getPlayersInGame,
  resetTournamentStep,
  resetTournamentEfficiency,
  resetLadderGameStep,
  getLadderGameStatus,
  setLadderGameStatus,
  resetGameCardsNumber,
  resetCardsInGame,
} from '../../redux/slices';
import { computer, mainPlayer, tournamentSteps } from '../../constants';
import { useEfficiency, useRoutes } from '../../hooks';
import { toFixedNoRound } from '../../utils';
import { confettiGif } from '../../assets';
import styles from '../../assets/styles/modal.module.scss';

interface GameResultModalProps {
  open: boolean;
  onClose: () => void;
  onStartGame: () => Promise<void>;
}
const GameResultModal: FC<GameResultModalProps> = ({ open, onClose, onStartGame }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'GAME_RESULT_MODAL' });
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const { t: altT } = useTranslation('translation', { keyPrefix: 'ALTERNATIVE_TEXT' });
  const { isMobile } = useMainLayout();
  const ROUTES = useRoutes();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const playersInGame = useSelector(getPlayersInGame);
  const ladderGameStatus = useSelector(getLadderGameStatus);

  const {
    ladderGameStep,
    tournamentStep,
    lastTournamentStep,
    lastLadderGameStep,
    currentEfficiency,
    overallEfficiency,
    soloEfficiency,
  } = useEfficiency();

  const winners = useMemo(() => {
    const scores = playersInGame.map(({ score }) => score);
    const maxScore = Math.max(...scores);
    return playersInGame.reduce(
      (acc: { [key: number]: string }, { emoji, score, name }) =>
        score === maxScore ? { ...acc, [emoji]: name } : acc,
      {}
    );
  }, [playersInGame]);

  const winnersNames = Object.values(winners);

  const soloDemoBodyText = (
    <Trans
      i18nKey="GAME_RESULT_MODAL.SOLO_DEMO_BODY_TEXT"
      values={{ soloEfficiency: toFixedNoRound(soloEfficiency * 100, 0) }}
      components={[
        <br />,
        <RouterLink
          to={ROUTES.AUTH_SIGN_IN}
          className={styles.link}
          aria-label={ariaLabelT('LINK.SIGN_IN') as string}
        />,
      ]}
    />
  );

  const multiPlayerBodyText = (
    <Trans
      i18nKey="GAME_RESULT_MODAL.MULTIPLAYER_BODY_TEXT"
      values={{ winners: winnersNames.join(', ') }}
      context={winnersNames.length > 1 ? 'MULTIPLE' : 'SINGLE'}
    />
  );

  const computerBodyText = (
    <Trans
      i18nKey="GAME_RESULT_MODAL.COMPUTER_BODY_TEXT"
      context={winners[computer.emoji] && winners[mainPlayer.emoji] ? 'DRAW' : winners[computer.emoji] ? 'LOST' : 'WON'}
    />
  );

  const tournamentActiveButton = (
    <Trans i18nKey="GAME_RESULT_MODAL.TOURNAMENT_ACTIVE_BUTTON" context={lastTournamentStep ? 'LAST' : ''} />
  );

  const ladderActiveButton = (
    <Trans
      i18nKey="GAME_RESULT_MODAL.LADDER_GAME_ACTIVE_BUTTON"
      context={['won', 'failed'].includes(ladderGameStatus) ? 'FINISH' : ''}
    />
  );

  const tournamentBodyText = (
    <Trans
      i18nKey="GAME_RESULT_MODAL.TOURNAMENT_STEP_BODY_TEXT"
      components={[<br />]}
      values={{
        current: toFixedNoRound(currentEfficiency * 100, 0),
        overall: toFixedNoRound(overallEfficiency * 100, 0),
        stepName: t(`TOURNAMENT_STEPS_NAMES.${tournamentSteps[tournamentStep]}`),
      }}
      context={lastTournamentStep ? 'LAST' : ''}
    />
  );

  const ladderGameBodyText = (
    <Trans
      i18nKey="GAME_RESULT_MODAL.LADDER_GAME_BODY_TEXT"
      components={[<br />]}
      values={{ achievedStep: ladderGameStep + (ladderGameStatus === 'failed' ? 1 : 2) }}
      context={ladderGameStatus === 'progress' ? 'PROGRESS' : ladderGameStep < 1 ? 'EMPTY' : ''}
    />
  );

  const titleTextMap = {
    [ROUTES.SOLO]: t('GAME_FINISHED_TITLE'),
    [ROUTES.SOLO_DEMO]: t('GAME_FINISHED_TITLE'),
    [ROUTES.MULTIPLAYER]: t('GAME_FINISHED_TITLE'),
    [ROUTES.COMPUTER]: t('GAME_FINISHED_TITLE'),
    [ROUTES.TOURNAMENT]: lastTournamentStep ? t('TOURNAMENT_FINISHED_TITLE') : t('STEP_FINISHED_TITLE'),
    [ROUTES.LADDER_GAME]:
      ladderGameStatus === 'failed'
        ? t('GAME_FINISHED_TITLE')
        : lastLadderGameStep
        ? t('LADDER_GAME_FINISHED_TITLE')
        : t('STEP_FINISHED_TITLE'),
  };

  const bodyTextMap = {
    [ROUTES.SOLO]: t('SOLO_BODY_TEXT', { soloEfficiency: toFixedNoRound(soloEfficiency * 100, 0) }),
    [ROUTES.SOLO_DEMO]: soloDemoBodyText,
    [ROUTES.MULTIPLAYER]: multiPlayerBodyText,
    [ROUTES.COMPUTER]: computerBodyText,
    [ROUTES.TOURNAMENT]: tournamentBodyText,
    [ROUTES.LADDER_GAME]: ladderGameBodyText,
  };

  const activeButtonTextMap = {
    [ROUTES.SOLO]: t('SOLO_ACTIVE_BUTTON'),
    [ROUTES.SOLO_DEMO]: t('SOLO_DEMO_ACTIVE_BUTTON'),
    [ROUTES.MULTIPLAYER]: t('MULTIPLAYER_ACTIVE_BUTTON'),
    [ROUTES.COMPUTER]: t('COMPUTER_ACTIVE_BUTTON'),
    [ROUTES.TOURNAMENT]: tournamentActiveButton,
    [ROUTES.LADDER_GAME]: ladderActiveButton,
  };

  const handleClose = () => {
    onClose();

    if ([ROUTES.SOLO, ROUTES.SOLO_DEMO].includes(pathname)) {
      dispatch(resetCardsInGame());
    }
    if ([ROUTES.MULTIPLAYER, ROUTES.COMPUTER].includes(pathname)) {
      dispatch(resetPlayersScores());
      dispatch(resetPlayerOnTurn());
      dispatch(resetCardsInGame());
    }
  };

  const handleStartGame = () => {
    onClose();
    if (pathname === ROUTES.TOURNAMENT && lastTournamentStep) {
      dispatch(resetTournamentStep());
      dispatch(resetTournamentEfficiency());
      dispatch(resetCardsInGame());
    } else if (pathname === ROUTES.LADDER_GAME && ['won', 'failed'].includes(ladderGameStatus)) {
      dispatch(resetLadderGameStep());
      dispatch(resetGameCardsNumber());
      dispatch(setLadderGameStatus('progress'));
      dispatch(resetCardsInGame());
    } else {
      onStartGame().finally();
    }
  };

  return (
    <Dialog open={open} transitionDuration={0} className={styles.modal}>
      <Stack className={styles.header}>
        <Typography variant="h3">{titleTextMap[pathname]}</Typography>
        {isMobile && ![ROUTES.TOURNAMENT, ROUTES.LADDER_GAME].includes(pathname) && (
          <CloseIcon className={styles['close-icon']} onClick={handleClose} aria-label={t('CLOSE_BUTTON') as string} />
        )}
      </Stack>

      <DialogContent className={styles.body}>
        <DialogContentText>
          <Typography variant="body-1">{bodyTextMap[pathname]}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.footer}>
        <Stack gap={5} direction="row">
          {!isMobile && ![ROUTES.TOURNAMENT, ROUTES.LADDER_GAME].includes(pathname) && (
            <Button
              variant="text"
              className={styles['secondary-button']}
              onClick={handleClose}
              aria-label={t('CLOSE_BUTTON') as string}
            >
              <Typography variant="h4">{t('CLOSE_BUTTON')}</Typography>
            </Button>
          )}

          <Button
            variant="contained"
            className={styles['action-button']}
            onClick={handleStartGame}
            aria-label={activeButtonTextMap[pathname] as string}
          >
            <Typography variant="button">{activeButtonTextMap[pathname]}</Typography>
          </Button>
        </Stack>
      </DialogActions>

      {(pathname === ROUTES.LADDER_GAME
        ? ladderGameStatus !== 'failed' || !!ladderGameStep
        : !winners[computer.emoji]) && (
        <img alt={altT('CONFETTI') as string} loading="lazy" src={confettiGif} className={styles.confetti} />
      )}
    </Dialog>
  );
};

export default GameResultModal;
