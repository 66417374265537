import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MethodType } from '../../types';
import { EfficiencyState } from '../types/efficiency';
import { toFixedNoRound } from '../../utils';

const initialState: EfficiencyState = {
  soloEfficiency: 0,
  currentEfficiencies: {
    open: 0,
    byOne: 0,
    byLine: 0,
    blind: 0,
  },
  overallEfficiency: 0,
};

export const efficiencySlice = createSlice({
  name: 'efficiency',
  initialState,
  reducers: {
    setTournamentEfficiency: (state, action: PayloadAction<{ method: MethodType; value: number }>) => {
      const { method, value } = action.payload;
      const currentEfficiencies = { ...state.currentEfficiencies, [method]: toFixedNoRound(value, 2) };

      const { count, sum } = Object.values(currentEfficiencies).reduce(
        (acc, v) => {
          if (v) {
            acc.sum += v;
            acc.count++;
          }
          return acc;
        },
        { count: 0, sum: 0 }
      );

      Object.assign(state, {
        currentEfficiencies,
        overallEfficiency: toFixedNoRound(count ? sum / count : 0, 2),
      });
    },
    resetTournamentEfficiency: (state) => {
      Object.assign(state, {
        currentEfficiencies: initialState.currentEfficiencies,
        overallEfficiency: initialState.overallEfficiency,
      });
    },
    setSoloEfficiency: (state, action: PayloadAction<number>) => {
      Object.assign(state, { soloEfficiency: toFixedNoRound(action.payload, 2) });
    },
  },
});

export const { setSoloEfficiency, setTournamentEfficiency, resetTournamentEfficiency } = efficiencySlice.actions;

export const getEfficiency = ({ efficiency }: { efficiency: EfficiencyState }) => efficiency;
export const getCurrentEfficiencies = ({ efficiency }: { efficiency: EfficiencyState }) =>
  getEfficiency({ efficiency }).currentEfficiencies;
export const getOverallEfficiency = ({ efficiency }: { efficiency: EfficiencyState }) =>
  getEfficiency({ efficiency }).overallEfficiency;
export const getSoloEfficiency = ({ efficiency }: { efficiency: EfficiencyState }) =>
  getEfficiency({ efficiency }).soloEfficiency;

export const { reducer: efficiencyReducer } = efficiencySlice;
