import React, { FC, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { getFolder, getGameFolder, getGameCardsNumber, setGameCardsNumber } from '../../redux/slices';
import { ArrowIcon } from '../../assets/icons';
import { dropdownMaxHeight, MAX_IMAGES_NUMBER } from '../../constants';

interface CardNumberSettingProps {
  styles: {
    dropdown: string;
    'cards-number': string;
  };
}
const CardNumberSetting: FC<CardNumberSettingProps> = ({ styles }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'SETTINGS' });
  const dispatch = useDispatch();
  const gameFolder = useSelector(getGameFolder);
  const folderCards = useSelector(getFolder(gameFolder));
  const gameCardsNumber = useSelector(getGameCardsNumber);
  const handleCardsNumberChange = (e: SelectChangeEvent) => dispatch(setGameCardsNumber(+e.target.value));

  const cardsNumberOptions = useMemo(
    () => Array.from({ length: folderCards?.length || MAX_IMAGES_NUMBER }, (v, i) => `${(i + 1) * 2}`).slice(1),
    [folderCards?.length]
  );

  return (
    <Stack direction="column" gap={2} className={styles.dropdown}>
      <Typography variant="h5" color="var(--text-secondary)">
        {t('CARD_NUMBER_LABEL')}
      </Typography>
      <Select
        className={styles['cards-number']}
        value={`${gameCardsNumber}`}
        onChange={handleCardsNumberChange}
        IconComponent={ArrowIcon}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: `${dropdownMaxHeight}px`,
              borderRadius: '8px',
              marginTop: '9px',
              transition: 'unset !important',
            },
          },
        }}
        inputProps={{ 'aria-label': `${gameCardsNumber}` }}
      >
        {cardsNumberOptions.map((value) => (
          <MenuItem key={`${value}`} value={`${value}`}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

export default CardNumberSetting;
