import React, { FC, MouseEvent, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, TextField, Typography, Stack, Divider, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { emailRegexp } from '../../constants';
import { GoogleIcon } from '../../assets';
import { useAuth, useRoutes } from '../../hooks';
import { AuthSignIn } from '../../types';
import styles from './Auth.module.scss';

const Login: FC = () => {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'AUTH' });
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const ROUTES = useRoutes();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AuthSignIn>();

  const navigate = useNavigate();
  const { user, logInWithEmailAndPassword, signInWithGoogle } = useAuth();

  const [isSending, setIsSending] = useState(false);
  const [errorMessages, setErrorMessages] = useState({ password: '', user: '' });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const preventMouseDown = (event: MouseEvent<HTMLButtonElement>) => event.preventDefault();

  useEffect(() => {
    if (user) navigate(ROUTES.DASHBOARD);
  }, [user]);

  const signInHandler: SubmitHandler<AuthSignIn> = async (formData) => {
    const { email, password } = formData;

    setIsSending(true);
    try {
      await logInWithEmailAndPassword(email, password);
      reset(formData);
    } catch (errCode) {
      const errorLocalPassword = `ERROR_MAP.PASSWORD.${errCode}`;
      const errorLocalUser = `ERROR_MAP.USER.${errCode}`;
      const passwordErrorMessage = t(errorLocalPassword);
      const userErrorMessage = t(errorLocalUser);

      setErrorMessages(
        i18n.exists(`AUTH.${errorLocalUser}`)
          ? { password: '', user: userErrorMessage }
          : {
              password: i18n.exists(`AUTH.${errorLocalPassword}`) ? passwordErrorMessage : t('UNEXPECTED_ERROR'),
              user: '',
            }
      );
    } finally {
      setIsSending(false);
    }
  };

  const handleEmailInput = () => setErrorMessages(({ password }) => ({ password, user: '' }));
  const handlePasswordInput = () =>
    setErrorMessages((currentErrorMessages) => ({ password: '', user: currentErrorMessages.user }));

  return (
    <Stack direction="column" gap={10}>
      <Typography variant="h1">{t('LOGIN_TITLE')}</Typography>

      <Stack direction="column" gap={6}>
        <Button
          className={styles['google-button']}
          startIcon={<GoogleIcon />}
          onClick={signInWithGoogle}
          aria-label={t('GOOGLE_LOGIN_BUTTON') as string}
        >
          <Typography variant="button" color="var(--text-lightest)">
            {t('GOOGLE_LOGIN_BUTTON')}
          </Typography>
        </Button>

        <Divider className={styles.divider}>
          <Typography variant="body-1" color="var(--text-lightest)">
            {t('OR')}
          </Typography>
        </Divider>

        <TextField
          className={styles['input-box']}
          onInput={handleEmailInput}
          {...register('email', {
            required: { value: true, message: t('EMAIL.ERROR.REQUIRED') },
            pattern: { value: emailRegexp, message: t('EMAIL.ERROR.PATTERN') },
          })}
          label={t('EMAIL.PLACEHOLDER')}
          helperText={errors.email?.message || errorMessages.user}
          error={!!errors.email?.type || !!errorMessages.user}
          fullWidth
          autoFocus
        />

        <TextField
          className={styles['input-box']}
          onInput={handlePasswordInput}
          {...register('password', {
            required: { value: true, message: t('PASSWORD.ERROR.REQUIRED') },
          })}
          label={t('PASSWORD.PLACEHOLDER')}
          helperText={errors.password?.message || errorMessages.password}
          error={!!errors.password?.type || !!errorMessages.password}
          fullWidth
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={preventMouseDown}
                  edge="end"
                  aria-label={ariaLabelT(`BUTTON.${showPassword ? 'HIDE_PASSWORD' : 'SHOW_PASSWORD'}`) as string}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          component={RouterLink}
          to={ROUTES.AUTH_RESET}
          variant="text"
          className={styles['text-button']}
          aria-label={ariaLabelT('LINK.RESET_PASSWORD') as string}
        >
          <Typography variant="h4">{t('FORGOT_PASSWORD')}</Typography>
        </Button>

        <LoadingButton
          size="small"
          variant="contained"
          onClick={handleSubmit(signInHandler)}
          className={styles['action-button']}
          loading={isSending}
        >
          <Typography variant="button">{t('LOG_IN')}</Typography>
        </LoadingButton>

        <Stack direction="row" gap={2}>
          <Typography variant="body-1">{t('DONT_HAVE_AN_ACCOUNT')}</Typography>
          <Button
            component={RouterLink}
            to={ROUTES.AUTH_SIGN_UP}
            variant="text"
            className={styles['text-button']}
            aria-label={ariaLabelT('LINK.SIGN_UP') as string}
          >
            <Typography variant="h4">{t('SIGN_UP')}</Typography>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Login;
