import React from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import CookieBanner from './components/CookieBanner/CookieBanner';
import NarrowScreenModal from './components/NarrowScreenModal/NarrowScreenModal';
import MobileOrientationModal from './components/MobileOrientationModal/MobileOrientationModal';
import './i18n';
import { useHistoryChange } from './hooks';
import createTheme from './theme';
import Routes from './Routes';
import './assets';
import './App.scss';

const root = document.getElementById('root')!;

function App() {
  const { i18n } = useTranslation('translation');
  useHistoryChange(() => {
    root.classList.add('freeze');
    root.scrollTo(0, 0);
    root.classList.remove('freeze');
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme()}>
        <Routes language={i18n.language.toLowerCase()} />
        <CookieBanner />
        <NarrowScreenModal />
        <MobileOrientationModal />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
