import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getStorage, connectStorageEmulator } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyCPqNmQ6SpXLwORWIgpNjuve8x_Xibjx6U',
  authDomain: 'memo.fun', // memo-1918.firebaseapp.com
  databaseURL: 'https://memo-1918.firebaseio.com',
  projectId: 'memo-1918',
  storageBucket: 'memo-1918.appspot.com',
  messagingSenderId: '75580067380',
  appId: '1:75580067380:web:01b537149d79ff5cc38461',
  measurementId: 'G-3QR50HE2P4',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const storage = getStorage(app, 'gs://memo-1918.appspot.com');

// for work with emulator run "firebase emulators:start" + don't forget about "yarn build" before
if (['localhost', '127.0.0.1'].includes(location.hostname) && location.port !== '3002') {
  // eslint-disable-next-line no-console
  console.log('We are on local emulators mode');
  if (location.port !== '3001') {
    connectAuthEmulator(auth, 'http://127.0.0.1:9099');
  }
  connectFirestoreEmulator(db, '127.0.0.1', 8080);
  connectStorageEmulator(storage, '127.0.0.1', 9199);
}

export { auth, db, analytics, storage };
