import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  getCardsInGame,
  getCurrentEfficiencies,
  getLadderGameStep,
  getOpeningMethod,
  getOverallEfficiency,
  getTournamentStep,
  getSoloEfficiency,
} from '../redux/slices';
import { blindMethod, ladderGameSteps, tournamentSteps } from '../constants';

const useEfficiency = () => {
  const cardsInGame = useSelector(getCardsInGame);
  const tournamentStep = useSelector(getTournamentStep);
  const openingMethod = useSelector(getOpeningMethod);
  const currentEfficiencies = useSelector(getCurrentEfficiencies);
  const overallEfficiency = useSelector(getOverallEfficiency);
  const soloEfficiency = useSelector(getSoloEfficiency);
  const ladderGameStep = useSelector(getLadderGameStep);

  const currentMethodType = tournamentSteps[tournamentStep];
  const currentEfficiency = currentMethodType && currentEfficiencies[currentMethodType];
  const lastTournamentStep = tournamentStep === tournamentSteps.length - 1;
  const lastLadderGameStep = ladderGameStep === ladderGameSteps.length - 1;

  const getCurrentEfficiency = useCallback(() => {
    if (!cardsInGame.length) return 0;

    const isBlind = openingMethod === blindMethod;
    let alreadySeenCardsNumber = 0;

    const currentOpenings = cardsInGame.reduce((acc, { openedTimes }) => {
      const openedTimesForBlind = openedTimes > 0 ? openedTimes - 1 : 0;
      const incrementValue = isBlind ? openedTimesForBlind : openedTimes;
      if (incrementValue) alreadySeenCardsNumber++;
      return acc + incrementValue;
    }, 0);

    return currentOpenings ? alreadySeenCardsNumber / currentOpenings : 1;
  }, [cardsInGame, openingMethod]);

  return {
    currentEfficiencies,
    currentEfficiency,
    overallEfficiency,
    soloEfficiency,
    currentMethodType,
    tournamentStep,
    ladderGameStep,
    lastTournamentStep,
    lastLadderGameStep,
    getCurrentEfficiency,
  };
};

export default useEfficiency;
