import React, { FC, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Typography,
  Button,
} from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { useGetTournamentDataQuery, useGetLadderGameDataQuery } from '../../redux/api';
import { tournamentsColumns, tournamentSteps, ladderGamesColumns } from '../../constants';
import { FirstPlaceIcon, SecondPlaceIcon, ThirdPlaceIcon } from '../../assets/icons';
import { makeUiDate, sortLadderGames, sortTournaments, toFixedNoRound } from '../../utils';
import { useAuth, useRoutes } from '../../hooks';
import { useMainLayout } from '../Layout/LayoutWrapper';
import styles from './BestScoreGamesSet.module.scss';

const BestScoreGamesSet: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'DASHBOARD' });
  const { t: settingsT } = useTranslation('translation', { keyPrefix: 'SETTINGS' });
  const { t: ariaLabelT } = useTranslation('translation', { keyPrefix: 'ARIA_LABEL' });
  const ROUTES = useRoutes();
  const { user, loading: authLoading } = useAuth();
  const { isMobile } = useMainLayout();
  const topIcons = [<FirstPlaceIcon />, <SecondPlaceIcon />, <ThirdPlaceIcon />];

  const { data: tournamentData } = useGetTournamentDataQuery({ uid: user?.uid! }, { skip: authLoading });
  const { data: ladderGameData } = useGetLadderGameDataQuery({ uid: user?.uid! }, { skip: authLoading });

  const [currentTab, setCurrentTab] = useState<'tournament' | 'ladderGame'>('tournament');
  const [expandedTournaments, setExpandedTournaments] = useState(false);
  const toggleTournaments = () => setExpandedTournaments((currentValue) => !currentValue);
  const [expandedLadderGames, setExpandedLadderGames] = useState(false);
  const toggleLadderGames = () => setExpandedLadderGames((currentValue) => !currentValue);

  const sortedTournamentTop10 = useMemo(() => sortTournaments(tournamentData?.top10 || []), [tournamentData]);
  const sortedTournaments = expandedTournaments ? sortedTournamentTop10 : sortedTournamentTop10.slice(0, 3);
  const sortedLadderGameTop10 = useMemo(() => sortLadderGames(ladderGameData?.top10 || []), [ladderGameData]);
  const sortedLadderGames = expandedLadderGames ? sortedLadderGameTop10 : sortedLadderGameTop10.slice(0, 3);

  return (
    <Stack direction="column" gap={4} width="stretch">
      <Stack
        direction={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems={isMobile ? 'start' : 'center'}
        gap={isMobile ? 4 : 0}
      >
        <Typography variant={isMobile ? 'h3' : 'h2'}>{t('BEST_SCORE_GAMES')}</Typography>

        <Stack direction="row" className={styles['button-tab-set']}>
          <Button
            className={clsx(styles['button-tab'], { [styles.active]: currentTab === 'tournament' })}
            onClick={() => setCurrentTab('tournament')}
            aria-label={ariaLabelT('BUTTON.OPEN_TOURNAMENT_TAB') as string}
          >
            <Typography variant="body-1">{t('TAB_TITLE.TOURNAMENT')}</Typography>
          </Button>
          <Button
            className={clsx(styles['button-tab'], { [styles.active]: currentTab === 'ladderGame' })}
            onClick={() => setCurrentTab('ladderGame')}
            aria-label={ariaLabelT('BUTTON.OPEN_LADDER_GAME_TAB') as string}
          >
            <Typography variant="body-1">{t('TAB_TITLE.LADDER_GAME')}</Typography>
          </Button>
        </Stack>
      </Stack>

      <TabContext value={currentTab}>
        <TabPanel value="tournament" className={styles['tab-panel']}>
          <Stack direction="column" gap={4} width="stretch">
            <TableContainer className={styles['achievements-table']}>
              <Table>
                <TableHead>
                  <TableRow>
                    {tournamentsColumns.map((step) => (
                      <TableCell key={step} align="center" style={{ minWidth: step === 'date' ? '118px' : 'unset' }}>
                        <Typography variant="body-2">{t(`TOURNAMENT_COLUMNS.${step}`)}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tournamentData?.total ? (
                    sortedTournaments.map(({ timeStamp, ...rest }, index) => (
                      <TableRow key={timeStamp}>
                        <TableCell
                          key={`index_${timeStamp}`}
                          align="center"
                          className={clsx({ [styles['top-cell']]: topIcons[index] })}
                        >
                          {topIcons[index] || <Typography variant="body-2">{index + 1}</Typography>}
                        </TableCell>
                        <TableCell key={`overall_${timeStamp}`} align="center">
                          <Typography variant="body-2">{toFixedNoRound(rest.overall * 100, 0)}%</Typography>
                        </TableCell>
                        {tournamentSteps.map((key) => (
                          <TableCell key={`${key}_${timeStamp}`} align="center">
                            <Typography variant="body-2">{toFixedNoRound(rest[key] * 100, 0)}%</Typography>
                          </TableCell>
                        ))}
                        <TableCell key={`date_${timeStamp}`} align="center">
                          <Typography variant="body-2">{makeUiDate(timeStamp, t)}</Typography>
                        </TableCell>
                        <TableCell key={`category_${timeStamp}`} align="center">
                          <Typography variant="body-2">{settingsT(`FOLDERS_MAP.${rest.category}`)}</Typography>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        <Stack direction="column" gap={2} alignItems="center">
                          <Typography variant="body-2" color="var(--text-secondary)">
                            {t('ZERO_TOURNAMENTS_PLAYED')}
                          </Typography>

                          <Button
                            component={RouterLink}
                            to={ROUTES.TOURNAMENT}
                            variant="text"
                            className={styles['text-button']}
                            aria-label={ariaLabelT('LINK.TOURNAMENT') as string}
                          >
                            <Typography variant="h4">{t('PLAY_NOW')}</Typography>
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {sortedTournamentTop10.length > 3 && (
              <Stack width="inherit" alignItems="center">
                <Button
                  variant="text"
                  onClick={toggleTournaments}
                  className={styles['text-button']}
                  aria-label={
                    ariaLabelT(
                      `BUTTON.${expandedTournaments ? 'SHOW_LESS_TOURNAMENTS' : 'SHOW_MORE_TOURNAMENTS'}`
                    ) as string
                  }
                >
                  <Typography variant="h4" color="var(--primary-main)">
                    {expandedTournaments ? t('SHOW_LESS') : t('SHOW_MORE')}
                  </Typography>
                </Button>
              </Stack>
            )}
          </Stack>
        </TabPanel>

        <TabPanel value="ladderGame" className={styles['tab-panel']}>
          <Stack direction="column" gap={4} width="stretch">
            <TableContainer className={styles['achievements-table']}>
              <Table>
                <TableHead>
                  <TableRow>
                    {ladderGamesColumns.map((step) => (
                      <TableCell key={step} align="center" style={{ minWidth: step === 'date' ? '118px' : 'unset' }}>
                        <Typography variant="body-2">{t(`LADDER_GAME_COLUMNS.${step}`)}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ladderGameData?.total ? (
                    sortedLadderGames.map(({ result, timeStamp, category }, index) => (
                      <TableRow key={timeStamp}>
                        <TableCell
                          key={`index_${timeStamp}`}
                          align="center"
                          className={clsx({ [styles['top-cell']]: topIcons[index] })}
                        >
                          {topIcons[index] || <Typography variant="body-2">{index + 1}</Typography>}
                        </TableCell>
                        <TableCell key={`overall_${timeStamp}`} align="center">
                          <Typography variant="body-2">{result}</Typography>
                        </TableCell>
                        <TableCell key={`date_${timeStamp}`} align="center">
                          <Typography variant="body-2">{makeUiDate(timeStamp, t)}</Typography>
                        </TableCell>
                        <TableCell key={`category_${timeStamp}`} align="center">
                          <Typography variant="body-2">{settingsT(`FOLDERS_MAP.${category}`)}</Typography>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <Stack direction="column" gap={2} alignItems="center">
                          <Typography variant="body-2" color="var(--text-secondary)">
                            {t('ZERO_LADDER_GAMES_PLAYED')}
                          </Typography>

                          <Button
                            component={RouterLink}
                            to={ROUTES.LADDER_GAME}
                            variant="text"
                            className={styles['text-button']}
                            aria-label={ariaLabelT('LINK.LADDER_GAME') as string}
                          >
                            <Typography variant="h4">{t('PLAY_NOW')}</Typography>
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {sortedLadderGameTop10.length > 3 && (
              <Stack width="inherit" alignItems="center">
                <Button
                  variant="text"
                  onClick={toggleLadderGames}
                  className={styles['show-more-less-button']}
                  aria-label={
                    ariaLabelT(
                      `BUTTON.${expandedLadderGames ? 'SHOW_LESS_LADDER_GAMES' : 'SHOW_MORE_LADDER_GAMES'}`
                    ) as string
                  }
                >
                  <Typography variant="h4" color="var(--primary-main)">
                    {expandedLadderGames ? t('SHOW_LESS') : t('SHOW_MORE')}
                  </Typography>
                </Button>
              </Stack>
            )}
          </Stack>
        </TabPanel>
      </TabContext>
    </Stack>
  );
};

export default BestScoreGamesSet;
