import React, { createContext, useContext } from 'react';
import { useStartGameHandler } from '../../hooks';

type GameWrapperProps = {
  children: string | JSX.Element | JSX.Element[];
};

type GameContextType = {
  startGameHandler: () => Promise<void>;
  setIsVirginGame: (isVirgin: boolean) => void;
  isVirginGame: boolean;
  isLoadingGame: boolean;
};

const GameContext = createContext<GameContextType>({} as GameContextType);
export const useGameContext = () => useContext(GameContext) as unknown as GameContextType;

const GameWrapper = ({ children }: GameWrapperProps) => {
  const { startGameHandler, isVirginGame, setIsVirginGame, isLoadingGame } = useStartGameHandler();

  const gameContext: GameContextType = {
    startGameHandler,
    isVirginGame,
    setIsVirginGame,
    isLoadingGame,
  };

  return <GameContext.Provider value={gameContext}>{children}</GameContext.Provider>;
};

export default GameWrapper;
